import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-plots',
  templateUrl: './plots.component.html',
  styleUrls: ['./plots.component.scss']
})

export class PlotsComponent implements OnInit {
  farmerId: any = '';
  plots: any = [];
  Date: any;
  titleButtonsActive: any = ['unharvest', 'irrigated', 'renew', 'drill']
  titleButtonsinActive: any = ['harvest', 'Not Irrigated', 'Not Renew', 'Not Drilled']
  treatment: any[] = [];
  irrigation: any[] = [];
  fertilisation: any[] = [];
  seeding: any[] = [];
  planting: any[] = [];
  spraying: any[] = [];
  site_lat: any;
  site_lng: any;
  langStatus: any;
  language: any;

  constructor(
    private req: RequestService<any>,
    private http: HttpClient,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getPlotData();
    this.statusLanguage()
  }

  async getPlotData() {
    
    this.req.getPlots().subscribe((res) => {
      res.forEach(element => {
        element.cropsCount = element.crop_info.length;
        this.plots.push(element);
      });  
      this.getWeather();
    });
  }

  statusLanguage() {
    this.langStatus = this.translate.store.currentLang
    if (this.langStatus === 'en') {
      this.language = 'en'
    } else if (this.langStatus === 'gr') {
      this.language = 'el'
    }
  }

  getWeather() {
    this.plots.forEach((element: any) => {
      this.site_lat = element.map_coordinates[0].lat
      this.site_lng = element.map_coordinates[0].lon
      this.http.get('https://api.weatherapi.com/v1/current.json?key=29bf4269b5754b0b903140948222706&q=' +
        this.site_lat +
        ',' +
        this.site_lng +
        '&days=10' + '&lang=' + this.language
      )
        .subscribe((res: any) => {
          element.temperature = res.current.temp_c
          element.weather = res.current.condition.text
          element.icon = res.current.condition.icon
          element.humidity = res.current.humidity
          element.precip = res.current.precip_mm
          element.rain = res.current.precip_mm
          element.wind =
            Math.min(12, Math.floor((res.current.wind_mph / 3.01) + 0.5))
        });
    })
  }
}