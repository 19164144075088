import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  submitting: boolean = false;
  contactForm!: FormGroup;
  fullName: any = '';
  email: any = '';
  subject: any = '';
  message: any = '';
  langStatus: any;
  debounceSendEmail: Function;

  constructor(private req: RequestService<any>, public _location: Location, private fb: FormBuilder, public translate: TranslateService) {
    this.debounceSendEmail = _.debounce(() => this.sendEmail(), 1000);
  }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      fullName: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      template: ['contact_form']
    })
  }

  sendEmail() {
    this.langStatus = this.translate.store.currentLang;

    if (this.contactForm.invalid) {
      this.contactForm.markAllAsTouched();
      const errorMessage = this.langStatus === 'en'
        ? 'Error sending message!'
        : 'Σφάλμα αποστολής μηνύματος!';

      Swal.fire({
        title: errorMessage,
        icon: 'error',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        timer: 2000
      });
      return;
    }
    this.submitting = true;

    let contactForm = {
      full_name: this.contactForm.value.fullName,
      email: this.contactForm.value.email,
      subject: this.contactForm.value.subject,
      message: this.contactForm.value.message,
      template: "contact_form",
    };

    this.req.postContactMail(contactForm).subscribe({
      next: (res) => {
        const successMessage = this.langStatus === 'en'
          ? 'Message sent!'
          : 'Το μήνυμά σας στάλθηκε!';

        Swal.fire({
          title: successMessage,
          icon: 'success',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
          timer: 3000,
          timerProgressBar: true
        }).then(() => {
          this._location.back();
        });
        this.contactForm.reset({ template: 'contact_form' });
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        this.submitting = false;
      }
    });
  }
}
