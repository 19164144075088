import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NgxFileDropEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import Swal from 'sweetalert2';
import { RequestService } from 'src/app/services/request.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-first-login-dialog',
  templateUrl: './first-login-dialog.component.html',
  styleUrls: ['./first-login-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FirstLoginDialogComponent {
  public droppedFile: any;
  public files: NgxFileDropEntry[] = [];
  public filePublic: any;
  public isDropped: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FirstLoginDialogComponent>,
    private http: RequestService<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public translate: TranslateService,
  ) { }


  onClose() {
    this.dialogRef.close();
  }

  onClick() {
    window.open(this.data, '_blank');
  }

  changeLanguage(language: string) {
    this.translate.use(language);
  }

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (this.droppedFile of files) {
      if (this.droppedFile.fileEntry.name.includes('.xml')) {
        this.isDropped = true;
      } else {
        const fileEntry = this.droppedFile
          .fileEntry as FileSystemDirectoryEntry;
        Swal.fire(
          'Incorrect file Format!',
          'You can upload only Xml files!',
          'error'
        );
      }
    }
  }

  submitUpload() {
    if (this.files.length == 0) {
      this.files = [];
    } else if (this.files.length > 0) {
      this.files.forEach((element) => {
        if (
          element.fileEntry.name.includes('.xml')
        ) {
          const fileEntry = element.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            this.filePublic = file;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('category', 'import')
            this.http.postFiles(formData).subscribe((res) => {
            });
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Success',
              text: 'Successfully Submited',
              showConfirmButton: false,
              timer: 2800,
              customClass: {
                popup: 'success-msg',
              },
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
        }
      });
      this.files = [];
    }
  }

  deleteDroped(event: any) {
    let nameUploadedfile = event.srcElement.parentElement.innerText.trim();
    const indexToDelete = this.files.findIndex(
      (item) => item.fileEntry.name === nameUploadedfile
    );
    if (indexToDelete !== -1) {
      this.files.splice(indexToDelete, 1);
      this.isDropped = false;
    } else {
      this.files = [];
      this.droppedFile = [];
    }
  }

}
