import {
  AfterViewInit,
  Component,
  ViewChild,
  OnInit,
  ViewEncapsulation,
  TemplateRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { RequestService } from 'src/app/services/request.service';
import Swal from 'sweetalert2';
import { KeycloakService } from 'keycloak-angular';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

export interface UserData {
  filename: string;
  upload_date: string;
}

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UploadComponent implements OnInit, AfterViewInit {
  importDataGR = ['Αίτηση Ενιαίας Ενίσχυσης (Δήλωση ΟΣΔΕ)',
    'Αναλύσεις υπολειμμάτων φυτοφαρμάκων',
    'Αναλύσεις εδάφους',
    'Παραστατικά αγορών',
    'Παραστατικά πωλήσεων',
    'Νομιμοποιητικά έγγραφα',
    'Πιστοποιητικά παραγωγού',
    'Λοιπά αρχεία',
    'XML-Εισαγωγη Δήλωση ΟΣΔΕ']
  importDataEN = ['Application for Single Payment Scheme',
    'Pesticide residue analysis',
    'Soil analysis',
    'Purchase documents',
    'Sales documents',
    'Legal documents',
    'Producer certificates',
    'Other documents',
    'XML-IACS Application Import']
  displayedColumns: string[] = ['type', 'filename', 'category', 'upload_date', 'action'];
  dataSource: MatTableDataSource<UserData>;
  public droppedFile: any;
  public id: any = '';
  public selectedRow: any;
  public selectedRowIndex: any;
  public selectedRowObj: any;
  public selectedRowName: any;
  public users: any;
  public token: any;
  public filePublic: any;
  public uploadedFiles: any[] = [];
  public files: NgxFileDropEntry[] = [];
  importDataValue: any
  public langStatus: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private dialog: MatDialog,
    private http: RequestService<any>,
    private keycloak: KeycloakService,
    public translate: TranslateService,
  ) {
    this.token = this.keycloak.getToken();
    this.token = this.token.__zone_symbol__value;
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.langStatus = this.translate.store.currentLang
    this.getFiles();
  }

  getFiles() {
    this.http.getUploadFiles(this.token).subscribe((res) => {
      res.forEach((el: any) => {
        if (this.langStatus === 'gr') {
          this.importDataGR.forEach((element: any) => {
            if (el.category.includes('Application for Single Payment Scheme')) {
              el.category = 'Αίτηση Ενιαίας Ενίσχυσης (Δήλωση ΟΣΔΕ)'
            }
            if (el.category.includes('Pesticide residue analysis')) {
              el.category = 'Αναλύσεις υπολειμμάτων φυτοφαρμάκων'
            }
            if (el.category.includes('Soil analysis')) {
              el.category = 'Αναλύσεις εδάφους'
            }
            if (el.category.includes('Purchase documents')) {
              el.category = 'Παραστατικά αγορών'
            }
            if (el.category.includes('Sales documents')) {
              el.category = 'Παραστατικά πωλήσεων'
            }
            if (el.category.includes('Legal documents')) {
              el.category = 'Νομιμοποιητικά έγγραφα'
            }
            if (el.category.includes('Producer certificates')) {
              el.category = 'Πιστοποιητικά παραγωγού'
            }
            if (el.category.includes('Other documents')) {
              el.category = 'Λοιπά αρχεία'
            }
            if (el.category.includes('XML-IACS Application Import')) {
              el.category = 'XML-Εισαγωγη Δήλωση ΟΣΔΕ'
            }
          })
        } else if (this.langStatus === 'en') {
          this.importDataGR.forEach((element: any) => {
            if (el.category.includes('Αίτηση Ενιαίας Ενίσχυσης (Δήλωση ΟΣΔΕ)')) {
              el.category = 'Application for Single Payment Scheme'
            }
            if (el.category.includes('Αναλύσεις υπολειμμάτων φυτοφαρμάκων')) {
              el.category = 'Pesticide residue analysis'
            }
            if (el.category.includes('Αναλύσεις εδάφους')) {
              el.category = 'Soil analysis'
            }
            if (el.category.includes('Παραστατικά αγορών')) {
              el.category = 'Purchase documents'
            }
            if (el.category.includes('Παραστατικά πωλήσεων')) {
              el.category = 'Sales documents'
            }
            if (el.category.includes('Νομιμοποιητικά έγγραφα')) {
              el.category = 'Legal documents'
            }
            if (el.category.includes('Πιστοποιητικά παραγωγού')) {
              el.category = 'Producer certificates'
            }
            if (el.category.includes('Λοιπά αρχεία')) {
              el.category = 'Other documents'
            }
            if (el.category.includes('XML-Εισαγωγη Δήλωση ΟΣΔΕ')) {
              el.category = 'XML-IACS Application Import'
            }
          })
        }
      })
      this.dataSource = new MatTableDataSource(res);
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  importSelect() {
    if (this.importDataValue.includes('Αίτηση Ενιαίας Ενίσχυσης (Δήλωση ΟΣΔΕ) )') || this.importDataValue.includes('Application for Single Payment Scheme')) {
      this.importDataValue = 'Application for Single Payment Scheme'
    }
    if (this.importDataValue.includes('Αναλύσεις υπολειμμάτων φυτοφαρμάκων)') || this.importDataValue.includes('Pesticide residue analysis')) {
      this.importDataValue = 'Pesticide residue analysis'
    }
    if (this.importDataValue.includes('Αναλύσεις εδάφους)') || this.importDataValue.includes('Soil analysis')) {
      this.importDataValue = 'Soil analysis'
    }
    if (this.importDataValue.includes('Παραστατικά αγορών)') || this.importDataValue.includes('Purchase documents')) {
      this.importDataValue = 'Purchase documents'
    }
    if (this.importDataValue.includes('Παραστατικά πωλήσεων)') || this.importDataValue.includes('Sales documents')) {
      this.importDataValue = 'Sales documents'
    }
    if (this.importDataValue.includes('Νομιμοποιητικά έγγραφα)') || this.importDataValue.includes('Legal documents')) {
      this.importDataValue = 'Legal documents'
    }
    if (this.importDataValue.includes('Πιστοποιητικά παραγωγού)') || this.importDataValue.includes('Producer certificates')) {
      this.importDataValue = 'Producer certificates'
    }
    if (this.importDataValue.includes('Λοιπά αρχεία)') || this.importDataValue.includes('Other documents')) {
      this.importDataValue = 'Other documents'
    }
    if (this.importDataValue.includes('XML-Εισαγωγη Δήλωση ΟΣΔΕ') || this.importDataValue.includes('XML-IACS Application Import')) {
      this.importDataValue = 'import'
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  uploadButton(uploadRef: TemplateRef<any>) {
    this.dialog.open(uploadRef, {
      width: '650px',
      height: '300px'
    });
  }

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (this.droppedFile of files) {
      console.log(files);
      if (
        this.droppedFile.fileEntry.name.includes('.pdf') ||
        this.droppedFile.fileEntry.name.includes('.csv') ||
        this.droppedFile.fileEntry.name.includes('.xlsx') ||
        this.droppedFile.fileEntry.name.includes('.doc') ||
        this.droppedFile.fileEntry.name.includes('.xml')
      ) {
      } else {
        const fileEntry = this.droppedFile
          .fileEntry as FileSystemDirectoryEntry;
        Swal.fire(
          'Incorrect file Format!',
          'You can upload only: Pdf , Csv, Xml, Excel or Word files!',
          'error'
        );
      }
    }
  }

  deleteDroped(event: any) {
    let nameUploadedfile = event.srcElement.parentElement.innerText.trim();
    const indexToDelete = this.files.findIndex(
      (item) => item.fileEntry.name === nameUploadedfile
    );
    if (indexToDelete !== -1) {
      this.files.splice(indexToDelete, 1);
    } else {
      this.files = [];
      this.droppedFile = [];
    }
  }

  onRowClicked(row: any) {
    this.selectedRow = row.file_id;
    this.selectedRowIndex = row.id;
    this.selectedRow = row
  }

  closeButton() {
    this.files = [];
    this.droppedFile = [];
  }

  submitUpload(event: any) {
    console.log(this.importDataValue)
    console.log(event)
    if (this.files.length == 0) {
      this.files = [];
    } else if (this.files.length > 0) {
      this.files.forEach((element) => {
        if (
          element.fileEntry.name.includes('.pdf') ||
          element.fileEntry.name.includes('.csv') ||
          element.fileEntry.name.includes('.xlsx') ||
          element.fileEntry.name.includes('.doc') ||
          element.fileEntry.name.includes('.xml')
          
        ) {
          const fileEntry = element.fileEntry as FileSystemFileEntry;
          const timestamp = Date();
          fileEntry.file((file: File) => {
            this.filePublic = file;
            const formData = new FormData();
            // formData.append('farmer_id', this.id);
            formData.append('file', file);
            formData.append('category', this.importDataValue)
            this.http.postFiles(formData).subscribe((res) => {
              console.log(res);
              this.getFiles();
            });
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Success',
              text: 'Successfully Submited',
              showConfirmButton: false,
              timer: 2800,
              customClass: {
                popup: 'success-msg',
              },
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
        }
      });
      this.files = [];
    }
  }

  downloadFile(row: any) {
    this.http.downloadFile(row.download_url, this.token).subscribe((blob: Blob) => {
      saveAs(blob, row.filename + '.' + row.file_type);
    },

      (error: any) => {
        console.log(error)
      }
    )
  }

  deletedFile(event: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-secondary',
      },
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        showCloseButton: true,
        title: 'Are you sure?',
        text: ' Do you want to delete this file?',
        showCancelButton: true,
        confirmButtonText: 'Delete File',
        confirmButtonColor: 'rgb(235, 18, 50)',
        cancelButtonText: 'Cancel',
        cancelButtonColor: 'grey',
      })
      .then((result) => {
        if (result.value) {
          this.http.deleteFileFarmer(this.selectedRow.file_id).subscribe(
            (res) => {
              this.getFiles();
              this.selectedRow = event;
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Success',
                text: 'The file has been deleted',
                showConfirmButton: false,
                timer: 2800,
                customClass: {
                  popup: 'success-msg',
                },
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            },
            (error) => {
              Swal.fire('Error', error.error.errors[0].detail, 'error');
            }
          );
        }
        Swal.clickDeny;
      });
  }
}
