import { Component, OnInit, ViewEncapsulation, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { FarmerhistoryComponent } from './farmerhistory/farmerhistory.component';
import { CrophistoryComponent } from './crophistory/crophistory.component';
import { TreatmentshistoryComponent } from '../history/treatmentshistory/treatmentshistory.component';
import { SeedhistoryComponent } from './seedhistory/seedhistory.component';
import { MatSelect } from '@angular/material/select';
import { MatOption, ThemePalette } from '@angular/material/core';
import { FormControl, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ShareDataService } from 'src/app/services/share-data.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class HistoryComponent implements OnInit {
  @ViewChild('select') select!: MatSelect;
  farmerId: any = '';
  plotCodes: any = [];
  codes: any = [];
  code: any;
  selectedChip: string | null = null;

  gridOptions: GridOptions = {
    columnDefs: [],
    rowData: [],
    rowSelection: 'multiple',
    animateRows: true,
    pagination: true,
    paginationPageSize: 10
  };

  plotColumnDefs: any[] = [];
  irrigationColumnDefs: any[] = [];
  fertilisationColumnDefs: any[] = [];
  sprayingColumnDefs: any[] = [];
  harvestColumnDefs: any[] = [];
  treatmentColumnDefs: any[] = [];
  seedPlantColumnDefs: any[] = [];

  defaultColDef: any = {
    flex: 1,
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: {
      buttons: ['reset', 'apply'],
      filterOptions: ['contains', 'notContains', 'equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
    }
  }

  columnDefs: any[] = [
    {
      headerName: 'Date',
      field: 'datetime',
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset', 'apply'],
      },
    },
  ];
  allSelected = false;
  multiSelect = false
  isPlots: boolean = false;
  isIrrigation: boolean = false;
  isFertilisation: boolean = false;
  isSpraying: boolean = false;
  isHarvest: boolean = false;
  isTreatment: boolean = false;
  isSeeding: boolean = false;
  isPlanting: boolean = false;
  selectType: any[] = [];
  selectTypeTreatments: any[] = []
  selectTypeSeed: any[] = []
  selected: any = '';
  cropSelectBoolean: boolean = false
  farmerSelectBoolean: boolean = false
  manageSelectBoolean: boolean = false
  seedSelectBoolean: boolean = false
  plot: any
  plotValueCrop: any;
  plotTypeCrop: any;
  plotValueTreatments: any;
  plotTypeTreatments: any;
  plotValueSeed: any;
  plotTypePlantSeed: any;
  plotChange: any;
  langStatus: any;
  plotChoose: any;
  cropManagement: any;
  @ViewChild(FarmerhistoryComponent) farmer: any;
  @ViewChild(CrophistoryComponent) cropIrrigation: any
  @ViewChild(CrophistoryComponent) cropFertil: any
  @ViewChild(CrophistoryComponent) cropSpray: any
  @ViewChild(CrophistoryComponent) cropHarvest: any
  @ViewChild(TreatmentshistoryComponent) treatment: any
  @ViewChild(SeedhistoryComponent) planting: any
  @ViewChild(SeedhistoryComponent) seeding: any
  selectedItems = new FormControl(<any>[]);

  tabStatus: any;
  @Output() selectedValuesChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  parentCode: any;
  childCode: any;
  parentCodeBoolean: boolean = false;
  childCodeBoolean: boolean = false
  parentBox: boolean = false;
  childBox: boolean = false
  setAllStatus: boolean = false
  eventParent: any;
  eventChild: any;
  startingDate: any;
  endingDate: any;
  startingDateString: any;
  endingDateString: any;
  afmFarmer: any;
  firstName: any;
  lastName: any;
  selectedYear: string = '';

  constructor(private req: RequestService<any>, public translate: TranslateService, private shareDataService: ShareDataService
  ) { }

  ngOnInit() {
    this.shareDataService.getData<any>('farmer').subscribe((data) => {
      if (data) {
        this.selectedYear = data.selected_year_data;
        this.startingDate = `${this.selectedYear}-01-01`;
        this.endingDate = `${this.selectedYear}-12-31`;
        this.startingDateString = this.startingDate;
        this.endingDateString = this.endingDate;
      }
    })
    this.getCodes()
    this.farmerSelectBoolean = true
    if (this.langStatus === 'en') {
      this.selectType = ['Irrigation', 'Fertilisation', 'Spraying', 'Harvest'];
      this.selectTypeTreatments = ['Treatment'];
      this.selectTypeSeed = ['Seeding', 'Planting'];
      this.plotChoose = this.codes
      this.plotTypeCrop = 'Irrigation'
      this.plotTypeTreatments = 'Treatment'
      this.plotTypePlantSeed = 'Planting'
    } else {
      this.selectType = ['Άρδευση', 'Λίπανση', 'Φυτοπροστασία', 'Συγκομιδή'];
      this.selectTypeTreatments = ['Εργασίες'];
      this.selectTypeSeed = ['Σπορά', 'Φύτευση'];
      this.plotChoose = this.codes
      this.plotTypeCrop = 'Άρδευση'
      this.plotTypeTreatments = 'Εργασίες'
      this.plotTypePlantSeed = 'Φύτευση'
    }
    this.getFarmer()
  }

  getFarmer() {
    this.req.getFarmer().subscribe((res: any) => {
      this.afmFarmer = res.vat_number
      this.firstName = res.first_name
      this.lastName = res.last_name
    })
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['plotChoose']) {
      this.code = this.plotChoose
    }
  }


  private getDateString(date: Date): string {
    const year = date.getFullYear();
    const month = this.formatNumber(date.getMonth() + 1);
    const day = this.formatNumber(date.getDate());
    return `${year}-${month}-${day}`;
  }

  private formatNumber(value: number): string {
    return value < 10 ? '0' + value : '' + value;
  }

  applyDate() {
    this.startingDateString = this.getDateString(this.startingDate);
    this.endingDateString = this.getDateString(this.endingDate);
    console.log(this.startingDateString)
  }


  MatCheck(code: any, event: any) {
    this.eventParent = event
    this.parentBox = event.target.checked
    if (this.parentBox) {
      this.parentCode = code
      this.parentCodeBoolean = true
      this.childCodeBoolean = false
      this.setAllStatus = false
    }
  }

  MatCheckSub(subtask: any, event: any) {
    this.eventChild = event
    this.childBox = event.target.checked
    if (this.childBox) {
      this.parentCodeBoolean = false
      this.childCodeBoolean = true
      this.setAllStatus = false
      this.childCode = subtask
    }
  }

  toggleCodeSelection(event: any, code: any) {
    const checked = event.target.checked;
    if (checked) {
      code[4].forEach((subtask: any) => {
        if (!this.plotChoose.includes(subtask)) {
          this.plotChoose.push(subtask);
        }
      });
    } else {
      this.plotChoose = this.plotChoose.filter((option: any) => !code[4].includes(option));
    }
    this.updateAllComplete();
  }

  isCodeSelected(code: any): boolean {
    return code[4].every((subtask: any) => this.plotChoose.includes(subtask));
  }

  toggleSelection(subtask: any, event: any, code: any) {
    const checked = event.target.checked;
    if (checked) {
      if (!this.plotChoose.includes(subtask)) {
        this.plotChoose.push(subtask);
      }
    } else {
      this.plotChoose = this.plotChoose.filter((option: any) => option !== subtask);
    }
    this.updateAllComplete();
  }

  updateAllComplete() {
    this.allSelected = this.plotCodes.every((code: any) =>
      code[4].every((task: any) => this.plotChoose.includes(task))
    );
  }

  setAll(event: any) {
    this.setAllStatus = event.target.checked;
    if (this.setAllStatus) {
      this.parentCodeBoolean = true
      this.childCodeBoolean = true
      this.plotChoose = [];
      this.plotCodes.forEach((code: any) => {
        code[4].forEach((subtask: any) => {
          if (!this.plotChoose.includes(subtask)) {
            this.plotChoose.push(subtask);
          }
        });
      });
    } else if (!this.setAllStatus) {
      this.plotChoose = [];
      this.parentCodeBoolean = false
      this.childCodeBoolean = false
    }
    this.updateAllComplete();
  }

  onTabChange(event: any): void {
    this.tabStatus = event.index
    if (this.tabStatus === 0) {
      this.farmerSelectBoolean = true
      this.cropSelectBoolean = false
      this.manageSelectBoolean = false
      this.seedSelectBoolean = false
      this.isPlanting = false;
    } else if (this.tabStatus === 1) {
      this.isIrrigation = true
      this.farmerSelectBoolean = false
      this.cropSelectBoolean = true
      this.manageSelectBoolean = false
      this.seedSelectBoolean = false
      this.isPlanting = false;
    } else if (this.tabStatus === 2) {
      this.isTreatment = true
      this.farmerSelectBoolean = false
      this.cropSelectBoolean = false
      this.manageSelectBoolean = true
      this.seedSelectBoolean = false
      this.isPlanting = false;
    } else if (this.tabStatus === 3) {
      this.farmerSelectBoolean = false
      this.cropSelectBoolean = false
      this.manageSelectBoolean = false
      this.seedSelectBoolean = true
      this.isPlanting = true
    }
    if (this.tabStatus == 1 && this.plotTypeCrop == 'Irrigation' || this.plotTypeCrop == 'Άρδευση') {
      this.isIrrigation = true
      this.isFertilisation = false
      this.isSpraying = false
      this.isHarvest = false
      this.isPlanting = false;
      this.isSeeding = false;
      this.irrigationData()
    } else if (this.tabStatus == 1 && this.plotTypeCrop == 'Fertilisation' || this.plotTypeCrop == 'Λίπανση') {
      this.isFertilisation = true
      this.isIrrigation = false
      this.isSpraying = false
      this.isHarvest = false
      this.isPlanting = false;
      this.isSeeding = false;
      this.fertData()
    } else if (this.tabStatus == 1 && this.plotTypeCrop == 'Spraying' || this.plotTypeCrop == 'Φυτοπροστασία') {
      this.isSpraying = true
      this.isIrrigation = false
      this.isFertilisation = false
      this.isHarvest = false
      this.isPlanting = false;
      this.isSeeding = false;
      this.sprayData()
    } else if (this.tabStatus == 1 && this.plotTypeCrop == 'Harvest' || this.plotTypeCrop == 'Συγκομιδή') {
      this.isHarvest = true
      this.isIrrigation = false
      this.isFertilisation = false
      this.isSpraying = false
      this.isPlanting = false;
      this.isSeeding = false;
      this.harvestData()
    }
    if (this.tabStatus == 3 && this.plotTypePlantSeed == 'Planting' || this.plotTypePlantSeed == 'Φύτευση') {
      this.isPlanting = true;
      this.isSeeding = false;
      this.plantData()
    } else if (this.tabStatus == 3 && this.plotTypePlantSeed == 'Seeding' || this.plotTypePlantSeed == 'Σπορά') {
      this.isPlanting = false;
      this.isSeeding = true;
      this.seedData()
    }
  }

  getCodes() {
    this.req.getPlots().subscribe((res) => {
      res.forEach(element => {
        this.plotCodes.push([element.plot_code, element.size, element.crop_info[0].prod_tree_number,
        element.location, element.crop_info, element.crop_info.variety, element.crop_info_id]);
      });
    })
  }

  irrigationData() {
    this.cropIrrigation.getIrrigationData()
  }

  fertData() {
    this.cropFertil.getFertilisationData()
  }

  sprayData() {
    this.cropSpray.getSprayingData()
  }

  harvestData() {
    this.cropHarvest.getHarvestData()
  }

  plantData() {
    this.planting.getPlantingData()
  }

  seedData() {
    this.seeding.getSeedingData()
  }

  exportCsvHisory() {
    this.farmer.exportCsv()
  }
  exportExcelFarmer() {
    this.farmer.exportExcel()
  }
  exportPdfFarmer() {
    this.farmer.exportPdf()
  }
  exportCsvCropIrrigation() {
    this.cropIrrigation.exportCsvIrrigation()
  }
  exportExcelCropIrrigation() {
    this.cropIrrigation.exportExcelIrrigation()
  }
  exportPdfCropIrrigation() {
    this.cropIrrigation.exportPdfIrrigation()
  }
  exportCsvCropFertil() {
    this.cropFertil.exportCsvFertil()
  }
  exportCsvFertil() {
    this.cropFertil.exportCsvFertil()
  }
  exportExcelFertil() {
    this.cropFertil.exportExcelFertil()
  }
  exportPdfFertil() {
    this.cropFertil.exportPdfFertil()
  }
  exportCsvSpraying() {
    this.cropSpray.exportCsvSpraying()
  }
  exportExcelSpray() {
    this.cropSpray.exportExcelSpray()
  }
  exportPdfSpray() {
    this.cropSpray.exportPdfSpray()
  }
  exportCsvHarvest() {
    this.cropHarvest.exportCsvHarvest()
  }
  exportExcelHarvest() {
    this.cropHarvest.exportExcelHarvest()
  }
  exportPdfHarvest() {
    this.cropHarvest.exportPdfHarvest()
  }
  exportCsvTreatment() {
    this.treatment.exportCsvTreatment()
  }
  exportExcelTreatment() {
    this.treatment.exportExcelTreatment()
  }
  exportPdfTreatment() {
    this.treatment.exportPdfTreatment()
  }
  exportCsvPlanting() {
    this.planting.exportCsvPlanting()
  }
  exportExcelPlanting() {
    this.planting.exportExcelPlanting()
  }
  exportPdfPlanting() {
    this.planting.exportPdfPlanting()
  }
  exportCsvSeeding() {
    this.seeding.exportCsvSeeding()
  }
  exportExcelSeeding() {
    this.seeding.exportExcelSeeding()
  }
  exportPdfSeeding() {
    this.seeding.exportPdfSeeding()
  }


}
