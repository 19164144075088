<div class="container-fluid">
    <div class="row">
        <div class="col-8 content">
            <div class="row" *ngIf="selectedPlot">
                <h5 class="mb-2 text-lg fw-bold">
                    <span class="text-white px-2 p-1" style="border-radius: 8px; background-color: #30b130;">
                        <i class="fa-solid fa-map-location mx-2" style="color: white;"></i>
                        {{ selectedPlot }}
                    </span>
                </h5>
                <div class="container-card" *ngIf="weatherConditions[0]">
                    <mat-card class="weather-conditions-card">
                        <mat-card-title class="weather-conditions-title">
                            <i class="fas fa-cloud-sun" style="margin-right: 10px;"></i>
                            {{'PEST.WEATHER_CONDITIONS' | translate}}
                        </mat-card-title>
                        <div class="row">
                            <div class="col">
                                <mat-card-content>
                                    <div class="weather-column">
                                        <h3 class="weather-title">
                                            <i class="fas fa-clock"></i> {{'PEST.WEATHER_24' | translate}}
                                        </h3>
                                        <div class="weather-table">
                                            <div class="weather-row weather-header">
                                                <span>{{'PEST.METRIC' | translate}}</span>
                                                <span class="text-center">Min</span>
                                                <span class="text-end">Max</span>
                                            </div>
                                            <div class="weather-row">
                                                <div class="text-left"><i class="fas fa-tint"></i>{{'PEST.HUMIDITY' |
                                                    translate}}</div>
                                                <span
                                                    class="text-center">{{weatherConditions[0].last_24_hours.min_hum_out
                                                    }} %</span>
                                                <span class="text-end">{{weatherConditions[0].last_24_hours.max_hum_out
                                                    }} %</span>
                                            </div>
                                            <div class="weather-row">
                                                <div class="text-left"><i class="fas fa-thermometer-half"></i>
                                                    {{'PEST.TEMPERATURE' | translate}}
                                                </div>
                                                <span
                                                    class="text-center">{{weatherConditions[0].last_24_hours.min_temp_out.toFixed(0)
                                                    }}
                                                    °C
                                                </span>
                                                <span
                                                    class="text-end">{{weatherConditions[0].last_24_hours.max_temp_out.toFixed(0)
                                                    }}
                                                    °C
                                                </span>
                                            </div>
                                            <div class="weather-row">
                                                <div class="text-left"><i
                                                        class="fas fa-cloud-rain"></i>{{'PEST.RAINFALL' | translate}}
                                                </div>
                                                <span class="text-center">-</span>
                                                <span class="text-end">
                                                    {{weatherConditions[0].last_24_hours.total_rainfall_mm}} mm
                                                </span>
                                            </div>
                                            <div class="weather-row">
                                                <div class="text-left"><i class="fas fa-wind"></i>
                                                    {{'PEST.WIND_SPEED' | translate}}
                                                </div>
                                                <span
                                                    class="text-center">{{weatherConditions[0].last_24_hours.min_wind_speed_hi}}
                                                    km/h</span>
                                                <span class="text-end">
                                                    {{weatherConditions[0].last_24_hours.max_wind_speed_hi}} km/h
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </div>
                            <div class="col">
                                <mat-card-content>
                                    <div class="weather-column">
                                        <h3 class="weather-title">
                                            <i class="fas fa-calendar-week"></i> {{'PEST.WEATHER_WEEK' | translate}}
                                        </h3>
                                        <div class="weather-table">
                                            <div class="weather-row weather-header">
                                                <span>{{'PEST.METRIC' | translate}}</span>
                                                <span class="text-center">Min</span>
                                                <span class="text-end">Max</span>
                                            </div>
                                            <div class="weather-row">
                                                <div class="text-left"><i class="fas fa-tint"></i>{{'PEST.HUMIDITY' |
                                                    translate}}</div>
                                                <span class="text-center">{{weatherConditions[0].last_week.min_hum_out
                                                    }}%</span>
                                                <span class="text-end">{{weatherConditions[0].last_week.max_hum_out
                                                    }}%</span>
                                            </div>
                                            <div class="weather-row">
                                                <div class="text-left"><i class="fas fa-thermometer-half"></i>
                                                    {{'PEST.TEMPERATURE' | translate}}</div>
                                                <span
                                                    class="text-center">{{weatherConditions[0].last_week.min_temp_out.toFixed(0)}}°C</span>
                                                <span
                                                    class="text-end">{{weatherConditions[0].last_week.max_temp_out.toFixed(0)}}°C</span>
                                            </div>
                                            <div class="weather-row">
                                                <div class="text-left"><i
                                                        class="fas fa-cloud-rain"></i>{{'PEST.RAINFALL' | translate}}
                                                </div>
                                                <span
                                                    class="text-center">{{weatherConditions[0].last_two_weeks.rainfall_week_1.toFixed(2)}}mm
                                                </span>
                                                <span
                                                    class="text-end">{{weatherConditions[0].last_two_weeks.total_rainfall_mm.toFixed(2)}}mm
                                                </span>
                                            </div>
                                            <div class="weather-row">
                                                <div class="text-left"><i class="fas fa-wind"></i>{{'PEST.WIND_SPEED' |
                                                    translate}}</div>
                                                <span class="text-center">
                                                    {{weatherConditions[0].last_week.min_wind_speed_hi}} km/h
                                                </span>
                                                <span class="text-end">
                                                    {{weatherConditions[0].last_week.max_wind_speed_hi}} km/h
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </div>
                            <div class="col">
                                <mat-card-content>
                                    <div class="weather-column">
                                        <h3 class="weather-title">
                                            <i class="fas fa-calendar-alt"></i> {{'PEST.WEATHER_2_WEEKS' | translate}}
                                        </h3>
                                        <div class="weather-table">
                                            <div class="weather-row weather-header">
                                                <span>{{'PEST.METRIC' | translate}}</span>
                                                <span class="text-center">Min</span>
                                                <span class="text-end">Max</span>
                                            </div>
                                            <div class="weather-row">
                                                <div class="text-left"><i class="fas fa-tint"></i> {{'PEST.HUMIDITY' |
                                                    translate}}</div>
                                                <span
                                                    class="text-center">{{weatherConditions[0].last_two_weeks.min_hum_out
                                                    }}%</span>
                                                <span class="text-end">{{weatherConditions[0].last_two_weeks.max_hum_out
                                                    }}%</span>
                                            </div>
                                            <div class="weather-row">
                                                <div class="text-left"><i class="fas fa-thermometer-half"></i>
                                                    {{'PEST.TEMPERATURE' | translate}}</div>
                                                <span
                                                    class="text-center">{{weatherConditions[0].last_two_weeks.min_temp_out.toFixed(0)}}°C</span>
                                                <span
                                                    class="text-end">{{weatherConditions[0].last_two_weeks.max_temp_out.toFixed(0)}}°C</span>
                                            </div>
                                            <div class="weather-row">
                                                <div class="text-left"><i class="fas fa-cloud-rain"></i>
                                                    {{'PEST.RAINFALL' | translate}}
                                                </div>
                                                <span
                                                    class="text-center">{{weatherConditions[0].last_two_weeks.rainfall_week_2.toFixed(2)}}mm
                                                </span>
                                                <span
                                                    class="text-end">{{weatherConditions[0].last_two_weeks.total_rainfall_mm.toFixed(2)}}mm
                                                </span>
                                            </div>
                                            <div class="weather-row">
                                                <div class="text-left"><i class="fas fa-wind"></i> {{'PEST.WIND_SPEED' |
                                                    translate}}</div>
                                                <span class="text-center">
                                                    {{weatherConditions[0].last_two_weeks.min_wind_speed_hi}} km/h
                                                </span>
                                                <span class="text-end">
                                                    {{weatherConditions[0].last_two_weeks.max_wind_speed_hi}} km/h
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let plot of groupedData">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="d-flex align-items-center p-1">
                                <span
                                    class="icon-container rounded-circle bg-white d-flex justify-content-center align-items-center">
                                    <i class="fa-solid fa-seedling icon-green"></i>
                                </span>
                                <div *ngFor="let dis of plot.diseases">
                                    {{dis.crop.crop_type}}
                                </div>
                            </mat-panel-title>
                            <mat-panel-description>
                                <span>{{'PEST.DAILY_RECOMMENDATION' | translate}}</span>
                                <i class="mx-2 fa-regular fa-clock icon-green"></i>
                                <div *ngFor="let dis of plot.diseases">
                                    {{ dateTime | date: 'MMM d y' }}
                                </div>
                                <div class="ms-auto d-flex justify-content-end align-items-end">
                                    <i *ngIf="plotHasDetectedDisease(plot)"
                                        class="fa-solid fa-bug icon-warning mx-2"></i>
                                    <i *ngIf="plotHasDetectedFertiliser(plot)"
                                        class="fa-solid fa-leaf icon-green mx-2"></i>
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="expansion-content">
                            <div class="row">
                                <div class="col-6">
                                    <h6 class="mb-3 text-medium fw-bold">
                                        <span class="text-white p-1"
                                            style="border-radius: 8px; background-color: #30b130;">
                                            {{'PEST.FERTILISER_TITLE' | translate}}
                                        </span>
                                    </h6>

                                </div>
                                <div class="col-6 d-flex justify-content-end">
                                    <h6 class="mb-3 text-medium fw-bold">
                                        <span class="text-white p-1"
                                            style="border-radius: 8px; background-color: #e4454a;">
                                            {{'PEST.DISEASES_TITLE' | translate}}
                                        </span>
                                    </h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div *ngFor="let fertilisersData of plot.fertilisers">
                                        <div *ngIf="plotHasDetectedFertiliser(plot) else noData">
                                            <div *ngFor="let fert of fertilisersData.report">
                                                <div class="row" *ngIf="fert.allowed">
                                                    <div class="col-6 p-2">
                                                        <div class="detail-row">
                                                            <i class="fa-solid fa-leaf icon-green"></i>
                                                            <p><strong>{{'PEST.FERTILISER' | translate}}:</strong>
                                                                {{ this.translate.store.currentLang == 'gr' ?
                                                                fert.fertiliser.fert_gr :
                                                                fert.fertiliser.fert_en
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div class="detail-row">
                                                            <i class="fa-solid fa-temperature-high icon-green"></i>
                                                            <p><strong>{{'PEST.TEMPERATURE' | translate}}:</strong> {{
                                                                fert.fertiliser.temp_min
                                                                }}°C
                                                                -
                                                                {{
                                                                fert.fertiliser.temp_max }}°C</p>
                                                        </div>
                                                        <div class="detail-row">
                                                            <i class="fa-solid fa-droplet icon-green"></i>
                                                            <p><strong>{{'PEST.HUMIDITY' | translate}}:</strong> {{
                                                                fert.fertiliser.hum_min }}%
                                                                - {{
                                                                fert.fertiliser.hum_max }}%</p>
                                                        </div>
                                                        <div class="detail-row">
                                                            <i class="fa-solid fa-cloud-rain icon-green"></i>
                                                            <p><strong>{{'PEST.RAINFALL' | translate}}:</strong> {{
                                                                fert.fertiliser.rainfall_min
                                                                }}
                                                                mm -
                                                                {{
                                                                fert.fertiliser.rainfall_max }} mm</p>
                                                        </div>
                                                        <div class="detail-row">
                                                            <i class="fa-solid fa-wind icon-green"></i>
                                                            <p><strong>{{'PEST.WIND_SPEED' | translate}}:</strong> {{
                                                                fert.fertiliser.wind_speed_min
                                                                }}
                                                                km/h
                                                                - {{ fert.fertiliser.wind_speed_max }} km/h</p>
                                                        </div>

                                                    </div>
                                                    <div
                                                        class="col-6 p-2 d-flex justify-content-center align-items-center">
                                                        <div
                                                            class="solution-row d-flex justify-content-center align-items-center">
                                                            <p><strong>{{'PEST.SOLUTION' | translate}}:</strong>
                                                                {{ this.translate.store.currentLang == 'gr' ?
                                                                fert.fertiliser.solution_gr :
                                                                fert.fertiliser.solution_en
                                                                }}
                                                            </p>
                                                            <i class="fa-solid fa-lightbulb icon-green px-2"></i>
                                                        </div>
                                                    </div>
                                                    <hr class="p-2">
                                                </div>
                                            </div>
                                        </div>
                                        <ng-template #noData>
                                            <div class="col" style="font-size: large; color: #456c86;">
                                                <div class="row">
                                                    <div class="col-2 d-flex justify-content-end align-items-center">
                                                        <span
                                                            class="icon-container-noReport rounded-circle bg-white d-flex justify-content-center align-items-center">
                                                            <i class="fa-solid fa-ban"></i>
                                                        </span>
                                                    </div>
                                                    <div class="col-10 d-flex justify-content-left align-items-center">
                                                        {{'PEST.FERTILISER_NODATA' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div *ngFor="let diseaseData of plot.diseases">
                                        <div *ngIf="plotHasDetectedDisease(plot) else noData">
                                            <div *ngFor="let dis of diseaseData.report">
                                                <div class="row" *ngIf="dis.detected">
                                                    <div
                                                        class="col-6 p-2 d-flex justify-content-center align-items-center">
                                                        <div
                                                            class="card-warning d-flex justify-content-center align-items-center">
                                                            <i class="fa-solid fa-bug icon-warning px-2"></i>
                                                            <p>
                                                                <strong>{{'PEST.SOLUTION' | translate}}:</strong>
                                                                {{ this.translate.store.currentLang == 'gr' ?
                                                                dis.disease.solution_gr :
                                                                dis.disease.solution_en
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 p-2">
                                                        <div class="detail-row">
                                                            <i class="fa-solid fa-virus icon-warning"></i>
                                                            <p><strong>{{'PEST.DISEASE' | translate}}:</strong>
                                                                {{this.translate.store.currentLang == 'gr' ?
                                                                dis.disease.disease_gr :
                                                                dis.disease.disease_en
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div class="detail-row">
                                                            <i class="fa-solid fa-bug icon-warning"></i>
                                                            <p><strong>{{'PEST.PATHOGEN' | translate}}:</strong>
                                                                {{ this.translate.store.currentLang == 'gr' ?
                                                                dis.disease.pathogen_gr :
                                                                dis.disease.pathogen_en
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <hr class="pt-1 pb-1">
                                                </div>
                                            </div>
                                        </div>
                                        <ng-template #noData>
                                            <div class="col" style="font-size: large; color: #456c86;">
                                                <div class="row">
                                                    <div class="col-2 d-flex justify-content-end align-items-center">
                                                        <span
                                                            class="icon-container-noReport rounded-circle bg-white d-flex justify-content-center align-items-center">
                                                            <i class="fa-solid fa-ban"></i>
                                                        </span>
                                                    </div>
                                                    <div class="col-10 d-flex justify-content-left align-items-center">
                                                        {{'PEST.DISEASE_NODATA' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <div class="col-4 px-0 sidebar">
            <div class="green-places-list">
                <h4 class="text-center py-2 text-white text-gray-600" id="greenTitle">{{'PEST.PLOTS' | translate}}</h4>
                <div class="list-group">
                    <a *ngFor="let plot of plots2" class="list-group-item list-group-item-action"
                        [class.active]="selectedPlot === plot?.location"
                        (click)="selectPlot( plot.plot_code, plot, plot.location)">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <h5 class="mb-1">{{ plot.location }}</h5>
                                <div>
                                    <small>
                                        {{'PEST.PLOT_CROPS' | translate}}:
                                        <i class="fa-solid fa-layer-group fa-lg " [ngClass]="'icon-automatic'">
                                        </i>
                                        <small class="fw-bold">
                                            {{plot.crop.length}}
                                        </small>
                                        <small class="" style="font-size: xx-small;">
                                            {{'PEST.VALUE_PIECES' | translate}}
                                        </small>
                                        <span style="color: rgb(160, 160, 160); font-weight: 600; font-size: small;">
                                            |
                                        </span>
                                    </small>
                                    <small>
                                        {{'PEST.PLOT_SENSORS' | translate}}:
                                        <i class="fa-solid fa-microchip fa-lg " [ngClass]="'icon-water'">
                                        </i>
                                        <small class="fw-bold">
                                            {{plot.devices.length}}
                                        </small>
                                        <small style="font-size: xx-small;">
                                            {{'PEST.VALUE_PIECES' | translate}}
                                        </small>
                                        <span style="color: rgb(160, 160, 160); font-weight: 600; font-size: small;">
                                            |
                                        </span>
                                    </small>
                                    <small>
                                        {{'PEST.PLOT_SIZE' | translate}}:
                                        <i class="fa-solid fa-map fa-lg " [ngClass]="'icon-manual'">
                                        </i>
                                        <small class="fw-bold">
                                            {{plot.size}}
                                        </small>
                                        <small style="font-size: xx-small;">
                                            {{'PEST.VALUE_ACRES' | translate}}
                                        </small>
                                    </small>

                                    <small *ngIf="plot.total_diseases > 0">
                                        <span style="color: rgb(160, 160, 160); font-weight: 600; font-size: small;">
                                            |
                                        </span>
                                        <small>
                                            {{'PEST.PLOT_DISEASES' | translate}}:
                                            <i class=" fa-solid fa-bug fa-lg" [ngClass]="'icon-unavailable'">
                                            </i>
                                            <small class="fw-bold">
                                                {{plot.total_diseases}}
                                            </small>
                                        </small>
                                    </small>
                                    <small *ngIf="plot.total_fertilizers > 0">
                                        <span style="color: rgb(160, 160, 160); font-weight: 600; font-size: small;">
                                            |
                                        </span>
                                        <small>
                                            {{'PEST.PLOT_FERTILISER' | translate}}:
                                            <i class=" fa-solid fa-leaf  fa-lg " [ngClass]="'icon-available'">
                                            </i>
                                            <small class="fw-bold">
                                                {{plot.total_fertilizers}}
                                            </small>
                                        </small>
                                    </small>
                                </div>
                            </div>
                            <div
                                class="icon-container rounded-circle bg-white d-flex justify-content-center align-items-center">
                                <mat-icon style="color:#30b130">place</mat-icon>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>