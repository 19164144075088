import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

let IP = environment.apiUrl;
let http_type = environment.apiType;
interface MeteorologicalData {
  bar_absolute: number;
  hum_out: number;
  last_updated: string;
  rain_24_hr_mm: number;
  rain_day_mm: number;
  rain_rate_mm: number;
  rain_week_mm:number
  rain_month_mm: number,
  rain_year_mm:number,
  temp_out: number;
  wind_dir: number;
  wind_dir_of_gust_10_min: number;
  wind_speed: number;
}

@Injectable({
  providedIn: 'root',
})
export class RequestService<T> {
  //private farmersUrl = 'http://192.168.25.14:8006/farmer/';
  private userProfileUrl = http_type + '://' + IP + '/api/users/profile/';
  private farmersUrl = http_type + '://' + IP + '/api/farmer/';
  private plotsUrl = http_type + '://' + IP + '/api/plot/';
  private seedingUrl = http_type + '://' + IP + '/api/seeding/';
  private plantingUrl = http_type + '://' + IP + '/api/planting/';
  private treatmentUrl = http_type + '://' + IP + '/api/treatment/';
  private cropUrl = http_type + '://' + IP + '/api/crop/';
  private storageUrl = http_type + '://' + IP + '/api/storage/products/';
  private equipmentUrl = http_type + '://' + IP + '/api/equipment/';
  private equipmentLogsMaintenanceUrl = http_type + '://' + IP + '/api/equipment/logs/maintenance/';
  private carbonFootprintUrl = http_type + '://' + IP + '/api/carbonfootprint/';
  private rawProductsUrl = http_type + '://' + IP + '/api/products/raw/';
  private fileupload = http_type + '://' + IP + '/api/files/';
  private downloadUploaded = http_type + '://' + IP + '/api';
  private getfileupload = http_type + '://' + IP + '/api/files/farmer/';
  private overviewWidgets = http_type + '://' + IP + '/api/statistics/overall/';
  private agronomistUrl = http_type + '://' + IP + '/api/agronomist/';
  private resportsUrl = http_type + '://' + IP + '/api/report/';
  private userRelationshipUrl = http_type + '://' + IP + '/api/user_relationship/';
  private recommendationUrl = http_type + '://' + IP + '/api/recommendation/';
  private multimanagementUrl = http_type + '://' + IP + '/api/multi_management/';
  private devices = http_type + '://' + IP + '/api/devices/';
  private devicesOverall = http_type + '://' + IP + '/api/devices/';
  private meteoUrl = http_type + '://' + IP + '/api/meteorological/stations/';
  private meteoHistoryUrl = http_type + '://' + IP + '/api/meteorological/stations/data/historic/?station_id=';
  private opekepeUrl = http_type + '://' + IP + '/api/integrations/opekepe/';
  private contactUrl = http_type + '://' + IP + '/api/integrations/email/send';

  private pestPlots = http_type + '://' + IP + '/api/recommendation_report/';


  constructor(private http: HttpClient) { }

  // GET REQUESTS==================================================================================

  getPlotsPests() {
    return this.http.get<T[]>(this.pestPlots).pipe(
      tap((Data) => { }),
      catchError((err) => {
        throw err
      }));
  }

  getPestsDiseases(plotCode?: any) {
    if (plotCode) {
      return this.http.get<T[]>(this.pestPlots + 'diseases/' + plotCode).pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err
        }));
    } else {
      return this.http.get<T[]>(this.pestPlots + 'diseases/' + '1').pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err
        }));
    }
  }

  getPestsFertilizer(plotCode?: any) {
    if (plotCode) {
      return this.http.get<T[]>(this.pestPlots + 'fertilisers/' + plotCode).pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err
        }));
    } else {
      return this.http.get<T[]>(this.pestPlots + 'fertilisers/' + '1').pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err
        }));
    }
  }

  getDevicePlot(device_id: any) {
    return this.http.get<T[]>(this.plotsUrl + '/?devices=' + device_id).pipe(
      tap((Data) => { }),
      catchError((err) => {
        throw err
      }));
  }

  getDevices(device_id?: any) {
    if (device_id) {
      return this.http.get<T[]>(this.devices + device_id).pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err
        })
      )
    } else {
      return this.http.get<T[]>(this.devices).pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err
        })
      )
    }
  }

  getSingleDevices(device_id: any) {
    return this.http.get<T[]>(this.devices).pipe(
      tap((Data) => { }),
      catchError((err) => {
        throw err
      })
    )
  }

  getDevicesOverall(device_id?: any, sensor_type?: any, startDate?: any, endDate?: any) {
    if (!startDate && !endDate) {
      return this.http.get<T[]>(this.devicesOverall + 'records/?device_id=' + device_id + '&sensor_type=' + sensor_type + '&ordering=time&page[size]=5000&page[number]=last').pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err
        })
      )
    }
    else if (startDate && endDate) {
      return this.http.get<T[]>(this.devicesOverall + 'records/?device_id=' + device_id + '&sensor_type=' + sensor_type + '&ordering=time&page[size]=5000&page[number]=last'
        + '&start=' + startDate
        + '&stop=' + endDate
      ).pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err
        })
      )
    }
    else {
      return this.http.get<T[]>(this.devicesOverall + 'records/?device_id=' + device_id).pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err
        })
      )
    }
  }

  getDevicesOverall2(device_id?: any, sensor_type?: string[], startDate?: any, endDate?: any, agg?: any, interval?: any, intervalNumber?: any) {
    let sensorTypesParam = '';
    if (sensor_type && sensor_type.length > 0) {
      sensorTypesParam = sensor_type.join('&');
    }
    let url = this.devicesOverall + 'records/?device_id=' + device_id;
    if (sensorTypesParam) {
      url += '&' + sensorTypesParam;
    }
    url += '&ordering=time&page[size]=2500&page[number]=last';

    if (startDate && endDate) {
      url += '&start=' + startDate + '&stop=' + endDate;
    }

    if (agg && interval && intervalNumber) {
      url += '&agg=' + agg + '&interval=' + intervalNumber + interval;
    }

    return this.http.get<T[]>(url).pipe(
      tap((Data) => { }),
      catchError((err) => {
        throw err;
      })
    );
  }

  getReportExcel(data: any) {
    return this.http.get<T[]>(this.resportsUrl + 'spd' + data).pipe(
      tap((Data) => { }),
      catchError((err) => {
        throw err
      })
    )
  }

  getReportPdf(data: any) {
    return this.http.get<T[]>(this.resportsUrl + 'general' + data).pipe(
      tap((Data) => { }),
      catchError((err) => {
        throw err
      })
    )
  }

  getUserProfile() {
    return this.http.get<T[]>(this.userProfileUrl).pipe(
      tap((Data) => { }),
      catchError((err) => {
        throw err
      })
    )
  }

  getOverviewWidgets() {
    return this.http.get<T[]>(this.overviewWidgets).pipe(
      tap((Data) => { }),
      catchError((err) => {
        throw err
      })
    )
  }

  getMaintenanceLogs() {
    return this.http.get<T[]>(this.equipmentLogsMaintenanceUrl).pipe(
      tap((Data) => { }),
      catchError((err) => {
        throw err
      })
    )
  }

  getUploadFiles(token: any): Observable<T[]> {
    return this.http
      .get<T[]>(this.getfileupload, {
        headers: new HttpHeaders({ Authorization: token }),
      })
      .pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getEquipments(): Observable<T[]> {
    return this.http.get<T[]>(this.equipmentUrl).pipe(
      tap((Data) => { }),
      catchError((err) => {
        throw err;
      })
    );
  }


  getFarmers() {
    let URL = this.farmersUrl;
    return this.http
      .get<T[]>(URL)
  }

  getPlotFarmer(plot: any) {
    let URL = this.plotsUrl + '?plot_code=' + plot;
    return this.http
      .get<T[]>(URL)
  }
  getPlotFarmerHistory(plot: any) {
    let URL = this.plotsUrl + plot;
    return this.http
      .get<T[]>(URL)
  }

  getAllPlotsFarmer() {
    let URL = this.plotsUrl;
    return this.http
      .get<T[]>(URL)
  }

  getFarmer(): Observable<T[]> {
    return this.http
      .get<T[]>(this.farmersUrl)
  }


  getFarmerOpekepe<T>(options?: any): Observable<HttpResponse<T[]>> {
    return this.http.get<T[]>(this.farmersUrl,
      { observe: 'response', responseType: 'json', ...options }) as Observable<HttpResponse<T[]>>;
  }

  getPlots(): Observable<T[]> {
    return this.http
      .get<T[]>(this.plotsUrl)
  }


  getPlot(id: any): Observable<T> {
    let URL = this.plotsUrl + id;
    return this.http.get<T>(URL).pipe(
      map((data: any) => ({
        _id: data._id,
        crop_info: data.crop_info,
        plot_code: data.plot_code,
        municipal_community: data.municipal_community,
        location: data.location,
        regional_unit: data.regional_unit,
        map_coordinates: data.map_coordinates,
        co_ownership: data.co_ownership,
        size: data.size,
        devices: data.devices,
        species: data.species,
        farmer_id: data.farmer_id,
        type_of_community: data.type_of_community,
        mapped_background: data.mapped_background,
        eligible_area: data.eligible_area,
        protocol_number_eligible_area: data.protocol_number_eligible_area,
        grassland_for_livestock: data.grassland_for_livestock,
        total_eligible_area: data.total_eligible_area,
        applied_area: data.applied_area,
        community_public: data.community_public,
        slope_8: data.slope_8,
        digital_area: data.digital_area,
        digitized_perimeter: data.digitized_perimeter,
        not_available_ab: data.not_available_ab,
        renewable_energy_sources: data.renewable_energy_sources,
        drilling: data.drilling,
        enabled_rights: data.enabled_rights,
        regulation_834_2007: data.regulation_834_2007,
        irrigated: data.irrigated,
        bordering_water: data.bordering_water,
        length_bordering_water: data.length_bordering_water,
        water_usage: data.water_usage,
        energy_type: data.energy_type,
        supply_number: data.supply_number,
        related_code: data.related_code,
        related_list: data.related_list,
        land_usage_code: data.land_usage_code,
        land_usage_dscr: data.land_usage_dscr,
        grazeland_ec: data.grazeland_ec,
        ref_parcel_natura: data.ref_parcel_natura,
        ref_parcel_aegean: data.ref_parcel_aegean,
        ref_parcel_value: data.ref_parcel_value,
        ref_parcel_nitrate: data.ref_parcel_nitrate,
        ref_parcel_wetland: data.ref_parcel_wetland,
        ref_parcel_groundwater: data.ref_parcel_groundwater,
        ref_parcel_national: data.ref_parcel_national,
        title_type: data.title_type,
        legal_parcel_ownership_proof: data.legal_parcel_ownership_proof,
        property_type: data.property_type,
        atak_area: data.atak_area,
        start_date: data.start_date,
        expiry_date: data.expiry_date,
        atak_exception: data.atak_exception,
        atak_code: data.atak_code,
        kaek: data.kaek,
        owner_contract_number: data.owner_contract_number,
        owner_contract_date: data.owner_contract_date,
        intercropping: data.intercropping,
        crop_type: data.crop_type,
        dry_xeric: data.dry_xeric,
        organic_farming: data.organic_farming,
        pef: data.pef,
        subsidised_area: data.subsidised_area,
        interseeded: data.interseeded,
        trees_less: data.trees_less,
        trees_more: data.trees_more,
        prod_tree_number: data.prod_tree_number,
        non_prod_tree_number: data.non_prod_tree_number,
        hive_number: data.hive_number,
        crop_contract_number: data.crop_contract_number,
        crop_contract_date: data.crop_contract_date,
        unharvested: data.unharvested,
        ep_met_epi: data.ep_met_epi,
        diat_per: data.diat_per,
        eco_schemes: data.eco_schemes,
        linked_regimes: data.linked_regimes,
        seed_produce: data.seed_produce,
        integration_data: data.integration_data,
        parallel_activities: data.parallel_activities
      } as T))
    );
  }

  getIrrigations(): Observable<T[]> {
    let URL = this.cropUrl + 'irrigation/';
    return this.http
      .get<T[]>(URL)
  }

  getPlotIrrigation(crop_info_id: any): Observable<T[]> {
    let URL = this.cropUrl + 'irrigation/' + '?crop_info_id=' + crop_info_id;
    return this.http
      .get<T[]>(URL)
  }

  getPlotIrrigationHistory(plotCode: any): Observable<T[]> {
    let URL = this.cropUrl + 'irrigation/' + plotCode;
    return this.http
      .get<T[]>(URL)
  }

  getSpraying(): Observable<T[]> {
    let URL = this.cropUrl + 'spraying/';
    return this.http
      .get<T[]>(URL)
  }

  getPlotSpraying(crop_info_id: any): Observable<T[]> {
    let URL = this.cropUrl + 'spraying/' + '?crop_info_id=' + crop_info_id;
    return this.http
      .get<T[]>(URL)
  }

  getPlotSprayingHistory(plotCode: any): Observable<T[]> {
    let URL = this.cropUrl + 'spraying/' + plotCode;
    return this.http
      .get<T[]>(URL)
  }

  getFertilisation(): Observable<T[]> {
    let URL = this.cropUrl + 'fertilisation/';
    return this.http
      .get<T[]>(URL)
  }

  getPlotFertilisation(crop_info_id: any): Observable<T[]> {
    let URL = this.cropUrl + 'fertilisation/' + '?crop_info_id=' + crop_info_id;
    return this.http
      .get<T[]>(URL)
  }

  getPlotFertilisationHistory(plotCode: any): Observable<T[]> {
    let URL = this.cropUrl + 'fertilisation/' + plotCode;
    return this.http
      .get<T[]>(URL)
  }

  getHarvest(): Observable<T[]> {
    let URL = this.cropUrl + 'harvest/';
    return this.http
      .get<T[]>(URL)
  }

  getPlotHarvest(crop_info_id: any): Observable<T[]> {
    let URL = this.cropUrl + 'harvest/' + '?crop_info_id=' + crop_info_id;
    return this.http
      .get<T[]>(URL)
  }

  getPlotHarvestHistory(plotCode: any): Observable<T[]> {
    let URL = this.cropUrl + 'harvest/' + plotCode;
    return this.http
      .get<T[]>(URL)
  }

  getPlotHarvestWidget(plotCode: any): Observable<T[]> {
    let URL = this.cropUrl + 'harvest/' + '?plot_code=' + plotCode;
    return this.http
      .get<T[]>(URL)
      .pipe(
        map((data: any[]) =>
          data.map(
            ({
              _id,
              farmer_id,
              datetime,
              lot_number,
              plot_code,
              cleaning_of_harvesting_equipment,
              client,
              summ,
            }: any) =>
            ({
              _id,
              farmer_id,
              datetime,
              lot_number,
              plot_code,
              cleaning_of_harvesting_equipment,
              client,
              summ,
            } as T)
          )
        )
      );
  }

  getSeeding(): Observable<T[]> {
    let URL = this.seedingUrl;
    return this.http
      .get<T[]>(URL)
  }

  getPlotSeeding(plotCode: any): Observable<T[]> {
    let URL = this.seedingUrl + '?plot_code=' + plotCode;
    return this.http
      .get<T[]>(URL)
  }

  getPlotSeedingHistory(plotCode: any): Observable<T[]> {
    let URL = this.seedingUrl + plotCode;
    return this.http
      .get<T[]>(URL)
  }

  getPlanting(): Observable<T[]> {
    let URL = this.plantingUrl;
    return this.http
      .get<T[]>(URL)
  }

  getPlotPlanting(plotCode: any): Observable<T[]> {
    let URL = this.plantingUrl + '?plot_code=' + plotCode;
    return this.http
      .get<T[]>(URL)
  }

  getPlotPlantingHistory(plotCode: any): Observable<T[]> {
    let URL = this.plantingUrl + plotCode;
    return this.http
      .get<T[]>(URL)
  }

  getTreatmentDate(start: any, end: any): Observable<T[]> {
    let URL = this.treatmentUrl + '?start_date=' + start + '&end_date=' + end;
    return this.http
      .get<T[]>(URL)
  }

  getTreatment(): Observable<T[]> {
    let URL = this.treatmentUrl;
    return this.http
      .get<T[]>(URL)
  }

  getPlotTreatment(crop_info_id: any): Observable<T[]> {
    let URL = this.treatmentUrl + '?crop_info_id=' + crop_info_id;
    return this.http
      .get<T[]>(URL)
  }

  getPlotTreatmentHistory(plotCode: any): Observable<T[]> {
    let URL = this.treatmentUrl + plotCode;
    return this.http
      .get<T[]>(URL)
  }

  getPlotResidues(crop_info_id: any): Observable<T[]> {
    let URL = this.cropUrl + 'residues/' + '?crop_info_id=' + crop_info_id;
    return this.http
      .get<T[]>(URL)
      .pipe(
        map((data: any[]) =>
          data.map(
            ({
              farmer_id,
              datetime,
              plot_code,
              management_method,
              total_amount,
              _id
            }: any) =>
            ({
              farmer_id,
              datetime,
              plot_code,
              management_method,
              total_amount,
              _id
            } as T)
          )
        )
      );
  }

  getProducts(): Observable<T[]> {
    return this.http
      .get<T[]>(this.storageUrl)
      .pipe(
        map((data: any[]) =>
          data.map(
            ({
              farmer_id,
              batch_number,
              category,
              commercial_name,
              datetime,
              expiration_date,
              initial_quantity,
              quantity,
              raw_product_id
            }: any) =>
            ({
              farmer_id,
              batch_number,
              category,
              commercial_name,
              datetime,
              expiration_date,
              initial_quantity,
              quantity,
              raw_product_id
            } as T)
          )
        )
      );
  }

  getSprayingProducts(): Observable<T[]> {
    let URL = this.storageUrl + '?category=protection';
    return this.http
      .get<T[]>(URL)
      .pipe(
        map((data: any[]) =>
          data.map(
            ({
              farmer_id,
              batch_number,
              category,
              commercial_name,
              datetime,
              expiration_date,
              initial_quantity,
              quantity,
              raw_product_id
            }: any) =>
            ({
              farmer_id,
              batch_number,
              category,
              commercial_name,
              datetime,
              expiration_date,
              initial_quantity,
              quantity,
              raw_product_id
            } as T)
          )
        )
      );
  }

  getFertiliserProducts(): Observable<T[]> {
    let URL = this.storageUrl + '?category=fertiliser';
    return this.http
      .get<T[]>(URL)
      .pipe(
        map((data: any[]) =>
          data.map(
            ({
              farmer_id,
              batch_number,
              category,
              commercial_name,
              datetime,
              expiration_date,
              initial_quantity,
              quantity,
              raw_product_id
            }: any) =>
            ({
              farmer_id,
              batch_number,
              category,
              commercial_name,
              datetime,
              expiration_date,
              initial_quantity,
              quantity,
              raw_product_id
            } as T)
          )
        )
      );
  }

  getRawProducts(): Observable<T[]> {
    return this.http
      .get<T[]>(this.rawProductsUrl)
      .pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getRawProduct(id: any): Observable<T[]> {
    let URL = this.rawProductsUrl + id;
    return this.http
      .get<T[]>(URL)
      .pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getRawProductsSpraying(): Observable<T[]> {
    let URL = this.rawProductsUrl + '?product_type=spraying';
    return this.http
      .get<T[]>(URL)
      .pipe(
        map((data: any[]) =>
          data.map(
            ({
              _id,
              approval_date,
              approval_expiration,
              approval_num,
              approval_owner,
              authority,
              category,
              commercial_name,
              country,
              crop_type,
              dosage,
              efpest,
              justification,
              line_hash,
              percentage,
              phi,
              producer,
              product_id,
              product_type,
              substance,
              supply_expiration
            }: any) =>
            ({
              _id,
              approval_date,
              approval_expiration,
              approval_num,
              approval_owner,
              authority,
              category,
              commercial_name,
              country,
              crop_type,
              dosage,
              efpest,
              justification,
              line_hash,
              percentage,
              phi,
              producer,
              product_id,
              product_type,
              substance,
              supply_expiration
            } as T)
          )
        )
      );
  }

  getRawProductsFertiliser(): Observable<T[]> {
    let URL = this.rawProductsUrl + '?product_type=fertilisation';
    return this.http
      .get<T[]>(URL)
      .pipe(
        map((data: any[]) =>
          data.map(
            ({
              _id,
              EF5,
              EFn,
              Efert,
              K,
              N,
              P,
              commercial_name,
              crop_type,
              fvoln,
              line_hash,
              product_type,
              substance,
              type
            }: any) =>
            ({
              _id,
              EF5,
              EFn,
              Efert,
              K,
              N,
              P,
              commercial_name,
              crop_type,
              fvoln,
              line_hash,
              product_type,
              substance,
              type
            } as T)
          )
        )
      );
  }

  getStorageFertiliser() {
    let URL = this.rawProductsUrl + '?is_requested=True&product_type=fertilisation';
    return this.http
      .get<T[]>(URL)
      .pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getStorageSpray() {
    let URL = this.rawProductsUrl + '?is_requested=True&product_type=spraying';
    return this.http
      .get<T[]>(URL)
      .pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err;
        })
      );
  }

  geFertiliserSuperUser() {
    let URL = this.rawProductsUrl + '?is_requested=False&product_type=fertilisation';
    return this.http
      .get<T[]>(URL)
      .pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getSpraySuperUser() {
    let URL = this.rawProductsUrl + '?is_requested=False&product_type=spraying';
    return this.http
      .get<T[]>(URL)
      .pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getAgronomists() {
    return this.http
      .get<T[]>(this.agronomistUrl)
      .pipe(
        map((data: any[]) =>
          data.map(
            ({ _id, employment_relationship, specify_employment_relationship, father_name, first_name, geote_number, last_name, vat_number, entity_type, company_name }: any) =>
              ({ _id, employment_relationship, specify_employment_relationship, father_name, first_name, geote_number, last_name, vat_number, entity_type, company_name } as T)
          )
        )
      );
  }

  getUserRelationship() {
    return this.http
      .get<T[]>(this.userRelationshipUrl)
      .pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getMultimanagementData() {
    return this.http
      .get<T[]>(this.multimanagementUrl)
      .pipe(
        map((data: any[]) =>
          data.map(
            ({
              crop_info_id,
              crop_type,
              first_name,
              last_name,
              location,
              municipal_community,
              plot_code,
              size,
              variety,
              vat_number,
              selected_schemes
            }: any) =>
            ({
              crop_info_id,
              crop_type,
              first_name,
              last_name,
              location,
              municipal_community,
              plot_code,
              size,
              variety,
              vat_number,
              selected_schemes
            } as T)
          )
        )
      );
  }

  getRecommendationQuestions(type: string) {
    let URL = this.recommendationUrl + 'questions/' + '?crop_type=' + type;
    return this.http
      .get<T[]>(URL)
      .pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getWeatherStations() {
    return this.http
      .get<T[]>(this.meteoUrl)
      .pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err;
        })
      );
  };

  getSingleStation(id: any) {
    let URL = this.meteoUrl + id;
    return this.http
      .get<T[]>(URL)
      .pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getStationMeteorologicalData(id: any): Observable<MeteorologicalData> {
    let URL = this.meteoUrl + 'data/current/?station_id=' + id;
    return this.http.get<MeteorologicalData>(URL).pipe(
      tap((Data) => { }),
      catchError((err) => {
        throw err;
      })
    );
  }

  getStationHistoricalData(id: any, range: string) {
    let URL = this.meteoHistoryUrl + id + '&range=' + range;
    return this.http
      .get<T[]>(URL)
      .pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getOpekepe() {
    return this.http
      .get<T>(this.opekepeUrl)
      .pipe(
        tap((Data) => { }),
        catchError((err) => {
          throw err;
        })
      );
  }

  // POST REQUESTS==================================================================================

  postFiles(fileupload: any) {
    return this.http
      .post(this.fileupload, fileupload)
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postPlot(plotData: any) {
    return this.http
      .post(this.plotsUrl, plotData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postSeed(seedData: any) {
    return this.http
      .post(this.seedingUrl, seedData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postPlant(plantData: any) {
    return this.http
      .post(this.plantingUrl, plantData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postTreatment(treatmentData: any) {
    return this.http
      .post(this.treatmentUrl, treatmentData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postCropFertilisation(fertilisationData: any) {
    let URL = this.cropUrl + 'fertilisation/';
    return this.http
      .post(URL, fertilisationData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postCropHarvest(harvestData: any) {
    let URL = this.cropUrl + 'harvest/';
    return this.http
      .post(URL, harvestData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postCropIrrigation(irrigationData: any) {
    let URL = this.cropUrl + 'irrigation/';
    return this.http
      .post(URL, irrigationData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postCropSpraying(sprayingData: any) {
    let URL = this.cropUrl + 'spraying/';
    return this.http
      .post(URL, sprayingData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postCropResidues(residuesData: any) {
    let URL = this.cropUrl + 'residues/';
    return this.http
      .post(URL, residuesData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postProduct(productData: any) {
    return this.http
      .post(this.storageUrl, productData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postEquipment(equipmentData: any) {
    return this.http
      .post(this.equipmentUrl, equipmentData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postEquipmentMaintenanceLogs(equipmentLogData: any) {
    return this.http
      .post(this.equipmentLogsMaintenanceUrl, equipmentLogData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postCarbonFootprint(footprintData: any) {
    return this.http
      .post(this.carbonFootprintUrl, footprintData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'json',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postStorageProduct(produdctData: any) {
    let URL = this.rawProductsUrl + '?is_requested=True';
    return this.http
      .post(URL, produdctData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postRawProduct(produdctData: any) {
    let URL = this.rawProductsUrl + '?is_requested=False';
    return this.http
      .post(URL, produdctData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postAgronomist(agronomistData: any) {
    return this.http
      .post(this.agronomistUrl, agronomistData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'json',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postFertiliserRecommendation(fertiliserData: any): Observable<any> {
    let URL = this.recommendationUrl + 'fertilization/';
    return this.http.post<any>(URL, fertiliserData, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    }).pipe(
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }


  postTreatmentMultiData(treatmentData: any) {
    let URL = this.treatmentUrl + '?multi_management_usage=True';
    return this.http
      .post(URL, treatmentData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postFertiliserMultiData(fertiliserData: any) {
    let URL = this.cropUrl + 'fertilisation/' + '?multi_management_usage=True';
    return this.http
      .post(URL, fertiliserData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postSprayingMultiData(sprayingData: any) {
    let URL = this.cropUrl + 'spraying/' + '?multi_management_usage=True';
    return this.http
      .post(URL, sprayingData, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postOpekepe(authCode: any) {
    return this.http
      .post(this.opekepeUrl, { 'auth_code': authCode }, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
        observe: 'response'
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  postOpekepeImport() {
    return this.http
      .post(this.opekepeUrl + 'import', null, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
        observe: 'response'
      })
      .pipe(
        catchError((err) => {
          console.log(err.status);
          throw err;
        })
      );
  }

  postContactMail(contactForm: any) {
    return this.http
      .post(this.contactUrl, contactForm, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          throw err;
        })
      );
  }

  // DELETE REQUESTS==================================================================================

  deleteFileFarmer(id: any) {
    return this.http.delete(this.fileupload + id).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  deleteFileEquipment(id: any) {
    return this.http.delete(this.equipmentUrl + id).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  deleteFileMaintenance(id: any) {
    return this.http.delete(this.equipmentLogsMaintenanceUrl + id).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  deleteStorageProduct(id: any) {
    let URL = this.rawProductsUrl + id + '?is_requested=True';
    return this.http.delete(URL).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  deleteRawProduct(id: any) {
    let URL = this.rawProductsUrl + id + '?is_requested=False';
    return this.http.delete(URL).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  deleteAgronomist(id: any) {
    return this.http.delete(this.agronomistUrl + id).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  deleteHistoricalCrop(management: any, id: any) {
    return this.http.delete(this.cropUrl + management + '/' + id).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  deleteHistoricalTreatment(id: any) {
    return this.http.delete(this.treatmentUrl + id).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  deleteHistoricalSeeding(id: any) {
    return this.http.delete(this.seedingUrl + id).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  deleteHistoricalPlanting(id: any) {
    return this.http.delete(this.plantingUrl + id).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  // PUT REQUESTS==================================================================================

  updateFarmer(farmerData: any) {
    let URL = this.farmersUrl;
    return this.http.put(URL, farmerData, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text' }).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  updateEquipment(id: any, equipmentData: any) {
    let URL = this.equipmentUrl + id;
    return this.http.put(URL, equipmentData).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  updateMaintenance(id: any, maintenanceData: any) {
    let URL = this.equipmentLogsMaintenanceUrl + id;
    return this.http.put(URL, maintenanceData).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    )
  }

  updateStorageProduct(id: any, productData: any) {
    let URL = this.rawProductsUrl + id + '?is_requested=True';
    return this.http.put(URL, productData).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  updateRawProduct(id: any, productData: any) {
    let URL = this.rawProductsUrl + id + '?is_requested=False';
    return this.http.put(URL, productData).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

  updateAgronomist(id: any, agronomistData: any) {
    let URL = this.agronomistUrl + id;
    return this.http.put(URL, agronomistData).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    )
  }

  updateSelectedFarmer(selectedFarmerData: any) {
    return this.http.put(this.userRelationshipUrl, selectedFarmerData).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    )
  }

  updateOsdeYear(selectedYear: any) {
    return this.http.put(this.farmersUrl, selectedYear).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    )
  }

  // OTHER REQUESTS==================================================================================

  downloadFile(downloadlink: any, token: any) {
    return this.http.get(this.downloadUploaded + downloadlink, {
      headers: new HttpHeaders({ Authorization: token }),
      responseType: 'blob'
    }).pipe(
      tap((res) => { }),
      catchError((err) => {
        console.log(err);
        throw err;
      })
    );
  }

}
