<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.CONTACT' | translate }}
</h1>
<div class="container mx-auto flex flex-col justify-center items-center px-4">
    <div class="flex-auto mt-8 p-4 lg:px-10 bg-white rounded-lg" id="main_container">
        <form [formGroup]="contactForm">
            <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-600 text-xs font-bold mb-2" for="fullName">
                    {{ 'CONTACT.NAME' | translate }}
                </label>
                <input type="text" id="fullName"
                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full"
                    formControlName="fullName" />
                <div *ngIf="contactForm.get('fullName')?.touched && contactForm.get('fullName')?.invalid"
                    class="text-red-500 text-sm">
                    <small><i class="px-1 fa-solid fa-circle-exclamation"></i>{{ 'CONTACT.NAME_REQUIRED' | translate }}</small>
                </div>
            </div>
            <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-600 text-xs font-bold mb-2" for="email">
                    {{ 'CONTACT.EMAIL' | translate }}
                </label>
                <input type="email" id="email"
                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full"
                    formControlName="email" />
                <div *ngIf="contactForm.get('email')?.touched && contactForm.get('email')?.invalid"
                    class="text-red-500 text-sm">
                    <small *ngIf="contactForm.get('email')?.errors?.['required']"><i class="px-1 fa-solid fa-circle-exclamation"></i>{{ 'CONTACT.EMAIL_REQUIRED' |
                        translate }}</small>
                    <small *ngIf="contactForm.get('email')?.errors?.['email']"><i class="px-1 fa-solid fa-circle-exclamation"></i>{{ 'CONTACT.EMAIL_INVALID' | translate
                        }}</small>
                </div>
            </div>
            <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-600 text-xs font-bold mb-2" for="subject">
                    {{ 'CONTACT.SUBJECT' | translate }}
                </label>
                <input type="text" id="subject"
                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full"
                    formControlName="subject" />
                <div *ngIf="contactForm.get('subject')?.touched && contactForm.get('subject')?.invalid"
                    class="text-red-500 text-sm">
                    <small><i class="px-1 fa-solid fa-circle-exclamation"></i>{{ 'CONTACT.SUBJECT_REQUIRED' | translate }}</small>
                </div>
            </div>
            <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-600 text-xs font-bold mb-2" for="message">
                    {{ 'CONTACT.MESSAGE' | translate }}
                </label>
                <textarea id="message" rows="5"
                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full"
                    formControlName="message"></textarea>
                <div *ngIf="contactForm.get('message')?.touched && contactForm.get('message')?.invalid"
                    class="text-red-500 text-sm">
                    <small><i class="px-1 fa-solid fa-circle-exclamation"></i>{{ 'CONTACT.MESSAGE_REQUIRED' | translate }}</small>
                </div>
            </div>
            <div class="flex flex-col justify-center items-center">
                <button mat-raised-button id="saveButton" class="w-10/12" [disabled]="submitting"
                    (click)="debounceSendEmail()">
                    <mat-icon *ngIf="!submitting">send</mat-icon>
                    <mat-progress-spinner *ngIf="submitting" diameter="20" mode="indeterminate"></mat-progress-spinner>
                    {{ 'CONTACT.SAVE' | translate }}
                </button>
            </div>
        </form>
    </div>
</div>