import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
  private shareData: { [key: string]: BehaviorSubject<any> } = {};

  constructor() { }

  keyExists(key: string) {
    if (!this.shareData[key]) {
      this.shareData[key] = new BehaviorSubject<any>(null);
    }
  }

  setData(key: string, data: any) {
    this.keyExists(key);
    this.shareData[key].next(data);
  }

  getData<T>(key: string): Observable<T> {
    this.keyExists(key);
    return this.shareData[key].asObservable();
  }
}
