import { Component, OnInit, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { forkJoin, map, of, startWith, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RecommendedComponent } from './recommended/recommended.component';
@Component({
  selector: 'app-crop',
  templateUrl: './crop.component.html',
  styleUrls: ['./crop.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
})

export class CropComponent implements OnInit {

  id: any = '';
  plotCodes: any = [];
  dosagePerPlot: any = [];
  fertBatchNumbers: { batchNumber: string, rawProductId: string, name: string }[] = [];
  sprayingBatchNumbers: { batchNumber: string, rawProductId: string, name: string }[] = [];

  //fertilisation body
  fertilizers: any[] = [];
  fertilisationAgronomist: any = '';
  fertilisationBatchNumber: any = '';
  composition: any = '';
  commercialNames: any = [];
  fertilisationCommercialName: any = '';
  otherFertilisationCommercialName: any = '';
  fertilisationStartDate: any = '';
  fertilisationEndDate: any = '';
  fertilisationDosage: any = '';
  fertilisationDosageUnit: any = '';
  // fertilisationDosageAppliedBy: any = '';
  fertilisationDosageAppliedBy100: string = '';
  fertilisationAmountUnit: any = '';
  fertilisationOperator: any = '';
  fertilisationPlotCode: any = '';
  applicationMethod: any = '';
  applicationMethods: any = [
    'APPLICATION_METHOD.HYDRO',
    'APPLICATION_METHOD.DISTRIBUTOR',
    'APPLICATION_METHOD.BULK_TREE',
    'APPLICATION_METHOD.BULK_LINES',
    'APPLICATION_METHOD.FOLIAR_200',
    'APPLICATION_METHOD.FOLIAR_300',
    'APPLICATION_METHOD.FOLIAR_500',
    'APPLICATION_METHOD.FOLIAR_1000',
    'APPLICATION_METHOD.FOLIAR_1500',
    'APPLICATION_METHOD.TURBINE_500',
    'APPLICATION_METHOD.TURBINE_1000',
    'APPLICATION_METHOD.TURBINE_1500',
    'APPLICATION_METHOD.TURBINE_2000',
  ];
  totalN: any = '';
  totalP: any = '';
  totalK: any = '';
  productId: { [key: string]: string } = {};
  selectedProductId: string = '';

  //irrigation body
  irrigationDate: any = '';
  irrigationStartTime: any = '';
  irrigationEndTime: any = '';
  irrigationHours: any = '';
  irrDays: any = '';
  irrHours: any = '';
  irrMinutes: any = '';
  irrigationStartHours: any = '';
  irrigationStartMinutes: any = '';
  frequencyOptions: any = [
    'FREQUENCY.WEEKDAYS',
    'FREQUENCY.EVERYDAY',
    'FREQUENCY.WEEKLY',
    'FREQUENCY.MONTHLY',
    'FREQUENCY.YEARLY',
    'FREQUENCY.CUSTOM'
  ];
  frequency: any = '';
  irrigationMethods: any = [
    'IRRIGATION_METHOD.FLOODING',
    'IRRIGATION_METHOD.SPRINLER',
    'IRRIGATION_METHOD.DRIP'
  ];
  irrigationMethod: any = '';
  irrigationPlotCode: any = '';
  quantity: any = '';
  irrigationPlotsSum: any = 0;
  waterSourceQuantity: any = 0
  waterSource: any = ['WATER.INDIVIDUAL', 'WATER.GROUP', 'WATER.NETWORK', 'WATER.WELL'];
  sourceItem: any = ''

  //spraying body
  recommendedDosage: any = '';
  substance: any = ''
  sprayingAgronomist: any = '';
  solutionAmount: any = '';
  solutionUnit: any = '';
  solutionUnits: any = [
    'lt'
  ]
  sprayingBatchNumber: any = '';
  sprayingCommercialNames: any = []
  commercialName: any = '';
  otherCommercialName: any = '';
  cultivationStage: any = '';
  cultivationStages: any = [
    'CULTIVATION_STAGE.DORMACY',
    'CULTIVATION_STAGE.BUD',
    'CULTIVATION_STAGE.LEAVES',
    'CULTIVATION_STAGE.VEGETA',
    'CULTIVATION_STAGE.FLOWER',
    'CULTIVATION_STAGE.FRUIT',
    'CULTIVATION_STAGE.HARVEST',
  ]
  sprayingStartDate: any = '';
  sprayingEndDate: any = '';
  dosage: any = '';
  dosageUnit: any = '';
  dosageUnitsAcres: any = [
    'CROP.ITEM',
    'CROP.KG'
  ];
  dosageUnits: any = [
    'gr',
    'kg',
    'ml',
    'lt',
  ]
  sprayingHours: any = '';
  sprayingMinutes: any = '';
  implementationMethod: any = '';
  implementationMethods: any = [
    'IMPLEMENTATION_METHOD.HANDHELD',
    'IMPLEMENTATION_METHOD.TANK_GAS',
    'IMPLEMENTATION_METHOD.TRACTOR',
    'IMPLEMENTATION_METHOD.GROUND',
    'IMPLEMENTATION_METHOD.NOZZLE',
    'IMPLEMENTATION_METHOD.BP',
    'IMPLEMENTATION_METHOD.TANK_200',
    'IMPLEMENTATION_METHOD.TANK_300',
    'IMPLEMENTATION_METHOD.TANK_500',
    'IMPLEMENTATION_METHOD.TANK_1000',
    'IMPLEMENTATION_METHOD.TANK_1500',
    'IMPLEMENTATION_METHOD.TURBINE_500',
    'IMPLEMENTATION_METHOD.TURBINE_1000',
    'IMPLEMENTATION_METHOD.TURBINE_1500',
    'IMPLEMENTATION_METHOD.TURBINE_2000'
  ];
  justification: any = '';
  sprayingOperator: any = '';
  phi: any = '';
  sprayingPlotCode: any = '';
  amountApplied: any = '';
  amountAppliedBy: any = '';
  amountUnit: any = '';
  amountUnits: any = [
    'lt'
  ];
  dosageAppliedBy: any = '';
  dosageBy: any = [
    'CROP.DOSAGE_ACRE',
    'CROP.DOSAGE_TREE',
    'CROP.DOSAGE_100'
  ];
  dosageBySpray: any = [
    'CROP.DOSAGE_ACRE',
    'CROP.DOSAGE_TREE',
  ];
  weatherCondition: any = '';
  weatherConditions: any = [
    'WEATHER.SUN',
    'WEATHER.SUN_CLOUD',
    'WEATHER.CLOUDS',
    'WEATHER.RAIN',
    'WEATHER.SNOW',
    'WEATHER.HAIL'
  ]
  relatedActionFert: any = ['CROP.RELATED_ACTION_NONE', 'CROP.RELATED_I', 'CROP.RELATED_IA', 'CROP.RELATED_IB']
  relatedActionCategoryFert: any = []
  relatedActionSpray: any = ['CROP.RELATED_ACTION_NONE', 'CROP.RELATED_B', 'CROP.RELATED_C', 'CROP.RELATED_D', 'CROP.RELATED_IC']
  sprayingProductId: { [key: string]: string } = {};
  translatedOtherValue: string = ''

  //harvest body
  cleaning: any = '';
  client: any = '';
  harvestDate: any = '';
  lotNumber: any = '';
  harvestPlotCode: any = '';
  harvestSum: any = '';
  warnHarvest: boolean = false;
  harvestStartDate: any;
  harvestEndDate: any;
  harvestHours: any;
  harvestMinutes: any;

  //residue body
  residuePlotCode: any = '';
  residueAmount: any = '';
  residueDate: any = '';
  residueMethod: any = '';
  residueMethods: any = [
    'RESIDUES.UNTREAT',
    'RESIDUES.NON_FORCED_COMOST',
    'RESIDUES.FORCED_COMPOST',
    'RESIDUES.LEFT',
    'RESIDUES.BURNED',
    'RESIDUES.EXPORT'
  ];

  harvestId: any = '';
  residueId: any = '';
  harvests: any = [];
  public langStatus: any
  public residueChoose: any
  chooseTree: boolean = false;
  chooseAcre: boolean = false;
  chooseHundredSpray: boolean = false
  chooseTreeSpray: boolean = false;
  chooseAcreSpray: boolean = false;
  treeNumber: any = 0;
  acresNumber: any = 0;
  totalAmount: any;
  acresSpray: any = 0;
  treeSpray: any = 0;
  totalAmountSpray: any = 0;
  sprayLast: any;
  sprayName: any;
  sprayPhi: any;
  harvestPlot: any
  plotSize: any
  plotCode: any;
  wrongTime: boolean = false;
  documentNumber: any;
  residuesTreatments: any[] = []
  difPhi: any;
  wrongPhi: boolean = false;
  warnIrrigation: boolean = false;
  sumDateTime: any;
  sumDateTimeSpray: any;
  warnSpray: boolean = false;
  infoFertil: boolean = false;
  infoIrrigation: boolean = false;
  infoSpraying: boolean = false;
  infoHarvest: boolean = false;
  infoResidues: boolean = false;
  totalNSum: any;
  totalPSum: any;
  totalKSum: any;
  amountAppliedByHundred: any;
  cropInfo: any[] = [];
  arraySend: any[] = [];
  plotName: boolean = false;
  chooseHundred: boolean = false;

  allComplete: boolean = false;
  fertToggled: boolean = true;
  sprayToggled: boolean = true;
  selectedItems = new FormControl(<any>[]);
  plotChoose: any;
  array: any[] = []
  @ViewChild('select') select!: MatSelect;
  selectedOptions: any[] = [];
  childBoxValue: boolean = false;
  statusRound: boolean = false;
  sub100Value: boolean = false;
  chooseHundredAcres: boolean = false
  chooseHundredTree: boolean = false
  crop_id: any;
  relatedActionUnit: any
  relatedActionCategoryUnit: any
  relatedActionEmpty: boolean = false;
  relatedActionCategoryEmpty: boolean = false;
  relatedActionSprayUnit: any;
  relatedActionSprayEmpty: boolean = false;
  resID: any;
  itemToggled: boolean = false;
  itemAmount: any;
  methodEng: any;
  cultivationStageEmpty: boolean = false;
  applicationMethodEmpty: boolean = false
  weatherConditionEmpty: boolean = false;
  fertilisationStartDateEmpty: boolean = false;
  fertilisationEndDateEmpty: boolean = false;
  fertilisationPlotsEmpty: boolean = false;

  //Form Controls for auto-complete
  filteredCommercialNames: Observable<string[]>[] = [];
  filteredSprayCommercialNames!: Observable<string[]>;
  fertilizerControls: FormControl[] = [];
  sprayCommercialNameControl = new FormControl('');

  constructor(
    private req: RequestService<any>,
    private _location: Location,
    public translate: TranslateService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getId();
    this.infoFertil = true;

    this.req.getRawProductsFertiliser().subscribe((data: any[]) => {
      this.commercialNames = data.map((item) => item.commercial_name);
      data.forEach((item) => {
        this.productId[item.commercial_name] = item._id;
      });
    });

    this.req.getRawProductsSpraying().subscribe((data: any[]) => {
      this.sprayingCommercialNames = data.map((item) => item.commercial_name);
      data.forEach((item) => {
        this.sprayingProductId[item.commercial_name] = item._id;
      });
    });

    this.addFertilizer();
    this.fertilizers.forEach((_, index) => this.initializeFertilizerControl(index));

    this.filteredSprayCommercialNames = this.sprayCommercialNameControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterSprayCommercialNames(value))
    );

  }

  initializeFertilizerControl(index: number) {
    const control = new FormControl('');
    this.fertilizerControls[index] = control;

    this.filteredCommercialNames[index] = control.valueChanges.pipe(
      startWith(''),
      map(value => this.filterCommercialNames(value))
    );
  }

  filterCommercialNames(value: string | null): string[] {
    const filterValue = (value || '').toLowerCase();
    return this.commercialNames.filter((name: string) => name.toLowerCase().includes(filterValue));
  }

  filterSprayCommercialNames(value: string | null): string[] {
    const filterValue = (value || '').toLowerCase();
    return this.sprayingCommercialNames.filter((name: string) => name.toLowerCase().includes(filterValue));
  }

  toggleCodeSelection(checked: boolean, code: any) {
    if (checked) {
      code[4].forEach((subtask: any) => {
        if (!this.selectedOptions.includes(subtask)) {
          this.selectedOptions.push(subtask);
        }
      });
    } else {
      this.selectedOptions = this.selectedOptions.filter(option => !code[4].includes(option));
    }
    this.updateAllComplete();
  }

  isCodeSelected(code: any): boolean {
    return code[4].every((subtask: any) => this.selectedOptions.includes(subtask));
  }

  toggleSelection(subtask: any, event: any) {
    const checked = event.target.checked;
    if (checked) {
      if (!this.selectedOptions.includes(subtask)) {
        this.selectedOptions.push(subtask);
      }
    } else {
      this.selectedOptions = this.selectedOptions.filter(option => option !== subtask);
    }
    this.updateAllComplete();
  }

  updateAllComplete() {
    this.allComplete = this.plotCodes.every((code: any) =>
      code[4].every((task: any) => this.selectedOptions.includes(task))
    );
  }

  setAll(checked: boolean) {
    if (checked) {
      this.plotCodes.forEach((code: any) => {
        code[4].forEach((subtask: any) => {
          if (!this.selectedOptions.includes(subtask)) {
            this.selectedOptions.push(subtask);
          }
        });
      });
    } else {
      this.plotCodes.forEach((code: any) => {
        code[4].forEach((subtask: any) => {
          this.selectedOptions = this.selectedOptions.filter(option => option !== subtask);
        });
      });
    }
    this.updateAllComplete();
  }

  onTabChangeManage(event: any) {
    if (event.index === 0) {
      this.infoFertil = true;
      this.infoIrrigation = false
      this.infoSpraying = false
      this.infoHarvest = false
      this.infoResidues = false
    } else if (event.index === 1) {
      this.infoFertil = false;
      this.infoIrrigation = true
      this.infoSpraying = false
      this.infoHarvest = false
      this.infoResidues = false
    } else if (event.index === 2) {
      this.infoFertil = false;
      this.infoIrrigation = false
      this.infoSpraying = true
      this.infoHarvest = false
      this.infoResidues = false
    } else if (event.index === 3) {
      this.infoFertil = false;
      this.infoIrrigation = false
      this.infoSpraying = false
      this.infoHarvest = true
      this.infoResidues = false
    } else if (event.index === 4) {
      this.infoFertil = false;
      this.infoIrrigation = false
      this.infoSpraying = false
      this.infoHarvest = false
      this.infoResidues = true
    }
  }

  async getId() {
    this.req.getSprayingProducts().subscribe((res) => {
      res.forEach(element => {
        this.sprayingBatchNumbers.push({ batchNumber: element.batch_number, rawProductId: element.raw_product_id, name: element.commercial_name });
      });
    })
    this.req.getFertiliserProducts().subscribe((res) => {
      res.forEach(element => {
        this.fertBatchNumbers.push({ batchNumber: element.batch_number, rawProductId: element.raw_product_id, name: element.commercial_name });
      });
    })
    this.req.getPlots().subscribe((res) => {
      res.forEach(element => {
        this.plotCodes.push([element.plot_code, element.size, element.crop_info[0].prod_tree_number,
        element.location, element.crop_info, element.crop_info.variety]);
      });
    })
  }

  getHarvests(subtask: any) {
    this.harvests = [];
    this.harvestId = '';
    if (!subtask) {
      return;
    }
    this.req.getPlotHarvest(subtask.crop_info_id).subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        this.harvests.push({ displayText: 'Plot ' + res[i].plot_code + ' - Date ' + res[i].datetime + ` (${res[i]._id.substring(0, 5)})`, harvestId: res[i]._id });
      }
    })
  }

  getTreatment(subtask: any) {
    this.crop_id = subtask.crop_info_id
    this.req.getPlotTreatment(this.crop_id).subscribe(res => {
      res.forEach((el: any) => {
        this.residuesTreatments.push(el)
      })
    })
  }

  getSpraying(subtask: any) {
    this.crop_id = subtask.crop_info_id
    this.plotSize = subtask.pef
    this.req.getPlotSpraying(this.crop_id).subscribe(res => {
      this.sprayLast = this.datePipe.transform(res[0].end_datetime, 'MM-dd-yyyy')
      this.sprayName = res[0].commercial_name
      this.sprayPhi = res[0].phi
    });
    this.difPhi = this.harvestDate - this.sprayLast
  }

  phiSpray(event: any) {
    const fullDate = this.datePipe.transform(this.harvestDate, 'MM-dd-yyyy')
    const harvestDateFormat: any = this.datePipe.transform(this.harvestDate, 'MM-dd-yyyy')?.split('-')[1]
    const sprayLastFormat: any = this.sprayLast.split('-')[1]
    const valuePhi = harvestDateFormat - sprayLastFormat
    const lastSprayDate = new Date(this.harvestDate)
    const harvestDate = new Date(this.sprayLast)
    const timeDiff = lastSprayDate.getTime() - harvestDate.getTime()
    const totalDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1
    if (totalDays >= this.sprayPhi) {
      this.warnHarvest = false
    } else {
      this.warnHarvest = true
    }
  }

  changeHarvest(event: any) {
    this.harvestSum = this.harvestPlot * this.plotSize
  }

  onChangeFert(index: number) {
    const relatedActionUnit = this.fertilizers[index].relatedActionUnit;

    if (relatedActionUnit === 'CROP.RELATED_ACTION_NONE') {
      this.fertilizers[index].relatedActionCategoryFert = ['CROP.RELATED_ACTION_NONE'];
    } else if (relatedActionUnit === 'CROP.RELATED_I') {
      this.fertilizers[index].relatedActionCategoryFert = ['CROP.CATEGORY_1', 'CROP.CATEGORY_2'];
    } else if (relatedActionUnit === 'CROP.RELATED_IA') {
      this.fertilizers[index].relatedActionCategoryFert = ['CROP.CATEGORY_1', 'CROP.CATEGORY_2', 'CROP.CATEGORY_3'];
    } else if (relatedActionUnit === 'CROP.RELATED_IB') {
      this.fertilizers[index].relatedActionCategoryFert = ['CROP.CATEGORY_1A', 'CROP.CATEGORY_1B', 'CROP.CATEGORY_2', 'CROP.CATEGORY_3'];
    }
  }

  changeFertilisation(index: number) {
    const fertilizer = this.fertilizers[index];
    fertilizer.acresNumber = 0;
    fertilizer.treeNumber = 0;
    fertilizer.totalAmount = 0;

    if (this.selectedOptions.length > 0 && (fertilizer.fertilisationDosageAppliedBy.includes('CROP.DOSAGE_ACRE'))) {
      fertilizer.chooseAcre = true;
      fertilizer.chooseTree = false;
      fertilizer.chooseHundred = false;
      fertilizer.sub100Value = false;
      this.selectedOptions.forEach((element: any) => {
        fertilizer.acresNumber += element.pef;
      });
      const AmountFertil = fertilizer.acresNumber * fertilizer.fertilisationDosage;
      fertilizer.totalAmount = AmountFertil.toFixed(2);
    } else if (this.selectedOptions.length > 0 && (fertilizer.fertilisationDosageAppliedBy.includes('CROP.DOSAGE_TREE'))) {
      fertilizer.chooseAcre = false;
      fertilizer.chooseTree = true;
      fertilizer.chooseHundred = false;
      fertilizer.sub100Value = false;
      this.selectedOptions.forEach((element: any) => {
        fertilizer.treeNumber += element.prod_tree_number;
      });
      const AmountFertil = fertilizer.treeNumber * fertilizer.fertilisationDosage;
      fertilizer.totalAmount = AmountFertil.toFixed(2);
    } else if (this.selectedOptions.length > 0 && (fertilizer.fertilisationDosageAppliedBy.includes('CROP.DOSAGE_100'))) {
      fertilizer.sub100Value = true;
      if (fertilizer.fertilisationDosageAppliedBy100.includes('CROP.DOSAGE_ACRE')) {
        fertilizer.chooseHundredAcres = true;
        fertilizer.chooseHundredTree = false;
        fertilizer.chooseHundred = true;
        fertilizer.chooseTree = false;
        fertilizer.chooseAcre = false;
        this.selectedOptions.forEach((element: any) => {
          fertilizer.acresNumber += element.pef;
        });
        fertilizer.totalAmount = (fertilizer.fertilisationDosage * fertilizer.solutionAmount / 100) * fertilizer.acresNumber;
      } else if (fertilizer.fertilisationDosageAppliedBy100.includes('CROP.DOSAGE_TREE')) {
        fertilizer.chooseHundredTree = true;
        fertilizer.chooseHundredAcres = false;
        fertilizer.chooseHundred = true;
        fertilizer.chooseTree = false;
        fertilizer.chooseAcre = false;
        this.selectedOptions.forEach((element: any) => {
          fertilizer.treeNumber += element.prod_tree_number;
        });
        const AmountFertil = (fertilizer.fertilisationDosage * fertilizer.solutionAmount / 100) * fertilizer.treeNumber;
        fertilizer.totalAmount = AmountFertil.toFixed(2);
      }
    }
    this.calculateTotalNPK(index);
  }

  changeIrrigation() {
    this.irrigationPlotsSum = 0
    const endDateStart = new Date(this.irrigationEndTime).getTime()
    const startDateStart = new Date(this.irrigationStartTime).getTime()
    this.sumDateTime = endDateStart - startDateStart
    const totalSeconds = Math.floor(this.sumDateTime / 1000);
    const seconds = totalSeconds % 60
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor(totalSeconds / 3600);
    const days = Math.floor(hours / 24);
    const CalHours = minutes * 0.01667 + hours
    if (minutes < 0) {
      this.wrongTime = true
      this.irrMinutes = 0
    } else {
      this.wrongTime = false
      this.irrMinutes = minutes
    }
    if (hours < 0) {
      this.wrongTime = true
      this.irrHours = 0
    } else {
      this.wrongTime = false
      this.irrHours = hours
    }
    if (days < 0) {
      this.wrongTime = true
      this.irrDays = 0
    } else {
      this.wrongTime = false
      this.irrDays = days
    }
    if (this.irrHours >= 24) {
      this.warnIrrigation = true
    } else {
      this.warnIrrigation = false
    }
    this.selectedOptions.forEach((el: any) => {
      this.irrigationPlotsSum += el.pef
    });
    this.quantity = this.irrigationPlotsSum * this.waterSourceQuantity * CalHours
  }

  changeSpraying() {
    this.acresSpray = 0;
    this.totalAmountSpray = 0;
    this.sprayingHours = 0;
    this.sprayingMinutes = 0;
    const endDateStart = new Date(this.sprayingEndDate).getTime()
    const startDateStart = new Date(this.sprayingStartDate).getTime()
    this.sumDateTimeSpray = endDateStart - startDateStart
    this.sumDateTime = endDateStart - startDateStart
    const totalSeconds = Math.floor(this.sumDateTimeSpray / 1000);
    const seconds = totalSeconds % 60
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor(totalSeconds / 3600);
    const days = Math.floor(hours / 24);
    const CalHours = minutes * 0.01667 + hours
    if (hours < 0) {
      this.warnSpray = true
      this.sprayingHours = 0
    } else {
      this.warnSpray = false
      this.sprayingHours = hours
    }
    if (minutes < 0) {
      this.warnSpray = true
      this.sprayingMinutes = 0
    } else {
      this.warnSpray = false
      this.sprayingMinutes = minutes
    }
    if (days < 0) {
      this.warnSpray = true
    } else {
      this.warnSpray = false
    }
    if (this.selectedOptions.length > 0 && this.dosageAppliedBy.includes('CROP.DOSAGE_ACRE')) {
      this.chooseAcreSpray = true
      this.chooseTreeSpray = false
      this.chooseHundredSpray = false
      this.selectedOptions.forEach((element: any) => {
        this.acresSpray += element.pef
      })
      this.totalAmountSpray = this.acresSpray * this.dosage
    } else if (this.selectedOptions.length > 0 && this.dosageAppliedBy.includes('CROP.DOSAGE_TREE')) {
      this.chooseAcreSpray = false
      this.chooseTreeSpray = true
      this.chooseHundredSpray = false
      this.cropInfo.forEach((element: any) => {
        element.forEach((el: any) => {
          this.acresSpray += el.prod_tree_number
        })
      })
      this.totalAmountSpray = this.acresSpray * this.dosage
    } else if (this.selectedOptions.length > 0 && this.dosageAppliedBy.includes('100')) {
      this.chooseHundredSpray = true
      this.chooseAcreSpray = false
      this.chooseTreeSpray = false
      if (this.amountAppliedByHundred.includes('CROP.DOSAGE_ACRE')) {
        this.selectedOptions.forEach((element: any) => {
          this.acresSpray += element.pef
        })
        this.totalAmountSpray = (this.dosage * this.solutionAmount / 100) * this.acresSpray
      } else if (this.amountAppliedByHundred.includes('CROP.DOSAGE_100')) {
        this.selectedOptions.forEach((element: any) => {
          this.acresSpray += element.prod_tree_number
        })
        this.totalAmountSpray = (this.dosage * this.solutionAmount / 100) * this.acresSpray
      }
    }
  }

  changeResidues(event: any) {
    this.residueId = event.treatment + '-' + event.datetime
    this.resID = event._id
  }

  onFertBatchChange(index: number) {
    const selectedBatchNumber = this.fertilizers[index].fertilisationBatchNumber;
    if (selectedBatchNumber) {
      const selectedEntry = this.fertBatchNumbers.find((batch: any) => batch.batchNumber === selectedBatchNumber);
      if (selectedEntry) {
        const selectedRawProductId = selectedEntry.rawProductId;
        if (selectedRawProductId) {
          this.req.getRawProduct(selectedRawProductId).subscribe((res: any) => {
            this.fertilizers[index].fertilisationCommercialName = res.commercial_name;
            this.fertilizers[index].composition = res.substance;
            this.fertilizers[index].totalK = res.K;
            this.fertilizers[index].totalN = res.N;
            this.fertilizers[index].totalP = res.P;
            this.calculateTotalNPK(index);
          });
        }
      }
    }
  }

  onFertCommercialNameChange(index: number) {
    const selectedCommercialName = this.fertilizerControls[index].value;

    if (selectedCommercialName === 'other') {
      this.fertilizers[index].totalN = '';
      this.fertilizers[index].totalP = '';
      this.fertilizers[index].totalK = '';
      this.fertilizers[index].composition = '';
    } else if (this.commercialNames.includes(selectedCommercialName)) {
      const selectedProductId = this.productId[selectedCommercialName];
      this.req.getRawProduct(selectedProductId).subscribe((res: any) => {
        this.fertilizers[index].totalN = res.N;
        this.fertilizers[index].totalP = res.P;
        this.fertilizers[index].totalK = res.K;
        this.fertilizers[index].composition = res.substance;
        this.calculateTotalNPK(index);
      });
    }
  }

  onSprayingBatchChange() {
    this.commercialName = '';
    this.substance = '';
    this.justification = '';
    this.recommendedDosage = '';
    const selectedBatchNumber = this.sprayingBatchNumber;
    if (selectedBatchNumber) {
      const selectedEntry = this.sprayingBatchNumbers.find((batch: any) => batch.batchNumber === selectedBatchNumber);

      if (selectedEntry) {
        const selectedRawProductId = selectedEntry.rawProductId;

        if (selectedRawProductId) {
          this.req.getRawProduct(selectedRawProductId).subscribe((res: any) => {
            this.commercialName = res.commercial_name;
            this.substance = res.substance;
            this.justification = res.justification;
            this.recommendedDosage = res.dosage;
          })
        }
      }
    }
  }

  onSprayingCommercialNameChange() {
    const selectedCommercialName = this.sprayCommercialNameControl.value;
    if (selectedCommercialName) {
      const exists = this.sprayingCommercialNames.includes(selectedCommercialName);
      if (exists) {
        const selectedProductId = this.sprayingProductId[selectedCommercialName];
        this.req.getRawProduct(selectedProductId).subscribe((res: any) => {
          this.substance = res.substance;
          this.justification = res.justification;
          this.recommendedDosage = res.dosage;
        });
      }
    }
  }

  calculateTotalNPK(index: number) {
    const fertilizer = this.fertilizers[index];
    if (fertilizer.fertilisationDosageUnit === 'gr' || fertilizer.fertilisationDosageUnit === 'ml') {
      const totalAmount = parseFloat(fertilizer.totalAmount) / 1000;
      fertilizer.totalNSum = (totalAmount * parseFloat(fertilizer.totalN)) / 100;
      fertilizer.totalPSum = (totalAmount * parseFloat(fertilizer.totalP)) / 100;
      fertilizer.totalKSum = (totalAmount * parseFloat(fertilizer.totalK)) / 100;
    } else if (fertilizer.fertilisationDosageUnit === 'kg' || fertilizer.fertilisationDosageUnit === 'lt') {
      fertilizer.totalNSum = (parseFloat(fertilizer.totalAmount) * parseFloat(fertilizer.totalN)) / 100;
      fertilizer.totalPSum = (parseFloat(fertilizer.totalAmount) * parseFloat(fertilizer.totalP)) / 100;
      fertilizer.totalKSum = (parseFloat(fertilizer.totalAmount) * parseFloat(fertilizer.totalK)) / 100;
    }
  }

  onChangeHarvest() {
    const endDateStart = new Date(this.sprayingEndDate).getTime()
    const startDateStart = new Date(this.sprayingStartDate).getTime()
  }

  addFertilizer() {
    const newFertilizer = {
      fertToggled: this.fertToggled,
      fertilisationBatchNumber: '',
      fertilisationCommercialName: '',
      totalN: '',
      totalP: '',
      totalK: '',
      composition: '',
      relatedActionUnit: '',
      relatedActionCategoryUnit: '',
      fertilisationDosage: '',
      fertilisationDosageUnit: '',
      fertilisationDosageAppliedBy: '',
      totalNSum: '',
      totalPSum: '',
      totalKSum: '',
      solutionAmount: '',
      solutionUnit: '',
      fertilisationDosageAppliedBy100: '',
      totalAmount: ''
    };

    this.fertilizers.push(newFertilizer);

    const control = new FormControl('');
    this.fertilizerControls.push(control);

    this.filteredCommercialNames.push(
      control.valueChanges.pipe(
        startWith(''),
        map(value => this.filterCommercialNames(value))
      )
    );
  }

  removeFertilizer(index: number) {
    if (this.fertilizers.length > 1) {
      this.fertilizers.splice(index, 1);
      this.fertilizerControls.splice(index, 1);
      this.filteredCommercialNames.splice(index, 1);
    }
  }

  checkIfEmpty(property: any): boolean {
    return property == '' || property.length == 0;
  }

  addFertilisation() {
    this.selectedOptions.forEach((item: any) => {
      this.arraySend.push(item.crop_info_id);
    });

    const related_action_category = 'CROP.RELATED_ACTION_AND_CATEGORY_MSG';

    this.cultivationStageEmpty = this.checkIfEmpty(this.cultivationStage);
    this.applicationMethodEmpty = this.checkIfEmpty(this.applicationMethod);
    this.weatherConditionEmpty = this.checkIfEmpty(this.weatherCondition);
    this.fertilisationStartDateEmpty = this.checkIfEmpty(this.fertilisationStartDate);
    this.fertilisationEndDateEmpty = this.checkIfEmpty(this.fertilisationEndDate);
    this.fertilisationPlotsEmpty = this.checkIfEmpty(this.selectedOptions);

    let isRelatedActionEmpty = false;
    let isRelatedActionCategoryEmpty = false;

    this.fertilizers.forEach((fertilizer: any) => {
      if (fertilizer.relatedActionUnit == undefined) {
        this.relatedActionEmpty = true;
        isRelatedActionEmpty = true;
      }
      if (fertilizer.relatedActionCategoryUnit == undefined) {
        this.relatedActionCategoryEmpty = true;
        isRelatedActionCategoryEmpty = true;
      }
    });

    if (isRelatedActionEmpty || isRelatedActionCategoryEmpty) {
      this.translate.get([related_action_category]).subscribe(trans => {
        Swal.fire({
          icon: 'error',
          text: trans[related_action_category]
        });
      });
      return;
    }

    if (!isRelatedActionEmpty && !isRelatedActionCategoryEmpty) {
      type TranslatableField = 'cultivation_stage' | 'implementation_method' | 'weather_conditions' | 'dosage_applied_by' | 'amount_of_solution_applied_by' | 'related_action' | 'category_by_action';

      const currentLang = this.translate.currentLang;
      this.translate.use('gr').subscribe(() => {
        const translationObservables = this.fertilizers.map((fertilizer: any) => {
          const fieldsToTranslate: Record<TranslatableField, any> = {
            cultivation_stage: this.cultivationStage,
            implementation_method: this.applicationMethod,
            weather_conditions: this.weatherCondition,
            dosage_applied_by: fertilizer.fertilisationDosageAppliedBy,
            amount_of_solution_applied_by: fertilizer.fertilisationDosageAppliedBy100,
            related_action: fertilizer.relatedActionUnit,
            category_by_action: fertilizer.relatedActionCategoryUnit
          };

          const translations: Partial<Record<TranslatableField, string>> = {};
          const fieldTranslationObservables = (Object.keys(fieldsToTranslate) as TranslatableField[]).map(field => {
            const fieldValue = fieldsToTranslate[field];
            if (fieldValue) {
              return this.translate.get(fieldValue).pipe(
                map((translatedValue: string) => {
                  translations[field] = translatedValue;
                })
              );
            } else {
              return of(null);
            }
          });

          return forkJoin(fieldTranslationObservables).pipe(
            map(() => ({ fertilizer, translations }))
          );
        });

        forkJoin(translationObservables).subscribe((translatedFertilizers: any[]) => {
          const postRequests = translatedFertilizers.map((item, i) => {
            const { fertilizer, translations } = item;

            const commercialName = this.fertilizerControls[i].value === 'other'
              ? fertilizer.otherFertilisationCommercialName
              : this.fertilizerControls[i].value;

            const updatedData = {
              storage_usage: this.fertToggled,
              chemical_composition: fertilizer.composition || '',
              agronomist: this.fertilisationAgronomist || '',
              batch_number: fertilizer.fertilisationBatchNumber || '',
              commercial_name: commercialName,
              cultivation_stage: translations['cultivation_stage'] || this.cultivationStage,
              starting_date: this.fertilisationStartDate || '',
              ending_date: this.fertilisationEndDate || '',
              dosage: fertilizer.fertilisationDosage || 0,
              dosage_applied_by: translations['dosage_applied_by'] || fertilizer.fertilisationDosageAppliedBy,
              amount_of_solution: fertilizer.solutionAmount || 0,
              amount_of_solution_measurement_units: fertilizer.solutionUnit || '',
              amount_of_solution_applied_by: translations['amount_of_solution_applied_by'] || fertilizer.fertilisationDosageAppliedBy100,
              dosage_measurement_units: fertilizer.fertilisationDosageUnit || '',
              implementation_method: translations['implementation_method'] || this.applicationMethod,
              operator: this.fertilisationOperator || '',
              crop_info_id: this.arraySend || '',
              weather_conditions: translations['weather_conditions'] || this.weatherCondition,
              related_action: translations['related_action'] || fertilizer.relatedActionUnit,
              category_by_action: translations['category_by_action'] || fertilizer.relatedActionCategoryUnit,
              n: fertilizer.totalN || 0,
              p: fertilizer.totalP || 0,
              k: fertilizer.totalK || 0,
            };

            let dataObj = JSON.stringify(updatedData);
            return this.req.postCropFertilisation(dataObj);
          });

          forkJoin(postRequests).subscribe({
            next: () => {
              this.translate.use(currentLang).subscribe(() => {
                Swal.fire('', 'Fertilisation information updated successfully!', 'success');
                setTimeout(() => {
                  this._location.back();
                }, 1000);
              });
            },
            error: (err) => {
              console.error('Error during POST:', err);
            }
          });
        });

      });
    }
  }

  addIrrigation() {
    this.selectedOptions.forEach((item: any) => {
      this.arraySend.push(item.crop_info_id);
    });

    type TranslatableField = 'water_source' | 'irrigation_frequency' | 'irrigation_method';
    const currentLang = this.translate.currentLang;
    this.translate.use('gr').subscribe(() => {
      const fieldsToTranslate: Record<TranslatableField, string> = {
        water_source: this.sourceItem,
        irrigation_frequency: this.frequency,
        irrigation_method: this.irrigationMethod,
      };

      const translations: Partial<Record<TranslatableField, string>> = {};

      const translationObservables = (Object.keys(fieldsToTranslate) as TranslatableField[]).map(field =>
        this.translate.get(fieldsToTranslate[field]).pipe(
          map((translatedValue: string) => {
            translations[field] = translatedValue;
          })
        )
      );

      forkJoin(translationObservables).subscribe(() => {
        const updatedData = {
          datetime: this.irrigationStartTime ? this.irrigationStartTime : '',
          end_datetime: this.irrigationEndTime ? this.irrigationEndTime : '',
          water_source: translations['water_source'] ? translations['water_source'] : this.sourceItem,
          hours: this.irrHours ? this.irrHours : '',
          irrigation_frequency: translations['irrigation_frequency'] ? translations['irrigation_frequency'] : this.frequency,
          irrigation_method: translations['irrigation_method'] ? translations['irrigation_method'] : this.irrigationMethod,
          water_m3_per_hour_per_acre: this.waterSourceQuantity ? this.waterSourceQuantity : '',
          crop_info_id: this.arraySend ? this.arraySend : '',
        };

        let dataObj = JSON.stringify(updatedData);
        this.translate.use(currentLang).subscribe(() => {
          this.req.postCropIrrigation(dataObj).subscribe(() => {
            Swal.fire(
              '',
              'Irrigation information updated successfully!',
              'success'
            );
            setTimeout(() => {
              this._location.back();
            }, 1000);
          });
        });
      });
    })
  }


  addSpraying() {
    this.selectedOptions.forEach((item: any) => {
      this.arraySend.push(item.crop_info_id)
    });
    const related_action = 'CROP.RELATED_ACTION_MSG'
    const sprayingSubmit = 'CROP.SUCCES_SPRAYING_SUBMIT'
    if (this.relatedActionSprayUnit == undefined) {
      this.relatedActionSprayEmpty = true
      this.translate.get([related_action]).subscribe(trans => {
        Swal.fire({
          icon: 'error',
          text: trans[related_action]
        }
        )
      })
    } else {
      this.relatedActionSprayEmpty = false

      type TranslatableField = 'cultivation_stage' | 'implementation_method' | 'weather_conditions' | 'dosage_applied_by' | 'amount_of_solution_applied_by' | 'related_action';
      const currentLang = this.translate.currentLang;

      this.translate.use('gr').subscribe(() => {

        const fieldsToTranslate: Record<TranslatableField, any> = {
          cultivation_stage: this.cultivationStage || '',
          implementation_method: this.implementationMethod || '',
          weather_conditions: this.weatherCondition || '',
          dosage_applied_by: this.dosageAppliedBy || '',
          amount_of_solution_applied_by: this.amountAppliedByHundred || '',
          related_action: this.relatedActionSprayUnit || '',
        };

        const translations: Partial<Record<TranslatableField, string>> = {};

        const translationObservables = (Object.keys(fieldsToTranslate) as TranslatableField[]).map(field => {
          const key = fieldsToTranslate[field];
          if (key && key.trim() !== '') {
            return this.translate.get(key).pipe(
              map((translatedValue: string) => {
                translations[field] = translatedValue;
              })
            );
          } else {
            translations[field] = '';
            return of(null);
          }
        });
        forkJoin(translationObservables).subscribe(() => {

          const updatedData = {
            storage_usage: this.sprayToggled,
            active_substance: this.substance ? this.substance : '',
            agronomist: this.sprayingAgronomist ? this.sprayingAgronomist : '',
            amount_of_solution: this.solutionAmount ? this.solutionAmount : 0,
            amount_of_solution_measurement_units: this.solutionUnit ? this.solutionUnit : '',
            amount_of_solution_applied_by: translations['amount_of_solution_applied_by'] ? translations['amount_of_solution_applied_by'] : '',
            batch_number: this.sprayingBatchNumber ? this.sprayingBatchNumber : '',
            commercial_name: this.commercialName === 'other' ? this.otherCommercialName : this.commercialName,
            cultivation_stage: translations['cultivation_stage'] ? translations['cultivation_stage'] : '',
            datetime: this.sprayingStartDate ? this.sprayingStartDate : '',
            end_datetime: this.sprayingEndDate ? this.sprayingEndDate : '',
            dosage: this.dosage ? this.dosage : 0,
            dosage_applied_by: translations['dosage_applied_by'] ? translations['dosage_applied_by'] : '',
            dosage_measurement_units: this.dosageUnit ? this.dosageUnit : '',
            hours: this.sprayingHours ? this.sprayingHours : '',
            implementation_method: translations['implementation_method'] ? translations['implementation_method'] : '',
            justification: this.justification ? this.justification : '',
            operator: this.sprayingOperator ? this.sprayingOperator : '',
            phi: this.phi ? this.phi : 0,
            crop_info_id: this.arraySend ? this.arraySend : '',
            weather_conditions: translations['weather_conditions'] ? translations['weather_conditions'] : '',
            related_action: translations['related_action'] ? translations['related_action'] : '',
            amount_of_item: this.itemAmount ? this.itemAmount : 0,
          };
          let dataObj = JSON.stringify(updatedData)
          this.translate.use(currentLang).subscribe(() => {
            this.req.postCropSpraying(dataObj).subscribe(() => {
              this.translate.get([sprayingSubmit]).subscribe(trans => {
                Swal.fire({
                  icon: 'success',
                  text: trans[sprayingSubmit]
                })
              })
              setTimeout(() => {
                this._location.back();
              }, 1000);
            })
          })
        })
      })
    }
  }

  addHarvest() {
    const currentLang = this.translate.currentLang;
    this.translate.use('gr').subscribe(() => {
      this.translate.get(this.fertilisationDosageUnit).subscribe((sum_unit_measurement: string) => {
        const updatedData = {
          cleaning_of_harvesting_equipment: this.cleaning ? this.cleaning : '',
          client: this.client ? this.client : '',
          datetime: this.harvestDate ? this.harvestDate : '',
          lot_number: this.lotNumber ? this.lotNumber : '',
          crop_info_id: this.crop_id ? this.crop_id : '',
          document_number: this.documentNumber ? this.documentNumber : '',
          summ_unit_measurement: sum_unit_measurement ? sum_unit_measurement : '',
          harvest_per_acre: this.harvestPlot ? this.harvestPlot : ''
        };
        let dataObj = JSON.stringify(updatedData)
        this.translate.use(currentLang).subscribe(() => {
          if (this.warnHarvest === true) {
            Swal.fire(
              '',
              'Wrong Phi!',
              'error'
            )
          } else if (this.warnHarvest === false) {
            this.req.postCropHarvest(dataObj).subscribe(() => {
              Swal.fire(
                '',
                'Harvest information updated successfully!',
                'success'
              )
              setTimeout(() => {
                this._location.back();
              }, 1000);
            })
          }
        })
      })
    })
  }

  onResidues(selectedMethod: any) {
    this.residueChoose = this.residueMethods.indexOf(selectedMethod);
  }

  addResidues() {
    const currentLang = this.translate.currentLang;
    this.translate.get(this.residueMethod).subscribe((management_method: string) => {

      const updatedData = {
        datetime: this.residueDate ? this.residueDate : '',
        management_method: management_method ? management_method : '',
        crop_info_id: this.crop_id,
        total_amount: this.residueAmount ? this.residueAmount : '',
        harvest_id: this.harvestId ? this.harvestId : '',
        related_treatment: this.resID ? this.resID : ''
      };
      let dataObj = JSON.stringify(updatedData)
      this.translate.use(currentLang).subscribe(() => {
        this.req.postCropResidues(dataObj).subscribe(() => {
          Swal.fire(
            '',
            'Residues information updated successfully!',
            'success'
          )
          setTimeout(() => {
            this._location.back();
          }, 1000);
        })
      })
    })
  }

  onItemToggleChange() {
    if (this.itemToggled) {
      this.translate.get('IMPLEMENTATION_METHOD.OTHER').subscribe((translatedValue: string) => {
        this.translatedOtherValue = translatedValue;
        this.implementationMethod = translatedValue;
      });
      this.sprayToggled = false;
    } else {
      this.implementationMethod = '';
      this.translatedOtherValue = '';
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(RecommendedComponent);
    dialogRef.afterClosed().subscribe();
  }

}