import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidebarService } from 'src/app/services/sidebar.service';
import { KeycloakInitOptions } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';
import { createPopper } from "@popperjs/core";
import { RequestService } from 'src/app/services/request.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { Router } from '@angular/router';
import { FirstLoginDialogComponent } from 'src/app/views/home/first-login-dialog/first-login-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ShareDataService } from 'src/app/services/share-data.service';
import { forkJoin } from 'rxjs';

const url = environment.apiType + '://' + environment.apiUrl;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {
  dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef!: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false }) popoverDropdownRef!: ElementRef;
  @ViewChild(MatSidenav) sidebar!: MatSidenav;

  showLogin: boolean = true;
  firstName: string = '';
  lastName: string = '';
  default: KeycloakInitOptions = {
    redirectUri: url
    // redirectUri: 'http://localhost:4200'
  }
  isVerified: boolean = true;
  superuser: boolean = false;
  admin: boolean = false;
  subadmin: boolean = false;
  opekepeData: any[] = [];
  opekepeUrl: string = '';
  urlCode: string | undefined;

  constructor(
    private sidebarService: SidebarService,
    private keycloak: KeycloakService,
    private renderer: Renderer2,
    private req: RequestService<any>,
    public translate: TranslateService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private dialog: MatDialog,
    private shareDataService: ShareDataService
  ) {
    this.renderer.listen('document', 'click', (event: MouseEvent) => {
      const isClickedInside = this.popoverDropdownRef?.nativeElement.contains(event.target);
      const isButtonClicked = this.btnDropdownRef?.nativeElement.contains(event.target);
      if (!isClickedInside && !isButtonClicked) {
        this.dropdownPopoverShow = false;
      }
    });
  }

  ngOnInit() {
    this.keycloak.isLoggedIn().then((isLoggedIn) => {
      this.showLogin = !isLoggedIn;
      if (isLoggedIn) {
        this.initializeData();
      }
    });
  }

  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      { placement: "bottom-end" }
    );
  }

  initializeData() {
    forkJoin({
      profile: this.req.getUserProfile(),
      opekepe: this.req.getOpekepe(),
      farmer: this.req.getFarmer()
    }).subscribe({
      next: ({ profile, opekepe, farmer }) => {
        this.handleUserProfile(profile);
        this.handleOpekepeData(opekepe, farmer);
        this.handleFarmerData(farmer);
      },
      error: (err) => {
        this.req.getFarmer().subscribe((res) => {
          this.handleFarmerData(res)
        })
      }
    }) ;
  }

  handleUserProfile(profile: any) {
    this.isVerified = profile.email_verified;
    this.superuser = profile.roles.includes('superuser');
    this.admin = profile.roles.includes('admin');
    this.subadmin = profile.roles.includes('subadmin');

    this.authorizationService.setSuperuser(this.superuser);
    this.authorizationService.setAdmin(this.admin);
    this.authorizationService.setSubadmin(this.subadmin);
  }

  handleOpekepeData(opekepe: any, farmer: any) {
    this.opekepeUrl = opekepe.auth_url;
    this.opekepeData = farmer.opekepe_data;

    if (this.opekepeData.length === 0) {
      this.openFirstLoginDialog(this.opekepeUrl);
    }

    const url = this.router.url;
    const splitUrl = url.split('/');
    const codeIndex = url.indexOf('code=');
    if (splitUrl[1] === 'opekepe' && splitUrl[2]?.startsWith('login') && codeIndex !== -1) {
      this.urlCode = url.slice(codeIndex + 5);
      if (this.urlCode) {
        this.postOpekepe();
      }
    }
  }

  handleFarmerData(farmer: any) {
    this.firstName = farmer.first_name[0]?.toUpperCase() || '';
    this.lastName = farmer.last_name[0]?.toUpperCase() || '';
    this.shareDataService.setData('farmer', farmer);
  }

  postOpekepe() {
    const translations = this.translate.instant([
      'OPEKEPE.IMPORT_DATA_TITLE',
      'OPEKEPE.SUBMIT',
      'OPEKEPE.CANCEL',
      'OPEKEPE.IMPORT_SUCCESS',
      'OPEKEPE.IMPORT_DENY',
      'OPEKEPE.200',
      'OPEKEPE.204',
      'OPEKEPE.400',
      'OPEKEPE.RIGHT_DESC'
    ]);

    this.req.postOpekepe(this.urlCode!).subscribe({
      next: (response) => {
        if (response.status === 204) {
          Swal.fire({
            title: translations['OPEKEPE.204'],
            text: translations['OPEKEPE.RIGHT_DESC'],
            icon: "warning"
          });
        } else {
          Swal.fire({
            title: translations['OPEKEPE.IMPORT_DATA_TITLE'],
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: translations['OPEKEPE.CANCEL'],
            confirmButtonText: translations['OPEKEPE.SUBMIT']
          }).then((result) => {
            if (result.isConfirmed) {
              this.req.postOpekepeImport().subscribe(() => {
                Swal.fire(translations['OPEKEPE.200'], '', 'success');
                window.location.href = url;
              });
            }
          });
        }
      },
      error: (err) => {
        console.error("Error posting Opekepe data: ", err);
      }
    });
  }

  openFirstLoginDialog(authUrl: string) {
    this.dialog.open(FirstLoginDialogComponent, {
      minWidth: '1000px',
      data: authUrl
    });
  }

  toggleSidebar() {
    this.sidebarService.toggle();
  }

  logout() {
    this.keycloak.logout(this.default.redirectUri);
  }

  toggleDropdown(event: any) {
    event.preventDefault();
    this.dropdownPopoverShow = !this.dropdownPopoverShow;
  }

  changeLanguage(language: string) {
    this.translate.use(language);
  }
}