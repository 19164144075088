<mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center" class="p-2">
    <mat-tab>
        <ng-template mat-tab-label>
            <div class="col-4 d-flex align-items-left justify-content-left pb-2">
                <img class="p-0 img-fluid mx-auto rounded-rectangle img-thumbnail" src="../../../../assets/gov.png"
                    alt=""
                    style="box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;"
                    width="40">
            </div>
            <div class="col-8 d-flex align-items-right justify-content-right">
                {{'OPEKEPE.OPEKEPE' | translate}}
            </div>
        </ng-template>
        <div class="container mx-auto px-4 flex flex-col justify-center items-center">
            <button mat-button class="hover:underline my-24" (click)="onClick()" id="taxisButton">
                {{'OPEKEPE.LOGIN' | translate}}
            </button>
            <div style="margin-top: -7%;font-size: medium; color: rgb(158, 158, 158);" class="pb-4">
                *{{'OPEKEPE.LOGIN_INFO' | translate}}
            </div>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <div class="col-4 d-flex align-items-left justify-content-left pb-2">
                <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail" src="../../../../assets/xml.png" alt=""
                    style="box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;"
                    width="40">
            </div>
            {{'OPEKEPE.XML' | translate}}
        </ng-template>
        <div class="my-12" id="drop-area">
            <div class="container mx-auto">
                <div *ngIf="!isDropped" class="col-6">
                    <div class="row">
                        <div class="flex flex-col justify-center items-center">
                            <i class="fa fa-cloud-upload text-center" id="cloud_icon" aria-hidden="true"></i>
                        </div>
                        <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" [multiple]="true">
                            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                <div class="row mt-2">
                                    <div class="col text-center mt-5">
                                        <div id="drag-text">
                                            <span>
                                                {{'UPLOAD.DRAG_DROP' | translate}}
                                            </span>
                                        </div>
                                    </div>
                                    <div id="browse_button" class="p-2">
                                        <button mat-raised-button (click)="openFileSelector()"
                                            style="color: #67b883; font-size: small;">
                                            {{'UPLOAD.BROWSE_BUTTON' | translate}} XML
                                        </button>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <button (click)="submitUpload()" class="btn-grad">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-file-drop>
                    </div>
                </div>
                <div *ngIf="isDropped" class="col-6" style="margin-top: 7%; margin-left: 42%;">
                    <div class="upload-table">
                        <div *ngFor="let item of files; let i = index">
                            <div *ngIf="item.fileEntry.name.includes('.xml') else wrongFile">
                                <div>
                                    <strong style="font-size: large; color: #7c7c7c">
                                        <div style="font-size: large; color: #7c7c7c">
                                            {{ item.fileEntry.name }}
                                            <i (click)="deleteDroped($event)" id="uploaded_delete"
                                                class="fa-solid fa-trash-can"></i>
                                        </div>
                                    </strong>
                                </div>
                            </div>
                            <ng-template #wrongFile> </ng-template>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button (click)="submitUpload()" class="btn-grad2">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <div class="col-4 d-flex align-items-left justify-content-left pb-2 mr-2">
                <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                    src="../../../../assets/instructions.png" alt=""
                    style="box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;"
                    width="40">
            </div>
            {{'OPEKEPE.INSTRUCTIONS' | translate}}
        </ng-template>
        <mat-tab-group class="mt-2">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="col-4 d-flex align-items-left justify-content-left pb-2 mr-2">
                        <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                            src="../../../../assets/instructions.png" alt=""
                            style="box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;"
                            width="30">
                    </div>
                    Για εισαγωγή μέσω TAXISNet
                </ng-template>
                <div class="container p-4 text-muted text-sm" id="scroll">
                    <strong>A.</strong> Στην αρχική οθόνη, επιλέξτε το κουμπί "Εισαγωγή Στοιχείων Taxisnet".
                    <img src="../../../../assets/instructions/image1.png" alt="" class="p-2" width="400">
                    <strong>Β.</strong> Θα μεταφερθείτε στην σελίδα του ECO-SCHEMES , εκεί θα επιλέξετε Gsis Taxis.
                    <img src="../../../../assets/instructions/image2.png" alt="" class="p-2" width="400">
                    Εκεί, θα χρειαστεί να εισαγάγετε τα προσωπικά σας στοιχεία σύνδεσης (Όνομα Χρήστη και Κωδικός
                    Πρόσβασης) για
                    το
                    Taxisnet.
                    <img src="../../../../assets/instructions/image3.png" alt="" class="p-2" width="400">
                    Μετά την εισαγωγή των στοιχείων σας, πατήστε το κουμπί "ΣΥΝΕΧΕΙΑ" για να προχωρήσετε.
                    <img src="../../../../assets/instructions/image4.png" alt="" class="p-2" width="400">
                    <strong>Γ.</strong> Μετά την επιτυχή σύνδεση, θα σας ζητηθεί να επιβεβαιώσετε την εισαγωγή των
                    δεδομένων από τον
                    ΟΠΕΚΕΠΕ στην
                    πλατφόρμα μας. Πατήστε το κουμπί "Καταχώρηση" ώστε να καταχωρηθούν τα δεδομένα σας.
                    <img src="../../../../assets/instructions/image5.png" alt="" class="p-2" width="400">
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="col-4 d-flex align-items-left justify-content-left pb-2 mr-2">
                        <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                            src="../../../../assets/instructions.png" alt=""
                            style="box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;"
                            width="30">
                    </div>
                    Για εισαγωγή με XML
                </ng-template>
                <div class="container p-4 text-muted text-sm" id="scroll">
                    <strong>A.</strong> Μπορείτε είτε να σείρετε το αρχείο XML που διαθέτετε , είτε να πατήσετε στο
                    κουμπί "Eισαγωγή
                    Aρχείου XML" και στη συνέχεια να επιλέξετε το δικό σας αρχείο XML.
                    <img src="../../../../assets/instructions/xml1.png" alt="" class="p-2" width="400">
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-tab>
</mat-tab-group>
<div mat-dialog-actions class="float-left">
    <div class="col d-flex justify-content-right align-items-right" style="background-color: #efefef;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;">
        <button *ngIf="translate.currentLang === 'en'" (click)="changeLanguage('gr')"
            class="text-md py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:text-gray-500">
            <div class="flex items-center">
                GR
                <img src="assets/flag-gr.webp" class="w-6 ml-2">
            </div>
        </button>
        <button *ngIf="translate.currentLang === 'gr'" (click)="changeLanguage('en')"
            class="text-md py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:text-gray-500">
            <div class="flex items-center">
                EN
                <img src="../../../assets/flag-en.webp" class="w-6 ml-2">
            </div>
        </button>
    </div>
</div>

<div mat-dialog-actions class="float-right">
    <div style="    background-color: #efefef;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;">
        <button mat-button (click)="onClose()">{{'OPEKEPE.CANCEL' | translate}}</button>
    </div>
</div>