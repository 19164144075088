import { Component, OnInit, ViewEncapsulation, Input, SimpleChanges } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { KeycloakService } from 'keycloak-angular';
import { GridOptions } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as XLSX from 'xlsx'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { font2 } from '../../fonts/encryption';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-treatmentshistory',
  templateUrl: './treatmentshistory.component.html',
  styleUrls: ['./treatmentshistory.component.scss']
})
export class TreatmentshistoryComponent implements OnInit {
  farmerId: any = '';
  plotCodes: any[] = [];
  treatmentData: any = [];
  treatmentDataMap: any = [];
  codes: any = [];
  code: any = '';
  gridOptions: GridOptions = {
    columnDefs: [],
    rowData: [],
    rowSelection: 'multiple',
    animateRows: true,
    pagination: true,
    paginationPageSize: 10
  };
  defaultColDef: any = {
    flex: 1,
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: {
      buttons: ['reset', 'apply'],
      filterOptions: ['contains', 'notContains', 'equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
    }
  }
  columnDefs: any[] = [
    {
      headerName: 'Date',
      field: 'start_date',
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset', 'apply'],
      },
    },
  ];
  treatmentColumnDefs: any[] = [
  ];
  selected: any
  @Input() plotTypeTreatments: any;
  @Input() plotChoose: any;
  @Input() eventChild: any;
  @Input() eventParent: any;
  @Input() parentCodeBoolean: any;
  @Input() childCodeBoolean: any;
  @Input() parentCode: any;
  @Input() childCode: any;
  @Input() parentBox: any;
  @Input() childBox: any;
  @Input() setAllStatus: any;
  @Input() startingDateString: any;
  @Input() endingDateString: any;
  @Input() tabStatus: any;
  @Input() afmFarmer: any
  @Input() firstName: any
  @Input() lastName: any

  public langStatus: any;
  headColumns: any[] = [];
  headColumnsSum: any[] = [];
  SumTable: any;
  TreatmentSum: any[] = [];
  elArray: any[] = [];
  splitArray: any;
  dateRange: any;
  cropType: any;

  constructor(
    private req: RequestService<any>,
    private keycloak: KeycloakService,
    private datePipe: DatePipe,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.langStatus = this.translate.store.currentLang
    this.columnsTable();
    this.getAllData()
    this.getAllTreatmentData();
  }

  getAllData() {
    this.req.getPlotFarmerHistory('?crop_usage=True').subscribe((res) => {
      this.plotCodes = res
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['eventParent'] || changes['eventChild'] || changes['plotChoose']
      || (this.parentCodeBoolean || this.childCodeBoolean && (changes['tabStatus'].previousValue == 3 || changes['tabStatus'].previousValue == 1))) {
      setTimeout(() => {
        if (this.plotChoose.length == 0) {
          this.noData()
        } else if (this.plotChoose.length > 0) {
          this.getTreatmentData()
        }
        else if (this.setAllStatus) {
          console.log('mpainei')
          this.getAllTreatmentData()
        }
      }, 50)
    }
    else if (changes['tabStatus'].currentValue == 2 || !this.parentCodeBoolean && !this.childCodeBoolean) {
      this.getTreatmentDataFirst()
    }

  }

  columnsTable() {
    this.langStatus = this.translate.store.currentLang;
    if (this.langStatus === 'en') {
      this.treatmentColumnDefs = [
        { headerName: 'Plot', field: 'plot_code', resizable: true },
        { headerName: 'Start Date', field: 'start_date', resizable: true },
        { headerName: 'End Date', field: 'end_date', resizable: true },
        { headerName: 'Treatment', field: 'treatment', resizable: true },
        { headerName: 'Action', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ]
    }

    else {
      this.treatmentColumnDefs = [
        { headerName: 'Αγροτεμάχιο', field: 'plot_code', resizable: true },
        { headerName: 'Ημ/νια Έναρξης', field: 'start_date', resizable: true },
        { headerName: 'Ημ/νια Λήξης', field: 'end_date', resizable: true },
        { headerName: 'Καλλιεργητική Εργασία', field: 'treatment', resizable: true },
        { headerName: 'Δράση', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ]
    }
  }

  getTreatmentDataFirst() {
    this.dateRange = '';
    this.elArray = [];
    this.req.getPlots().subscribe((res) => {
      res.forEach((element: any) => {
        element.crop_info.forEach((el: any) => {
          this.elArray.push('crop_info_id=' + el.crop_info_id + '&')
          this.splitArray = (('?' + this.elArray).replace(/,/g, '').slice(0, -1));
        })
        this.dateRange = this.splitArray + '&start_date=' + this.startingDateString + '&end_date=' + this.endingDateString
      });
      this.req.getPlotTreatmentHistory(this.dateRange).subscribe((res) => {
        this.treatmentData = res.map((element: any) => {
          this.plotCodes.forEach((el: any) => {
            if (el.crop_info_id == element.crop_info_id) {
              this.cropType = el.crop_type
            }
          });
          return {
            id: element._id,
            plot_code: `${element.plot_code} - ${this.cropType}`,
            start_date: this.datePipe.transform(element.start_date, 'dd-MMMM-yyyy'),
            end_date: this.datePipe.transform(element.end_date, 'dd-MMMM-yyyy'),
            treatment: element.treatment,
          }
        });
      });
    })
  }

  deleteButtonRenderer = (params: any) => {
    let eGui = document.createElement('div');
    eGui.innerHTML = `<button class="action-button text-md text-red-500 hover:text-red-600" data-action="delete">
    <i class="far fa-trash-alt"></i>
    </button>`;

    eGui.querySelectorAll('.action-button').forEach(button => {
      button.addEventListener('click', () => {
        const action = button.getAttribute('data-action');
        const paramsId = params.data.id
        if (action === 'delete') {
          this.deleteRecord(paramsId);
        }
      });
    });
    return eGui;
  }

  deleteRecord(paramsId: any) {
    const title = 'HISTORY.DELETE_TITLE';
    const yesDelete = 'PRODUCT.YES_DELETE';
    const deletedTitle = 'PRODUCT.DELETED_TITLE';
    const deletedText = 'HISTORY.DELETED_TEXT';
    const cancel = 'PRODUCT.CANCEL';

    this.translate.get([title, yesDelete, yesDelete, cancel, deletedTitle, deletedText])
      .subscribe(translations => {
        Swal.fire({
          title: translations[title],
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: 'rgb(147, 163, 160)',
          confirmButtonText: translations[yesDelete],
          cancelButtonText: translations[cancel],
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: translations[deletedTitle],
              text: translations[deletedText],
              icon: 'success',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000
            });
            this.req.deleteHistoricalTreatment(paramsId).subscribe(res => {
              this.getTreatmentData();
            })
          }
        });
      });
  }

  noData() {
    this.treatmentData = []
    this.elArray = [];
    this.splitArray = ''
  }

  textList() {
    this.dateRange = '';
    if (this.plotChoose.length == 0) {
      this.noData()
    } else if (this.plotChoose.length > 0) {
      this.plotChoose.forEach((el: any) => {
        this.elArray.push('crop_info_id=' + el.crop_info_id + '&')
        this.splitArray = (('?' + this.elArray).replace(/,/g, '').slice(0, -1));
      });
      this.dateRange = this.splitArray + '&start_date=' + this.startingDateString + '&end_date=' + this.endingDateString
    }
  }

  getTreatmentData() {
    this.treatmentData = []
    this.elArray = [];
    this.dateRange = '';
    this.textList()
    this.req.getPlotTreatmentHistory(this.dateRange).subscribe((res) => {
      this.treatmentData = res.map((element: any) => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          id: element._id,
          plot_code: `${element.plot_code} - ${this.cropType}`,
          start_date: this.datePipe.transform(element.start_date, 'dd-MMMM-yyyy'),
          end_date: this.datePipe.transform(element.end_date, 'dd-MMMM-yyyy'),
          treatment: element.treatment,
        }
      });
    });
  }

  getAllTreatmentData() {
    this.req.getTreatment().subscribe((res) => {
      this.treatmentData = res.map(element => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          plot_code: `${element.plot_code} - ${this.cropType}`,
          start_date: this.datePipe.transform(element.start_date, 'dd-MMMM-yyyy'),
          end_date: this.datePipe.transform(element.end_date, 'dd-MMMM-yyyy'),
          treatment: element.treatment,
        }
      });
    });
  }

  treatmentMapped() {
    this.treatmentDataMap = this.treatmentData.map((el: any) => [
      el.plot_code,
      el.start_date,
      el.end_date,
      el.treatment,
    ]);
  }

  exportCsvTreatment() {
    this.treatmentMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    if (this.langStatus.includes('en')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers: ['Plot', 'Start Date', 'End Date', 'Treatment'],
      };
      new ngxCsv(this.treatmentDataMap, 'Crop_Treatment' + dateTime, options);
    } else if (this.langStatus.includes('gr')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers: ['Αργοτεμάχιο', 'Ημερομηνία Έναρξης', 'Ημερομηνία Λήξης', 'Εργασία'],
      };
      new ngxCsv(this.treatmentDataMap, 'Καλλιεργητικές_Εργασίες_' + dateTime, options);
    }
  }

  exportExcelTreatment() {
    this.treatmentMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    const workbook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = {};
    if (this.langStatus.includes('en')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Name', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Last Name', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Vat Number', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Plot', 'Start_Date', 'End_Date', 'Treatment']
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.treatmentDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Crop_Treatment_' + dateTime + '.xlsx')
    } else if (this.langStatus.includes('gr')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Όνομα', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Επίθετο', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['ΑΦΜ', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Αργοτεμάχιο', 'Ημερομηνία_Έναρξης', 'Ημερομηνία_Λήξης', 'Εργασία']
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.treatmentDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Καλλιεργητικές_Εργασίες_' + dateTime + '.xlsx')
    }
  }
  exportPdfTreatment() {
    this.treatmentMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    if (this.langStatus.includes('en')) {
      this.headColumns = ['Plot', 'Start Date', 'End Date', 'Treatment']
    } else if (this.langStatus.includes('gr')) {
      this.headColumns = ['Αργοτεμάχιο', 'Ημερομηνία_Έναρξης', 'Ημερομηνία_Λήξης', 'Εργασία']
    }
    const doc = new jsPDF();
    doc.addFileToVFS('LiberationSerif-Regular-normal.ttf', font2);
    doc.addFont('LiberationSerif-Regular-normal.ttf', 'LiberationSerif-Regular', 'normal');
    doc.setFont('LiberationSerif-Regular', 'normal');
    doc.setFontSize(12)
    doc.setTextColor('#5e797e')
    if (this.langStatus.includes('en')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('Vat Number: ' + this.afmFarmer, 10, 20)
      doc.setTextColor('#5e797e')
      doc.text('Treatment', 175, 10)
    } else if (this.langStatus.includes('gr')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('ΑΦΜ: ' + this.afmFarmer, 10, 20)
      doc.setTextColor('#5e797e')
      doc.text('Καλλιεργητικές Εργασίες', 150, 10)
    }
    doc.addImage("./assets/logo.png", 'PNG', 90, 10, 30, 20);
    autoTable(doc, {
      theme: 'grid',
      head: [this.headColumns],
      styles: { font: "LiberationSerif-Regular", fontSize: 9 },
      body: this.treatmentDataMap,
      startY: 45,
    });
    doc.addImage("./assets/logo-wings.png", 'PNG', 178, 280, 20, 10);
    if (this.langStatus.includes('en')) {
      doc.save("Treatment " + dateTime + ".pdf");
    } else if (this.langStatus.includes('gr')) {
      doc.save("Καλλιεργητικές Εργασίες " + dateTime + ".pdf");
    }
  }

}
