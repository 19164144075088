import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { Router } from '@angular/router';
import { FeatureFlagsService } from 'src/app/services/feature-flags.service';
import { TranslateService } from '@ngx-translate/core';
import { ShareDataService } from 'src/app/services/share-data.service';

declare var google: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  farmerId: any = '';
  plotCodes: any = [];
  public map: any

  plot: any = [];
  displayedColumns: string[] = ['plot_code', 'municipal_community', 'location', 'regional_unit', 'co_ownership', 'size'];
  treatment: any[] = [];
  irrigation: any[] = [];
  fertilisation: any[] = [];
  seeding: any[] = [];
  planting: any[] = [];
  spraying: any[] = [];
  plotCode: any;
  drawCoords: any[] = []
  public farmerInfo: any[] = [];
  public plotLocations: any[] = [];
  public plotLocationsMap: any[] = [];
  mobile = false;
  laptop = false;
  desktop = false;
  lat: any;
  lon: any;
  zoom: any;
  comunityFarmer: any
  municipal: string | undefined;
  size: string | undefined;
  location: string | undefined;
  species: string | undefined;
  farmerData: any;

  constructor(private router: Router, private req: RequestService<any>, public featureFlags: FeatureFlagsService, public translate: TranslateService, private shareDataService: ShareDataService  ) { }

  ngOnInit(): void {
    this.getPlotData()
    if (window.screen.width < 600) {
      this.mobile = true;
      this.laptop = false;
      this.desktop = false;
    }
    if (window.screen.width > 600 && window.screen.width < 1200) {
      this.mobile = false;
      this.laptop = true
      this.desktop = false;
    }
    if (window.screen.width > 1200 && window.screen.width < 1500) {
      this.laptop = true;
      this.mobile = false;
      this.desktop = false;
    }
    if (window.screen.width > 1500) {
      this.laptop = false;
      this.mobile = false;
      this.desktop = true;
    }
  }

  async getPlotData() {
    this.shareDataService.getData<any>('farmer').subscribe((res: any) => {
      if(res){
        this.farmerInfo.push(res)
        var regionalFarmer = res.regional_unit.split(' ')
        this.comunityFarmer = regionalFarmer.slice(2).join(' ')
      }
    });
    this.req.getPlots().subscribe((res) => {
      res.forEach(element => {
        this.plotLocations.push(element)
        this.plotCodes.push(element.map_coordinates);
        this.plotLocationsMap = this.plotLocations.map((el: any) => {
          let municipalName = 'HOME.MUNICIPAL';
          let locationName = 'MAP.LOCATION';
          let sizeName = 'HOME.SIZE_PLOT';
          let speciesName = 'MAP.SPECIES';
          this.translate.get([municipalName, locationName, sizeName, speciesName])
            .subscribe(translations => {
              this.municipal = translations[municipalName];
              this.location = translations[locationName];
              this.size = translations[sizeName];
              this.species = translations[speciesName];
            });

          if (el.map_coordinates[0]) {
            return [{
              lat: el.map_coordinates[0].lat,
              lng: el.map_coordinates[0].lon,
            },
            el.crop_info.length,
            el.location,
            el.plot_code,
            el.municipal_community,
            el.size.toFixed(2),
            this.municipal,
            this.location,
            this.size,
            this.species,

            ]
          } else {
            return [{
              lat: 37.98773910747681,
              lng: 23.722049982626345,
            },
            el.crop_info.length,
            el.location,
            el.plot_code,
            el.municipal_community,
            el.size.toFixed(2),
            this.municipal,
            this.location,
            this.size,
            this.species,

            ]
          }
        })
      })
      this.initMap()
    });
  }

  initMap(): void {
    if (this.plotCodes[0]) {
      this.lat = this.plotCodes[0][0].lat
      this.lon = this.plotCodes[0][0].lon
      this.zoom = 13.3
    } else {
      this.lat = 37.98773910747681
      this.lon = 23.722049982626345
      this.zoom = 7
    }
    const mapOptions = {
      zoom: this.zoom,
      center: { lat: this.lat, lng: this.lon },
      mapTypeId: google.maps.MapTypeId.SATELLITE,
    };
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      mapOptions
    );
    const plotLocations: [google.maps.LatLngLiteral, number, string, any, any, any, any, any, any, any][] = this.plotLocationsMap
    const infoWindow = new google.maps.InfoWindow();
    plotLocations.forEach(([position, species, location, plot_code, municipal_community, size, municipalTitle, locationTitle, sizeTitle, speciesTitle], i) => {
      const markerContent = `
            <div class="container" style="overflow: hidden;">
              <div class="row">
                  <div class="col">
                      <div class="col-3">
                          <div class="text-center" style=" font-weight: bold;">
                            ${municipalTitle}
                          </div>
                          <div class="text-center" style="font-size: 12px;">
                            ${municipal_community.split(' ')[2]}
                          </div>
                      </div>
                      <div class="col-3 pt-1">
                          <div class="text-center" style=" font-weight: bold;">
                          ${sizeTitle}
                          </div>
                          <div class="text-center" style="font-size: 12px;">
                            ${size}
                          </div>
                      </div>
                  </div>
                  <div class="col">
                      <div class="cageInfo" style="  
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-bottom: -10px;">
                          <div class="imground img-fluid rounded-circle mb-3 img-thumbnail shadow-md"
                              style="border: 3px solid #06ca5b;">
                              <img id="imgcyrcle" src="../../../assets/land.png" width="45">
                          </div>
                      </div>
                  </div>
                  <div class="col">
                      <div class="col-3">
                          <div class="text-center" style=" font-weight: bold;">
                            ${locationTitle}
                          </div>
                          <div class="text-center" style="font-size: 12px;">
                            ${location}
                          </div>
                      </div>
                      <div class="col-3 pt-1">
                          <div class="text-center" style=" font-weight: bold;">
                          ${speciesTitle}
                          </div>
                          <div class="text-center" style="font-size: 12px;">
                            ${species}
                          </div>
                      </div>
                  </div>
              </div>
            </div>
        `
      const marker = new google.maps.Marker({
        position,
        map,
        label: `${plot_code}`,
        optimized: false,
      });

      marker.addListener("click", () => {
        infoWindow.close();
        infoWindow.setContent(markerContent);
        infoWindow.open(marker.getMap(), marker);
        google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
          const closeButton = document.querySelector('.gm-ui-hover-effect') as HTMLElement;
          if (closeButton) {
            closeButton.style.margin = ' -10px -8px -18px 0px';
          }
        });

      });
    });

    this.plotCodes.forEach((element: any) => {
      this.drawCoords = element.map((el: any) => {
        return {
          lat: el.lat,
          lng: el.lon
        }
      })
      const drowPolygons = new google.maps.Polygon({
        paths: this.drawCoords,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });
      drowPolygons.setMap(map);
    });
  }

  goTo() {
    this.router.navigateByUrl(`/management/farmer`)
  }
}


