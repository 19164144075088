import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import { formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { forkJoin, map } from 'rxjs';

@Component({
  selector: 'app-multimanagement',
  templateUrl: './multimanagement.component.html',
  styleUrls: ['./multimanagement.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class MultimanagementComponent implements OnInit {

  gridOptions: GridOptions<any>;
  rowData: any[] = [];
  defaultColDef: any;
  currentStep: number = 1;
  cropIds: any = [];
  arraySend: any[] = [];
  plotName: boolean = false;
  fertilisationAgronomist: any = '';
  fertilisationBatchNumber: any = '';
  composition: any = '';
  commercialNames: any = [];
  fertilisationCommercialName: any = '';
  otherFertilisationCommercialName: any = '';
  fertilisationStartDate: any = '';
  fertilisationEndDate: any = '';
  fertilisationDosage: any = '';
  fertilisationDosageUnit: any = '';
  fertilisationDosageAppliedBy: any = '';
  fertilisationDosageAppliedBy100: any = '';
  fertilisationAmountUnit: any = '';
  fertilisationOperator: any = '';
  fertilisationPlotCode: any = '';
  applicationMethod: any = '';
  applicationMethods: any = [
    'APPLICATION_METHOD.HYDRO',
    'APPLICATION_METHOD.DISTRIBUTOR',
    'APPLICATION_METHOD.BULK_TREE',
    'APPLICATION_METHOD.BULK_LINES',
    'APPLICATION_METHOD.FOLIAR_200',
    'APPLICATION_METHOD.FOLIAR_300',
    'APPLICATION_METHOD.FOLIAR_500',
    'APPLICATION_METHOD.FOLIAR_1000',
    'APPLICATION_METHOD.FOLIAR_1500',
    'APPLICATION_METHOD.TURBINE_500',
    'APPLICATION_METHOD.TURBINE_1000',
    'APPLICATION_METHOD.TURBINE_1500',
    'APPLICATION_METHOD.TURBINE_2000',
  ];
  cultivationStage: any = '';
  cultivationStages: any = [
    'CULTIVATION_STAGE.DORMACY',
    'CULTIVATION_STAGE.BUD',
    'CULTIVATION_STAGE.LEAVES',
    'CULTIVATION_STAGE.VEGETA',
    'CULTIVATION_STAGE.FLOWER',
    'CULTIVATION_STAGE.FRUIT',
    'CULTIVATION_STAGE.HARVEST',
  ]
  weatherCondition: any = '';
  weatherConditions: any = [
    'WEATHER.SUN',
    'WEATHER.SUN_CLOUD',
    'WEATHER.CLOUDS',
    'WEATHER.RAIN',
    'WEATHER.SNOW',
    'WEATHER.HAIL'
  ]
  productId: { [key: string]: string } = {};
  selectedProductId: string = '';
  totalN: any = '';
  totalP: any = '';
  totalK: any = '';
  solutionAmount: any = '';
  solutionUnit: any = '';
  solutionUnits: any = [
    'lt'
  ]
  selectedOptions: any[] = [];
  dosage: any = '';
  dosageUnit: any = '';
  dosageUnitsAcres: any = [
    'CROP.ITEM',
    'CROP.KG'
  ];
  dosageUnits: any = [
    'gr',
    'kg',
    'ml',
    'lt',
  ]
  dosageBy: any = [
    'CROP.DOSAGE_ACRE',
    'CROP.DOSAGE_TREE',
    'CROP.DOSAGE_100'
  ];
  dosageBySpray: any = [
    'CROP.DOSAGE_ACRE',
    'CROP.DOSAGE_TREE',
  ];
  relatedActionUnit: any;
  relatedActionSprayUnit: any;
  relatedActionFert: any = ['CROP.RELATED_ACTION_NONE', 'CROP.RELATED_I', 'CROP.RELATED_IA', 'CROP.RELATED_IB']
  relatedActionSpray: any = ['CROP.RELATED_ACTION_NONE', 'CROP.RELATED_B', 'CROP.RELATED_C', 'CROP.RELATED_D', 'CROP.RELATED_IC']
  relatedActionCategoryFert: any = []
  relatedActionCategoryEmpty: boolean = false;
  relatedActionSprayEmpty: boolean = false;
  relatedActionEmpty: boolean = false;
  relatedActionCategoryUnit: any;
  implementationMethod: any = '';
  implementationMethods: any = [
    'IMPLEMENTATION_METHOD.HANDHELD',
    'IMPLEMENTATION_METHOD.TANK_GAS',
    'IMPLEMENTATION_METHOD.TRACTOR',
    'IMPLEMENTATION_METHOD.GROUND',
    'IMPLEMENTATION_METHOD.NOZZLE',
    'IMPLEMENTATION_METHOD.BP',
    'IMPLEMENTATION_METHOD.TANK_200',
    'IMPLEMENTATION_METHOD.TANK_300',
    'IMPLEMENTATION_METHOD.TANK_500',
    'IMPLEMENTATION_METHOD.TANK_1000',
    'IMPLEMENTATION_METHOD.TANK_1500',
    'IMPLEMENTATION_METHOD.TURBINE_500',
    'IMPLEMENTATION_METHOD.TURBINE_1000',
    'IMPLEMENTATION_METHOD.TURBINE_1500',
    'IMPLEMENTATION_METHOD.TURBINE_2000'
  ];
  justification: any = '';
  substance: any = '';
  recommendedDosage: any = '';
  commercialName: any = '';
  otherCommercialName: any = '';
  sprayingProductId: { [key: string]: string } = {};
  sprayingCommercialNames: any = []
  itemAmount: any;
  sprayingStartDate: any = '';
  sprayingEndDate: any = '';
  sprayingHours: any = '';
  sprayingMinutes: any = '';
  sprayingOperator: any = '';
  phi: any = '';
  dosageAppliedBy: any = '';
  acresSpray: any = 0;
  totalAmountSpray: any = 0;
  sumDateTime: any;
  sumDateTimeSpray: any;
  cropInfo: any[] = [];
  amountAppliedByHundred: any;
  sprayingAgronomist: any = '';
  sprayingBatchNumber: any = '';
  treatment: any = '';
  treatments: any = [
    'TREATMENT.SEEDBED',
    'TREATMENT.1ST',
    'TREATMENT.2ND',
    'TREATMENT.WEED',
    'TREATMENT.IMPROVEMENT',
    'TREATMENT.FERT',
    'TREATMENT.RESIDUES',
    'TREATMENT.ENTOMOLOGICAL',
    'TREATMENT.FLAT',
    'TREATMENT.PRUNING',
    'TREATMENT.CLEAN',
    'TREATMENT.PLOWING',
    'TREATMENT.MILLING',
    'TREATMENT.MOWING',
  ];
  treatmentStartDate: any = '';
  treatmentEndDate: any = '';
  startDateTime: any = '';
  endDateTime: any = '';
  warnSpray: boolean = false;
  infoFertil: boolean = false;
  infoSpraying: boolean = false;
  infoTreatment: boolean = true;
  itemToggled: boolean = false;
  arraySendLength: any;
  dataObj: any;
  summerTreatment: boolean = false;
  validStartDate: Date = new Date('2024-06-01T00:00:00');
  validEndDate: Date = new Date('2024-08-31T23:59:59');
  loading: boolean = true;

  constructor(private req: RequestService<any>, public translate: TranslateService, public _location: Location) {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      filterParams: {
        buttons: ['reset', 'apply'],
        filterOptions: [
          'contains', 'notContains', 'equals', 'notEqual',
          'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'
        ],
        maxNumConditions: 3,
        numAlwaysVisibleConditions: 3
      }
    };

    // Initialize grid options
    this.gridOptions = {
      domLayout: 'autoHeight',
      rowSelection: 'multiple',
      animateRows: true,
      pagination: true,
      paginationPageSize: 15,
      suppressRowClickSelection: true,
      columnDefs: []
    };
  }

  ngOnInit() {
    this.req.getMultimanagementData().subscribe((res: any[]) => {
      this.rowData = res
      this.rowData = res.map(element => {
        const sizeAsNumber = parseFloat(element.size);
        if (!isNaN(sizeAsNumber)) {
          return {
            ...element,
            size: sizeAsNumber.toFixed(2)
          };
        } else {
          return element;
        }
      });
      this.loading = false;
    }, () => {
      this.loading = false;
    });

    this.req.getRawProductsFertiliser().subscribe((data: any[]) => {
      this.commercialNames = data.map((item) => item.commercial_name);
      data.forEach((item) => {
        this.productId[item.commercial_name] = item._id;
      });
    });

    this.req.getRawProductsSpraying().subscribe((data: any[]) => {
      this.sprayingCommercialNames = data.map((item) => item.commercial_name);
      data.forEach((item) => {
        this.sprayingProductId[item.commercial_name] = item._id;
      });
    });
    this.summerTreatment = false;
    this.setColumnDefs();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateColumnDefs();
    });
  }

  setColumnDefs() {
    const columns: (ColDef<any, any> | ColGroupDef<any>)[] = [
      {
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 10,
        suppressSizeToFit: true
      },
      { headerName: 'Καλλιέργεια', field: 'crop_type', resizable: true },
      { headerName: 'Όνομα', field: 'first_name', resizable: true },
      { headerName: 'Επώνυμο', field: 'last_name', resizable: true },
      { headerName: 'Τοποθεσία', field: 'location', width: 140, resizable: true },
      { headerName: 'Περιφερειακή Ενότητα', field: 'municipal_community', resizable: true },
      { headerName: 'Έκταση', field: 'size', width: 120, resizable: true },
      { headerName: 'Ποικιλία', field: 'variety', resizable: true },
      { headerName: 'ΑΦΜ', field: 'vat_number', width: 120, resizable: true },
      { headerName: 'Οικολογικά Σχήματα', field: 'selected_schemes', resizable: true }
    ];

    this.gridOptions.columnDefs = columns.map(column => {
      if (this.isColDef(column) && column.field) {
        column.headerName = this.translate.instant(this.getColumnLabel(column.field));
      }
      return column;
    });
  }

  getColumnLabel(field: string): string {
    switch (field) {
      case 'crop_type':
        return 'PRODUCT.CROP_TYPE';
      case 'first_name':
        return 'REPORT.FNAME';
      case 'last_name':
        return 'REPORT.LNAME';
      case 'location':
        return 'HOME.LOCATION';
      case 'municipal_community':
        return 'HOME.UNIT';
      case 'size':
        return 'HOME.SIZE_PLOT';
      case 'variety':
        return 'HOME.VARIETY';
      case 'vat_number':
        return 'REPORT.VAT';
      case 'selected_schemes':
        return 'MULTI.ECO';
      default:
        return '';
    }
  }

  isColDef(column: ColDef<any, any> | ColGroupDef<any>): column is ColDef<any, any> {
    return (column as ColDef<any, any>).field !== undefined;
  }

  updateColumnDefs() {
    if (this.gridOptions.api && this.gridOptions.columnDefs) {
      this.gridOptions.columnDefs.forEach(column => {
        if (this.isColDef(column) && column.field) {
          column.headerName = this.translate.instant(this.getColumnLabel(column.field));
        }
      });
      this.gridOptions.api.setColumnDefs(this.gridOptions.columnDefs);
    }
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  onRowClicked(event: any) {
    if (event.node.isSelected()) {
      event.node.setSelected(false);
    } else {
      event.node.setSelected(true);
    }
  }

  onTabChangeManage(event: any) {
    if (event.index === 0) {
      this.infoFertil = false;
      this.infoSpraying = false
      this.infoTreatment = true
    } else if (event.index === 1) {
      this.infoFertil = true;
      this.infoSpraying = false;
      this.infoTreatment = false;
    } else if (event.index === 2) {
      this.infoFertil = false;
      this.infoSpraying = true;
      this.infoTreatment = false;
    }
  }

  nextStep() {
    if (this.currentStep < 3) {
      if (this.gridOptions.api) {
        const selectedRows = this.gridOptions.api.getSelectedRows();

        this.cropIds.push(selectedRows);
      }
      this.currentStep++;
    }
    if (this.currentStep === 3) {

      this.cropIds[0].forEach((item: any) => {
        this.arraySend.push(item.crop_info_id)
      });

      this.arraySendLength = this.arraySend.length
    }
  }

  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  onChangeFert() {
    if (this.relatedActionUnit == 'CROP.RELATED_ACTION_NONE') {
      this.relatedActionCategoryFert = ['CROP.RELATED_ACTION_NONE']
    } else if (this.relatedActionUnit == 'CROP.RELATED_I') {
      this.relatedActionCategoryFert = ['CROP.CATEGORY_1', 'CROP.CATEGORY_2']
    } else if (this.relatedActionUnit == 'CROP.RELATED_IA') {
      this.relatedActionCategoryFert = ['CROP.CATEGORY_1', 'CROP.CATEGORY_2', 'CROP.CATEGORY_3']
    } else if (this.relatedActionUnit == 'CROP.RELATED_IB') {
      this.relatedActionCategoryFert = ['CROP.CATEGORY_1A', 'CROP.CATEGORY_1B', 'CROP.CATEGORY_2', 'CROP.CATEGORY_3']
    }
  }

  onFertCommercialNameChange() {
    const selectedCommercialName = this.fertilisationCommercialName;
    if (selectedCommercialName) {
      const exists = this.commercialNames.includes(selectedCommercialName);
      if (exists) {
        const selectedProductId = this.productId[selectedCommercialName];
        this.req.getRawProduct(selectedProductId).subscribe((res: any) => {
          this.totalN = res.N;
          this.totalP = res.P;
          this.totalK = res.K;
          this.composition = res.substance;

        });
      } else if (this.fertilisationCommercialName !== 'other') {
        this.totalN = '';
        this.totalP = '';
        this.totalK = '';
        this.composition = '';
      }
    }
  }

  onSprayingCommercialNameChange() {
    const selectedCommercialName = this.commercialName;
    if (selectedCommercialName) {
      const exists = this.sprayingCommercialNames.includes(selectedCommercialName);
      if (exists) {
        const selectedProductId = this.sprayingProductId[selectedCommercialName];
        this.req.getRawProduct(selectedProductId).subscribe((res: any) => {
          this.substance = res.substance;
          this.justification = res.justification;
          this.recommendedDosage = res.dosage;
        })
      }
    }
  }

  changeSpraying() {
    this.sprayingHours = 0;
    this.sprayingMinutes = 0;
    const endDateStart = new Date(this.sprayingEndDate).getTime()
    const startDateStart = new Date(this.sprayingStartDate).getTime()
    this.sumDateTimeSpray = endDateStart - startDateStart
    this.sumDateTime = endDateStart - startDateStart
    const totalSeconds = Math.floor(this.sumDateTimeSpray / 1000);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor(totalSeconds / 3600);
    const days = Math.floor(hours / 24);
    if (hours < 0) {
      this.warnSpray = true
      this.sprayingHours = 0
    } else {
      this.warnSpray = false
      this.sprayingHours = hours
    }
    if (minutes < 0) {
      this.warnSpray = true
      this.sprayingMinutes = 0
    } else {
      this.warnSpray = false
      this.sprayingMinutes = minutes
    }
    if (days < 0) {
      this.warnSpray = true
    } else {
      this.warnSpray = false
    }
  }

  setFixedTime() {
    const fixedTime = 'T09:00';

    if (this.treatmentStartDate) {
      this.startDateTime = formatDate(this.treatmentStartDate, 'yyyy-MM-dd', 'en') + fixedTime;
    }

    if (this.treatmentEndDate) {
      this.endDateTime = formatDate(this.treatmentEndDate, 'yyyy-MM-dd', 'en') + fixedTime;
    }
  }

  pruningCalculate(event: any) {
    const startDate = new Date(this.treatmentStartDate);
    const endDate = new Date(this.treatmentEndDate);
    if ((this.outOfRangeDate(startDate) || this.outOfRangeDate(endDate)) && (this.treatment == 'TREATMENT.2ND' || this.treatment == 'TREATMENT.1ST')) {
      this.summerTreatment = true
    } else {
      this.summerTreatment = false
    }
  }

  outOfRangeDate(date: Date): boolean {
    return date < this.validStartDate || date > this.validEndDate
  }

  addData() {
    const currentLang = this.translate.currentLang;

    this.setFixedTime();
    const title = 'MULTI.ADD_DATA_TITLE';
    const yesDelete = 'MULTI.YES_SAVE';
    const deletedText = 'MULTI.POST_TEXT';
    const cancel = 'PRODUCT.CANCEL';
    const fixedTime = 'T09:00';

    this.translate.use('gr').subscribe(() => {
      if (this.infoTreatment) {
        const startDate = new Date(this.treatmentStartDate);
        const endDate = new Date(this.treatmentEndDate);
        const formattedStartDate = formatDate(startDate, 'yyyy-MM-dd', 'en') + fixedTime;
        const formattedEndDate = formatDate(endDate, 'yyyy-MM-dd', 'en') + fixedTime;
        this.translate.get(this.treatment).subscribe((translatedTreatment: string) => {
          const updatedData = {
            start_date: formattedStartDate ? formattedStartDate : '',
            end_date: formattedEndDate ? formattedEndDate : '',
            crop_info_id: this.arraySend ? this.arraySend : '',
            treatment: translatedTreatment ? translatedTreatment : '',
          };
          this.dataObj = JSON.stringify(updatedData)
        });
      }
      if (this.infoFertil) {
        const startDate = new Date(this.fertilisationStartDate);
        const endDate = new Date(this.fertilisationEndDate);
        const formattedStartDate = formatDate(startDate, 'yyyy-MM-dd', 'en') + fixedTime;
        const formattedEndDate = formatDate(endDate, 'yyyy-MM-dd', 'en') + fixedTime;
        type TranslatableField = 'cultivation_stage' | 'implementation_method' | 'weather_conditions' | 'dosage_applied_by' | 'amount_of_solution_applied_by' | 'related_action' | 'category_by_action';
        this.translate.use('gr').subscribe(() => {
          const fieldsToTranslate: Record<TranslatableField, any> = {
            cultivation_stage: this.cultivationStage,
            implementation_method: this.applicationMethod,
            weather_conditions: this.weatherCondition,
            dosage_applied_by: this.fertilisationDosageAppliedBy,
            amount_of_solution_applied_by: this.fertilisationDosageAppliedBy,
            related_action: this.relatedActionUnit,
            category_by_action: this.relatedActionCategoryUnit
          };
          const translations: Partial<Record<TranslatableField, string>> = {};
          const translationObservables = (Object.keys(fieldsToTranslate) as TranslatableField[]).map(field =>
            this.translate.get(fieldsToTranslate[field]).pipe(
              map((translatedValue: string) => {
                translations[field] = translatedValue;
              })
            )
          );
          forkJoin(translationObservables).subscribe(() => {
            const updatedData = {
              storage_usage: false,
              cultivation_stage: translations['cultivation_stage'] ? translations['cultivation_stage'] : this.cultivationStage,
              implementation_method: translations['implementation_method'] ? translations['implementation_method'] : this.applicationMethod,
              weather_conditions: translations['weather_conditions'] ? translations['weather_conditions'] : this.weatherCondition,
              starting_date: formattedStartDate ? formattedStartDate : '',
              ending_date: formattedEndDate ? formattedEndDate : '',
              crop_info_id: this.arraySend ? this.arraySend : '',
              agronomist: this.fertilisationAgronomist ? this.fertilisationAgronomist : '',
              operator: this.fertilisationOperator ? this.fertilisationOperator : '',
              batch_number: this.fertilisationBatchNumber ? this.fertilisationBatchNumber : '',
              commercial_name: this.fertilisationCommercialName === 'other' ? this.otherFertilisationCommercialName : this.fertilisationCommercialName,
              chemical_composition: this.composition ? this.composition : '',
              related_action: translations['related_action'] ? translations['related_action'] : '',
              category_by_action: translations['category_by_action'] ? translations['category_by_action'] : '',
              dosage: this.fertilisationDosage ? this.fertilisationDosage : 0,
              dosage_measurement_units: this.fertilisationDosageUnit ? this.fertilisationDosageUnit : '',
              dosage_applied_by: translations['dosage_applied_by'] ? translations['dosage_applied_by'] : '',
              amount_of_solution: this.solutionAmount ? this.solutionAmount : 0,
              amount_of_solution_measurement_units: this.solutionUnit ? this.solutionUnit : '',
              amount_of_solution_applied_by: translations['amount_of_solution_applied_by'] ? translations['amount_of_solution_applied_by'] : '',
              n: this.totalN ? this.totalN : 0,
              p: this.totalP ? this.totalP : 0,
              k: this.totalK ? this.totalK : 0,
            };
            this.dataObj = JSON.stringify(updatedData)
          })
        })
      }
      if (this.infoSpraying) {
        const startDate = new Date(this.sprayingStartDate);
        const endDate = new Date(this.sprayingEndDate);
        const formattedStartDate = formatDate(startDate, 'yyyy-MM-dd', 'en') + fixedTime;
        const formattedEndDate = formatDate(endDate, 'yyyy-MM-dd', 'en') + fixedTime;
        type TranslatableField = 'cultivation_stage' | 'implementation_method' | 'weather_conditions' | 'dosage_applied_by' | 'amount_of_solution_applied_by' | 'related_action';;
        this.translate.use('gr').subscribe(() => {
          const fieldsToTranslate: Record<TranslatableField, any> = {
            cultivation_stage: this.cultivationStage,
            implementation_method: this.implementationMethod,
            weather_conditions: this.weatherCondition,
            dosage_applied_by: this.dosageAppliedBy,
            amount_of_solution_applied_by: this.amountAppliedByHundred,
            related_action: this.relatedActionSprayUnit,
          };
          const translations: Partial<Record<TranslatableField, string>> = {};
          const translationObservables = (Object.keys(fieldsToTranslate) as TranslatableField[]).map(field =>
            this.translate.get(fieldsToTranslate[field]).pipe(
              map((translatedValue: string) => {
                translations[field] = translatedValue;
              })
            )
          );
          forkJoin(translationObservables).subscribe(() => {
            const updatedData = {
              storage_usage: false,
              active_substance: this.substance ? this.substance : '',
              agronomist: this.sprayingAgronomist ? this.sprayingAgronomist : '',
              amount_of_solution: this.solutionAmount ? this.solutionAmount : 0,
              amount_of_solution_measurement_units: this.solutionUnit ? this.solutionUnit : '',
              amount_of_solution_applied_by: translations['amount_of_solution_applied_by'] ? translations['amount_of_solution_applied_by'] : '',
              batch_number: this.sprayingBatchNumber ? this.sprayingBatchNumber : '',
              commercial_name: this.commercialName === 'other' ? this.otherCommercialName : this.commercialName,
              cultivation_stage: translations['cultivation_stage'] ? translations['cultivation_stage'] : '',
              datetime: formattedStartDate ? formattedStartDate : '',
              end_datetime: formattedEndDate ? formattedEndDate : '',
              dosage: this.dosage ? this.dosage : 0,
              dosage_applied_by: this.dosageAppliedBy ? this.dosageAppliedBy : '',
              dosage_measurement_units: this.dosageUnit ? this.dosageUnit : '',
              hours: this.sprayingHours ? this.sprayingHours : '',
              implementation_method: translations['implementation_method'] ? translations['implementation_method'] : '',
              justification: this.justification ? this.justification : '',
              operator: this.sprayingOperator ? this.sprayingOperator : '',
              phi: this.phi ? this.phi : 0,
              crop_info_id: this.arraySend ? this.arraySend : '',
              weather_conditions: translations['weather_conditions'] ? translations['weather_conditions'] : '',
              related_action: translations['related_action'] ? translations['related_action'] : '',
              amount_of_item: this.itemAmount ? this.itemAmount : 0,
            };
            this.dataObj = JSON.stringify(updatedData)
          })
        })
      }
      this.translate.get([title, yesDelete, yesDelete, cancel, deletedText])
        .subscribe(translations => {
          Swal.fire({
            title: translations[title],
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: 'rgb(147, 163, 160)',
            confirmButtonColor: 'rgb(34, 139, 34)',
            confirmButtonText: translations[yesDelete],
            cancelButtonText: translations[cancel],
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: '',
                text: translations[deletedText],
                icon: 'success',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                },
                timer: 3000
              });
              if (this.infoTreatment) {
                this.translate.use(currentLang).subscribe(() => {
                  this.req.postTreatmentMultiData(this.dataObj).subscribe(() => {
                    setTimeout(() => {
                      this._location.back();
                    })
                  })
                })
              }
              if (this.infoFertil) {
                this.translate.use(currentLang).subscribe(() => {
                  this.req.postFertiliserMultiData(this.dataObj).subscribe(() => {
                    setTimeout(() => {
                      this._location.back();
                    })
                  })
                })
              }
              if (this.infoSpraying) {
                this.translate.use(currentLang).subscribe(() => {
                  this.req.postSprayingMultiData(this.dataObj).subscribe(() => {
                    setTimeout(() => {
                      this._location.back();
                    })
                  })
                })
              }
            }
          });
        });

    })
  }
}