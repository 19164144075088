import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

interface WeatherCondition {
  last_24_hours: {
    min_hum_out: number;
    max_hum_out: number;
    min_temp_out: number;
    max_temp_out: number;
    total_rainfall_mm: number;
    min_wind_speed_hi: number;
    max_wind_speed_hi: number;
  };
  last_week: {
    min_hum_out: number;
    max_hum_out: number;
    min_temp_out: number;
    max_temp_out: number;
    total_rainfall_mm: number;
    min_wind_speed_hi: number;
    max_wind_speed_hi: number;
  };
  last_two_weeks: {
    min_hum_out: number;
    max_hum_out: number;
    min_temp_out: number;
    max_temp_out: number;
    total_rainfall_mm: number;
    min_wind_speed_hi: number;
    max_wind_speed_hi: number;
    rainfall_week_1: number;
    rainfall_week_2: number;
  };
}

@Component({
  selector: 'app-pests',
  templateUrl: './pests.component.html',
  styleUrls: ['./pests.component.scss']
})

export class PestsComponent implements OnInit {
  selectedPlot: any;
  thermostats: any[] = [];
  airQualityData: any;
  securityPanel: any[] = [];
  locks: any[] = [];
  reportDiseases: any[] = [];
  mappedPlots: any[] = [];
  mappedPlotsFert: any[] = [];
  plots: any[] = [];
  plots2: any[] = [];
  pestFertilizer: any[] = [];
  pestDisease: any[] = [];
  weatherConditions: WeatherCondition[] = [];
  aggregatedData: any[] = [];
  allData: any[] = [];
  cropsFertilisier: any[] = [];
  cropsDiseases: any[] = [];
  diseasesStatus: boolean = false;
  groupedData: any[] = [];
  plotsCodes: any[] = [];
  dateTime: any;

  constructor(public translate: TranslateService, private req: RequestService<any>) { }

  ngOnInit(): void {
    this.initPests();
  }

  initPests() {
    this.req.getPlotsPests().subscribe((res: any) => {
      this.plots2 = res
      const plotCode = res[0].plot_code
      const plotId = res[0]._id
      const plotLocation = res[0].location
      this.selectPlot(plotCode, plotId, plotLocation)
    });
    this.dateTime = new Date();
  }

  plotHasDetectedDisease(plot: any): boolean {
    return plot.diseases.some((disease: any) =>
      disease.report && disease.report.some((report: any) => report.detected)
    );
  }

  plotHasDetectedFertiliser(plot: any): boolean {
    return plot.fertilisers.some((fertiliser: any) =>
      fertiliser.report && fertiliser.report.some((report: any) => report.allowed)
    );
  }

  selectPlot(plot: any, plotSelect: any, plotLocation?: any): void {
    this.selectedPlot = plotLocation
    const firstPlotSelect = plotSelect ? plotSelect : this.plots2[0]
    const plotCode = plot;
    this.groupedData = [];
    this.plotsCodes = [];
    this.weatherConditions = [];
    this.plotsCodes.push(firstPlotSelect.crop)
    this.req.getPestsFertilizer(plotCode).subscribe((resFertiliser) => {
      resFertiliser.forEach((el: any) => {
        if (el.report.length) {
          el.report.forEach((item: any) => {
            this.weatherConditions.push(item.conditions)
          });
        }
      });
      this.req.getPestsDiseases(plotCode).subscribe((resDiseases) => {
        if (this.weatherConditions.length === 0) {
          resDiseases.forEach((el: any) => {
            if (el.report.length) {
              el.report.forEach((item: any) => {
                this.weatherConditions.push(item.conditions)
              });
            }
          });
        }
        this.groupedData = this.groupData(
          firstPlotSelect.crop,
          resFertiliser,
          resDiseases
        );
      });
    });
  }

  groupData(plots: any[], fertiliserData: any[], diseasesData: any[]): any[] {
    return plots.map((plot) => {
      const fertilisers = fertiliserData.filter(
        (fertiliser) => fertiliser.crop_id === plot.crop_info_id
      );
      const diseases = diseasesData.filter(
        (disease) => disease.crop_id === plot.crop_info_id
      );
      return {
        crop_info_id: plot.crop_info_id,
        fertilisers: fertilisers,
        diseases: diseases
      };
    });
  }
}
