<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.MANAGEMENT'| translate}} / {{ 'MANAGEMENT.CROP'| translate}}
</h1>
<div class="container mx-auto px-4">
    <div class="flex-auto mt-2 p-2 lg:px-10 bg-white rounded-lg" id="main_container">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="end" (selectedTabChange)="onTabChangeManage($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="row">
                        <div class="col-6 d-flex align-items-left justify-content-left">
                            <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                                src="../../../../assets/fertilizer_manage.png" alt=""
                                style="background-color: #eac406;
                                 border: 3px solid rgb(253, 255, 252);
                                 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;" width="40">
                        </div>
                        <div class="col-6 d-flex align-items-center justify-content-center">
                            {{ 'CROP.FERT' | translate }}
                        </div>
                    </div>
                </ng-template>
                <div class="container mx-auto px-4">
                    <form style="margin-top: 1%;">
                        <h6 class="text-center text-gray-400 text-sm my-6 font-bold uppercase" id="field">
                            {{ 'CROP.FIELD' | translate }}
                        </h6>
                        <hr class="my-1 border-b-1 border-gray-300" />
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.CODE' | translate }}
                                    </label>
                                    <div
                                        class="card border-1 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <mat-select [formControl]="selectedItems" [(ngModel)]="selectedOptions" #select
                                            name="plotCode" placeholder="{{ 'CARBON.PLOTS' | translate }}"
                                            id="selectPlot">
                                            <mat-checkbox class="example-margin" [checked]="allComplete"
                                                (change)="setAll($event.checked)">
                                                Select All
                                            </mat-checkbox>
                                            <mat-option *ngFor="let code of plotCodes" [value]="code">
                                                <mat-checkbox (change)="toggleCodeSelection($event.checked, code)"
                                                    (click)="$event.stopPropagation()" [checked]="isCodeSelected(code)">
                                                    {{ code[0] }} - {{ code[3] }}
                                                </mat-checkbox>
                                                <hr style="color: gray;">
                                                <span class="row  ml-4 pt-1">
                                                    <div *ngFor="let subtask of code[4]"
                                                        (click)="$event.stopPropagation()">
                                                        <input type="checkbox" id="{{ subtask.id }}" class="check_round"
                                                            name="{{ subtask.id }}" [value]="subtask"
                                                            [checked]="selectedOptions.includes(subtask)"
                                                            (change)="toggleSelection(subtask, $event)">
                                                        <label for="{{ subtask.id }}">
                                                            <span style="font-size: 9px; color: gray;" class="pl-2">{{
                                                                subtask.crop_type }} /
                                                                {{ subtask.variety.split('(')[0]}}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </span>
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <span *ngIf="fertilisationPlotsEmpty" id="errorMsg">
                                        {{ 'CROP.RELATED_ACTION_CATEGORY_MSG' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.CULTSTAGE' | translate }}
                                    </label>
                                    <select
                                        class="card border-1 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="cultivationStage" [ngModelOptions]="{standalone: true}">
                                        <option value="" disabled selected></option>
                                        <option *ngFor="let stage of cultivationStages" [value]="stage">{{ stage |
                                            translate}}
                                        </option>
                                    </select>
                                    <span *ngIf="cultivationStageEmpty" id="errorMsg">
                                        {{ 'CROP.RELATED_ACTION_CATEGORY_MSG' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.METHOD' | translate }}
                                    </label>
                                    <select
                                        class="card border-1 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="applicationMethod" [ngModelOptions]="{standalone: true}">
                                        <option value="" disabled selected></option>
                                        <option *ngFor="let method of applicationMethods" [value]="method">{{ method |

                                            translate }}
                                        </option>
                                    </select>
                                    <span *ngIf="applicationMethodEmpty" id="errorMsg">
                                        {{ 'CROP.RELATED_ACTION_CATEGORY_MSG' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="w-full lg:w-2/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.WEATHER' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="weatherCondition" [ngModelOptions]="{standalone: true}">
                                        <option value="" disabled selected></option>
                                        <option *ngFor="let condition of weatherConditions" [ngValue]="condition">
                                            {{condition | translate}}</option>
                                    </select>
                                    <span *ngIf="weatherConditionEmpty" id="errorMsg">
                                        {{ 'CROP.RELATED_ACTION_CATEGORY_MSG' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'PLOTS.STARTDATE' | translate }}
                                    </label>
                                    <input type="datetime-local"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="fertilisationStartDate" name="fertilisationStartDate" />
                                    <span *ngIf="fertilisationStartDateEmpty" id="errorMsg">
                                        {{ 'CROP.RELATED_ACTION_DATE' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'PLOTS.ENDDATE' | translate }}
                                    </label>
                                    <input type="datetime-local"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="fertilisationEndDate" name="fertilisationEndDate" />
                                    <span *ngIf="fertilisationEndDateEmpty" id="errorMsg">
                                        {{ 'CROP.RELATED_ACTION_DATE' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="w-full lg:w-2/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.AGRONOMIST' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="fertilisationAgronomist" name="fertilisationAgronomist" />
                                </div>
                            </div>
                            <div class="w-full lg:w-2/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.OPERATOR' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="fertilisationOperator" name="fertilisationOperator" />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="row pb-1">
                        <div class="col-4">
                        </div>
                        <div class="col-4">
                            <button mat-raised-button class="mt-3 ml-4" id="fertilizerRecommended"
                                (click)="openDialog()">
                                <i class="fa-solid fa-circle-info"></i>
                                {{'CROP.RECOMMEND' | translate}}
                            </button>
                        </div>
                        <div class="col-4 mt-4">
                            <mat-slide-toggle color="warn" class="float-right" [(ngModel)]="fertToggled">
                                <p class="text-gray-600">{{ fertToggled ? ('CROP.SLIDE_ON' | translate) :
                                    ('CROP.SLIDE_OFF' |
                                    translate) }}</p>
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div *ngFor="let fertilizer of fertilizers; let i = index">
                        <form *ngIf="fertToggled" style="margin-top: -1%;">
                            <h6 class="text-center text-gray-400 text-sm my-6 font-bold uppercase" id="fertilizer">
                                {{ 'CROP.FERTILIZER' | translate }}
                            </h6>
                            <hr class="my-1 border-b-1 border-gray-300" />
                            <div class="flex flex-wrap">
                                <div class="w-full lg:w-2/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.BATCH' | translate }}
                                        </label>
                                        <select
                                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            [(ngModel)]="fertilizer.fertilisationBatchNumber"
                                            name="fertilisationBatchNumber" (change)="onFertBatchChange(i)">
                                            <option value="" disabled selected></option>
                                            <option *ngFor="let batch of fertBatchNumbers" [value]="batch.batchNumber">
                                                {{
                                                batch.batchNumber}} - {{ batch.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="w-full lg:w-2/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.COMMERCIAL' | translate }}
                                        </label>
                                        <input type="text" autocomplete="off" disabled
                                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            [(ngModel)]="fertilizer.fertilisationCommercialName"
                                            name="fertilisationCommercialName" />
                                    </div>
                                </div>
                                <div class="w-full lg:w-2/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.NPK' | translate }}
                                        </label>
                                        <div
                                            class="flex flex-row  border-2 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <div class="mx-1">
                                                <input type="text" disabled
                                                    class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.totalN" name="totalN" />
                                            </div>
                                            <div class="mx-1">
                                                <input type="text" disabled
                                                    class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.totalP" name="totalP" />
                                            </div>
                                            <div class="mx-1">
                                                <input type="text" disabled
                                                    class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.totalK" name="totalK" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-2/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.COMPOSITION' | translate }}
                                        </label>
                                        <input type="text" autocomplete="off" disabled
                                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            [(ngModel)]="fertilizer.composition" name="composition" />
                                    </div>
                                </div>
                                <div class="w-full lg:w-2/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.RELATED_ACTION' | translate }}
                                        </label>
                                        <select
                                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            [(ngModel)]="fertilizer.relatedActionUnit" name="solutionUnit"
                                            (ngModelChange)="onChangeFert(i)">
                                            <option *ngFor="let unit of relatedActionFert" [value]="unit">
                                                {{ unit | translate }}
                                            </option>
                                        </select>
                                        <span *ngIf="fertilizer.relatedActionEmpty"
                                            style="color: red; font-size: 14px; font-weight: 600;">
                                            {{ 'CROP.RELATED_ACTION_MSG' | translate }}
                                        </span>
                                    </div>
                                </div>
                                <div class="w-full lg:w-2/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.RELATED_ACTION_CATEGORY' | translate }}
                                        </label>
                                        <select
                                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            [(ngModel)]="fertilizer.relatedActionCategoryUnit" name="solutionUnit">
                                            <option *ngFor="let unit of fertilizer.relatedActionCategoryFert"
                                                [value]="unit">
                                                {{ unit | translate }}
                                            </option>
                                        </select>
                                        <span *ngIf="fertilizer.relatedActionCategoryEmpty"
                                            style="color: red; font-size: 14px; font-weight: 600;">
                                            {{ 'CROP.RELATED_ACTION_CATEGORY_MSG' | translate }}
                                        </span>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.DOSAGE' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-3/12 mx-1">
                                                <input type="number" min="0" autocomplete="off"
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.fertilisationDosage"
                                                    name="fertilisationDosage" changeSelectPlots
                                                    (ngModelChange)="changeFertilisation(i)"
                                                    (ngModelChange)="onFertBatchChange(i)" />
                                            </div>
                                            <div class="w-3/12 mx-1">
                                                <select
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.fertilisationDosageUnit"
                                                    name="fertilisationDosageUnit"
                                                    (ngModelChange)="onFertBatchChange(i)">
                                                    <option value="unit" disabled selected>{{ 'CROP.UNIT' | translate }}
                                                    </option>
                                                    <option *ngFor="let unit of dosageUnits" [value]="unit">
                                                        {{ unit }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="w-3/12 mx-1">
                                                <select
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.fertilisationDosageAppliedBy"
                                                    name="fertilisationDosageAppliedBy"
                                                    (ngModelChange)="changeFertilisation(i)"
                                                    (ngModelChange)="onFertBatchChange(i)">
                                                    <option value="" disabled selected>{{ 'CROP.BY' | translate }}
                                                    </option>
                                                    <option *ngFor="let unit of dosageBy" changeSelectPlots
                                                        [value]="unit">
                                                        {{ unit |translate }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.TOTALNPK' | translate }}
                                        </label>
                                        <div
                                            class="flex flex-row  border-2 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <div class="w-1/3 mx-1">
                                                <input type="number" disabled
                                                    class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.totalNSum" name="totalNSum" />
                                            </div>
                                            <div class="w-1/3 mx-1">
                                                <input type="number" disabled
                                                    class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.totalPSum" name="totalPSum" />
                                            </div>
                                            <div class="w-1/3 mx-1">
                                                <input type="number" disabled
                                                    class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.totalKSum" name="totalKSum" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Amount of solution -->
                                <div *ngIf="fertilizer.sub100Value" class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.AMOUNT' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-3/12 mx-1">
                                                <input type="number" autocomplete="off" min="0"
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.solutionAmount" name="solutionAmount"
                                                    (ngModelChange)="changeFertilisation(i)"
                                                    (ngModelChange)="onFertBatchChange(i)" />
                                            </div>
                                            <div class="w-3/12 mx-1">
                                                <select
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.solutionUnit" name="solutionUnit">
                                                    <option value="" disabled selected>{{ 'CROP.UNIT' | translate }}
                                                    </option>
                                                    <option *ngFor="let unit of solutionUnits" [value]="unit">
                                                        {{ unit }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="w-3/12 mx-1">
                                                <select
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.fertilisationDosageAppliedBy100"
                                                    name="fertilisationDosageAppliedBy"
                                                    (ngModelChange)="changeFertilisation(i)"
                                                    (ngModelChange)="onFertBatchChange(i)">
                                                    <option value="" disabled selected>{{ 'CROP.BY' | translate }}
                                                    </option>
                                                    <option *ngFor="let unit of dosageBySpray" [value]="unit">
                                                        {{ unit | translate}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div *ngIf="fertilizer.chooseTree" class="relative w-full mb-3 rounded"
                                        id="borderSum">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.TOTALAMOUNT' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-1/3 mx-1">
                                                <input type="number" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.totalAmount"
                                                    (ngModelChange)="onFertBatchChange(i)" name="totalAmount" />
                                            </div>
                                            <div class="w-1/3 mx-1">
                                                <input disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="fertilizer.fertilisationDosageUnit"
                                                    name="fertilisationDosageUnit" />
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="fertilizer.chooseAcre" class="relative w-full mb-3 rounded"
                                        id="borderSum">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.TOTALAMOUNT' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-1/3 mx-1">
                                                <input type="number" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.totalAmount" name="totalAmount"
                                                    (ngModelChange)="onFertBatchChange(i)" />
                                            </div>
                                            <div class="w-1/3 mx-1">
                                                <input disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="fertilizer.fertilisationDosageUnit"
                                                    name="fertilisationDosageUnit" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 100 Sum -->
                                    <div *ngIf="fertilizer.chooseHundred && fertilizer.sub100Value">
                                        <div *ngIf="fertilizer.chooseHundredTree" id="borderSum">
                                            <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password">
                                                {{ 'CROP.TOTALAMOUNT' | translate }} /{{ 'MANAGEMENT.TREE' | translate
                                                }}
                                            </label>
                                            <div class="flex flex-row">
                                                <div class="w-9/12 mx-1">
                                                    <input type="number" autocomplete="off" disabled
                                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        [value]="fertilizer.totalAmount" />
                                                </div>
                                                <div class="w-3/12 mx-1">
                                                    <input type="text" autocomplete="off" disabled
                                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        [value]="fertilizer.fertilisationDosageUnit"
                                                        name="fertilisationDosageUnit" />
                                                </div>
                                            </div>
                                        </div>
                                        <div id="borderSum" *ngIf="fertilizer.chooseHundredAcres">
                                            <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password">
                                                {{ 'CROP.TOTALAMOUNT' | translate }} /{{ 'MANAGEMENT.ACRE' | translate
                                                }}
                                            </label>
                                            <div class="flex flex-row">
                                                <div class="w-9/12 mx-1">
                                                    <input type="number" autocomplete="off" disabled
                                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        [value]="fertilizer.totalAmount" />
                                                </div>
                                                <div class="w-3/12 mx-1">
                                                    <input type="text" autocomplete="off" disabled
                                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        [value]="fertilizer.fertilisationDosageUnit"
                                                        name="fertilisationDosageUnit" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form *ngIf="!fertToggled" style="margin-top: -1%;">
                            <h6 class="text-gray-400 text-sm my-6 font-bold uppercase" id="fertilizer">
                                {{ 'CROP.FERTILIZER' | translate }}
                            </h6>
                            <hr class="my-1 border-b-1 border-gray-300" />
                            <div class="flex flex-wrap">
                                <div class="w-full lg:w-2/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.BATCH' | translate }}
                                        </label>
                                        <input type="text" autocomplete="off"
                                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            [(ngModel)]="fertilizer.fertilisationBatchNumber"
                                            name="sprayingBatchNumber" />
                                    </div>
                                </div>
                                <div class="w-full lg:w-2/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            [for]="'fertilisationCommercialName' + i">
                                            {{ 'CROP.COMMERCIAL' | translate }}
                                        </label>
                                        <mat-form-field class="w-full">
                                            <input type="text" matInput [formControl]="fertilizerControls[i]"
                                                [matAutocomplete]="auto" (ngModelChange)="onFertCommercialNameChange(i)"
                                                [id]="'fertilisationCommercialName' + i" />
                                            <mat-autocomplete [id]="'auto' + i" #auto="matAutocomplete"
                                                (optionSelected)="onFertCommercialNameChange(i)">
                                                <mat-option *ngFor="let name of filteredCommercialNames[i] | async"
                                                    [value]="name">
                                                    {{ name }}
                                                </mat-option>
                                                <mat-option value="other">{{ 'CROP.OTHER' | translate }}</mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                        <input *ngIf="fertilizerControls[i].value === 'other'"
                                            [(ngModel)]="fertilizers[i].otherFertilisationCommercialName"
                                            [name]="'otherFertilisationCommercialName' + i" autocomplete="off"
                                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                    </div>
                                </div>
                                <div class="w-full lg:w-2/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.NPK' | translate }}
                                        </label>
                                        <div
                                            class="flex flex-row border-2 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <div class="mx-1">
                                                <input type="number" min="0"
                                                    [disabled]="fertilizerControls[i].value !== 'other'"
                                                    class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizers[i].totalN" name="totalN" />
                                            </div>
                                            <div class="mx-1">
                                                <input type="number" min="0"
                                                    [disabled]="fertilizerControls[i].value !== 'other'"
                                                    class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizers[i].totalP" name="totalP" />
                                            </div>
                                            <div class="mx-1">
                                                <input type="number" min="0"
                                                    [disabled]="fertilizerControls[i].value !== 'other'"
                                                    class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizers[i].totalK" name="totalK" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-2/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.COMPOSITION' | translate }}
                                        </label>
                                        <input type="text" autocomplete="on"
                                            [disabled]="fertilizerControls[i].value !== 'other'"
                                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            [(ngModel)]="fertilizers[i].composition" name="composition" />
                                    </div>
                                </div>
                                <div class="w-full lg:w-2/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.RELATED_ACTION' | translate }}
                                        </label>
                                        <select
                                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            [(ngModel)]="fertilizer.relatedActionUnit" name="solutionUnit"
                                            (ngModelChange)="onChangeFert(i)">
                                            <option *ngFor="let unit of relatedActionFert" [value]="unit">
                                                {{ unit | translate }}
                                            </option>
                                        </select>
                                        <span *ngIf="fertilizer.relatedActionEmpty"
                                            style="color: red; font-size: 14px; font-weight: 600;">
                                            {{ 'CROP.RELATED_ACTION_MSG' | translate }}
                                        </span>
                                    </div>
                                </div>
                                <div class="w-full lg:w-2/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.RELATED_ACTION_CATEGORY' | translate }}
                                        </label>
                                        <select
                                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            [(ngModel)]="fertilizer.relatedActionCategoryUnit" name="solutionUnit">
                                            <option *ngFor="let unit of fertilizer.relatedActionCategoryFert"
                                                [value]="unit">
                                                {{ unit | translate }}
                                            </option>
                                        </select>
                                        <span *ngIf="fertilizer.relatedActionCategoryEmpty"
                                            style="color: red; font-size: 14px; font-weight: 600;">
                                            {{ 'CROP.RELATED_ACTION_CATEGORY_MSG' | translate }}
                                        </span>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.DOSAGE' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-3/12 mx-1">
                                                <input type="number" min="0" autocomplete="off"
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.fertilisationDosage"
                                                    name="fertilisationDosage" changeSelectPlots
                                                    (ngModelChange)="changeFertilisation(i)"
                                                    (ngModelChange)="onFertBatchChange(i)" />
                                            </div>
                                            <div class="w-3/12 mx-1">
                                                <select
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.fertilisationDosageUnit"
                                                    name="fertilisationDosageUnit"
                                                    (ngModelChange)="onFertBatchChange(i)">
                                                    <option value="unit" disabled selected>{{ 'CROP.UNIT' | translate }}
                                                    </option>
                                                    <option *ngFor="let unit of dosageUnits" [value]="unit">
                                                        {{ unit }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="w-3/12 mx-1">
                                                <select
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.fertilisationDosageAppliedBy"
                                                    name="fertilisationDosageAppliedBy"
                                                    (ngModelChange)="changeFertilisation(i)"
                                                    (ngModelChange)="onFertBatchChange(i)">
                                                    <option value="" disabled selected>{{ 'CROP.BY' | translate }}
                                                    </option>
                                                    <option *ngFor="let unit of dosageBy" changeSelectPlots
                                                        [value]="unit">
                                                        {{ unit | translate}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.TOTALNPK' | translate }}
                                        </label>
                                        <div
                                            class="flex flex-row  border-2 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                            <div class="w-1/3 mx-1">
                                                <input type="number" disabled
                                                    class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.totalNSum" name="totalNSum" />
                                            </div>
                                            <div class="w-1/3 mx-1">
                                                <input type="number" disabled
                                                    class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.totalPSum" name="totalPSum" />
                                            </div>
                                            <div class="w-1/3 mx-1">
                                                <input type="number" disabled
                                                    class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.totalKSum" name="totalKSum" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Amount of solution without storage-->
                                <div *ngIf="fertilizer.sub100Value" class="w-full lg:w-6/12 px-2">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.AMOUNT' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-3/12 mx-1">
                                                <input type="number" autocomplete="off" min="0"
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.solutionAmount" name="solutionAmount"
                                                    (ngModelChange)="changeFertilisation(i)"
                                                    (ngModelChange)="onFertBatchChange(i)" />
                                            </div>
                                            <div class="w-3/12 mx-1">
                                                <select
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.solutionUnit" name="solutionUnit">
                                                    <option value="" disabled selected>{{ 'CROP.UNIT' | translate }}
                                                    </option>
                                                    <option *ngFor="let unit of solutionUnits" [value]="unit">
                                                        {{ unit }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="w-3/12 mx-1">
                                                <select
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.fertilisationDosageAppliedBy100"
                                                    name="fertilisationDosageAppliedBy"
                                                    (ngModelChange)="changeFertilisation(i)"
                                                    (ngModelChange)="onFertBatchChange(i)">
                                                    <option value="" disabled selected>{{ 'CROP.BY' | translate }}
                                                    </option>
                                                    <option *ngFor="let unit of dosageBySpray" [value]="unit">
                                                        {{ unit | translate}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-6/12 px-4">
                                    <div *ngIf="fertilizer.chooseTree" class="relative w-full mb-3 rounded"
                                        id="borderSum">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.TOTALAMOUNT' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-1/3 mx-1">
                                                <input type="number" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.totalAmount"
                                                    (ngModelChange)="onFertBatchChange(i)" name="totalAmount" />
                                            </div>
                                            <div class="w-1/3 mx-1">
                                                <input disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="fertilizer.fertilisationDosageUnit"
                                                    name="fertilisationDosageUnit" />
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="fertilizer.chooseAcre" class="relative w-full mb-3 rounded"
                                        id="borderSum">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.TOTALAMOUNT' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-1/3 mx-1">
                                                <input type="number" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilizer.totalAmount" name="totalAmount"
                                                    (ngModelChange)="onFertBatchChange(i)" />
                                            </div>
                                            <div class="w-1/3 mx-1">
                                                <input disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="fertilizer.fertilisationDosageUnit"
                                                    name="fertilisationDosageUnit" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 100 Sum -->
                                    <div *ngIf="fertilizer.chooseHundred && fertilizer.sub100Value">
                                        <div *ngIf="fertilizer.chooseHundredTree" id="borderSum">
                                            <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password">
                                                {{ 'CROP.TOTALAMOUNT' | translate }} /{{ 'MANAGEMENT.TREE' | translate
                                                }}
                                            </label>
                                            <div class="flex flex-row">
                                                <div class="w-9/12 mx-1">
                                                    <input type="number" autocomplete="off" disabled
                                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        [value]="fertilizer.totalAmount" />
                                                </div>
                                                <div class="w-3/12 mx-1">
                                                    <input type="text" autocomplete="off" disabled
                                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        [value]="fertilizer.fertilisationDosageUnit"
                                                        name="fertilisationDosageUnit" />
                                                </div>
                                            </div>
                                        </div>
                                        <div id="borderSum" *ngIf="fertilizer.chooseHundredAcres">
                                            <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password">
                                                {{ 'CROP.TOTALAMOUNT' | translate }} /{{ 'MANAGEMENT.ACRE' | translate
                                                }}
                                            </label>
                                            <div class="flex flex-row">
                                                <div class="w-9/12 mx-1">
                                                    <input type="number" autocomplete="off" disabled
                                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        [value]="fertilizer.totalAmount" />
                                                </div>
                                                <div class="w-3/12 mx-1">
                                                    <input type="text" autocomplete="off" disabled
                                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        [value]="fertilizer.fertilisationDosageUnit"
                                                        name="fertilisationDosageUnit" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <button type="button" class="m-2 mt-4" matTooltip='{{ "CROP.TOOLTIP_DELETE" | translate }}'
                            (click)="removeFertilizer(i)">
                            <i class="fas fa-minus text-lg"></i>
                        </button>
                    </div>
                    <button type="button" class="m-2 mt-4" matTooltip='{{ "CROP.TOOLTIP_ADD" | translate }}'
                        (click)="addFertilizer()">
                        <i class="fas fa-plus text-lg right-0"></i>
                    </button>
                    <button mat-raised-button id="saveButton" class="float-right m-2" type="button"
                        (click)="addFertilisation()">
                        <mat-icon>done</mat-icon>
                        {{ 'MANAGEMENT.SAVE' | translate }}
                    </button>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="row">
                        <div class="col-6 d-flex align-items-left justify-content-left">
                            <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                                src="../../../../assets/watering.png" alt=""
                                style="background-color: #9bf0e8;
                                 border: 3px solid rgb(253, 255, 252);
                                 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;" width="40">
                        </div>
                        <div class="col-6 d-flex align-items-center justify-content-center">
                            {{ 'CROP.IRRIGATE' | translate }}
                        </div>
                    </div>
                </ng-template>
                <div class="container mx-auto px-4">
                    <form style="margin-top: 1%;">
                        <h6 class="text-center text-gray-400 text-sm my-6 font-bold uppercase" id="field">
                            {{ 'CROP.FIELD' | translate }}
                        </h6>
                        <hr class="my-1 border-b-1 border-gray-300" />
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.CODE' | translate }}
                                    </label>
                                    <div
                                        class="card border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <mat-select [formControl]="selectedItems" [(ngModel)]="selectedOptions" #select
                                            name="plotCode" placeholder="{{ 'CARBON.PLOTS' | translate }}"
                                            id="selectPlot">
                                            <mat-checkbox class="example-margin" [checked]="allComplete"
                                                (change)="setAll($event.checked)">
                                                Select All
                                            </mat-checkbox>
                                            <mat-option *ngFor="let code of plotCodes" [value]="code">
                                                <mat-checkbox (change)="toggleCodeSelection($event.checked, code)"
                                                    (click)="$event.stopPropagation()" [checked]="isCodeSelected(code)">
                                                    {{ code[0] }} - {{ code[3] }}
                                                </mat-checkbox>
                                                <hr style="color: gray;">
                                                <span class="row  ml-4 pt-1">
                                                    <div *ngFor="let subtask of code[4]"
                                                        (click)="$event.stopPropagation()">
                                                        <input type="checkbox" id="{{ subtask.id }}" class="check_round"
                                                            name="{{ subtask.id }}" [value]="subtask"
                                                            [checked]="selectedOptions.includes(subtask)"
                                                            (change)="toggleSelection(subtask, $event)">
                                                        <label for="{{ subtask.id }}">
                                                            <span style="font-size: 10px; color: gray;" class="pl-2">{{
                                                                subtask.crop_type }} /
                                                                {{ subtask.variety.split('(')[0]}}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </span>
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.IRRMETHOD' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="irrigationMethod" [ngModelOptions]="{standalone: true}">
                                        <option value="" disabled selected></option>
                                        <option *ngFor="let method of irrigationMethods" [ngValue]="method">{{method |

                                            translate}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.FREQUENCY' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="frequency" [ngModelOptions]="{standalone: true}">
                                        <option value="" disabled selected></option>
                                        <option *ngFor="let frequency of frequencyOptions" [ngValue]="frequency">
                                            {{frequency | translate}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'PLOTS.STARTDATE' | translate }}
                                    </label>
                                    <input type="datetime-local"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="irrigationStartTime" name="irrigationStartTime"
                                        (ngModelChange)="changeIrrigation()" />
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'PLOTS.ENDDATE' | translate }}
                                    </label>
                                    <input type="datetime-local"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="irrigationEndTime" name="irrigationEndTime"
                                        (ngModelChange)="changeIrrigation()" />
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.DURATION' | translate }}
                                    </label>
                                    <input type="number" min="0" step="0.5" autocomplete="off" readonly
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        (ngModelChange)="changeIrrigation()" />
                                    <div class="overlay mt-2">
                                        {{irrHours}} hours : {{irrMinutes}} minutes
                                    </div>
                                </div>
                                <div class="mb-3" *ngIf="wrongTime" style="
                                    color: red;
                                    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 10px !important;
                                    padding: 3%;
                                    border-radius: 12px;">
                                    <i class="fa-solid fa-circle-exclamation"></i>
                                    <span style="color: #ef4444; font-weight: 600;">
                                        {{'IRRIGATION.WRONG_TIME' | translate}}
                                    </span>
                                </div>
                                <div class="mb-3" *ngIf="warnIrrigation" style="
                                    color: rgb(234, 196, 6);
                                    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 10px !important;
                                    padding: 3%;
                                    border-radius: 12px;">
                                    <i class=" fa-solid fa-triangle-exclamation"></i>
                                    <span style="font-weight: 600;">
                                        {{'IRRIGATION.WRONG_DURATION' | translate}}
                                    </span>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'IRRIGATION.WATER_SOURCE' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="sourceItem" [ngModelOptions]="{standalone: true}">
                                        <option value="" disabled selected></option>
                                        <option *ngFor="let sourceItem of waterSource" [ngValue]="sourceItem">
                                            {{ sourceItem | translate }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'IRRIGATION.WATER_SUPPLY' | translate }}
                                    </label>
                                    <input type="number" min="0" step="0.01" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="waterSourceQuantity" name="waterSourceQuantity"
                                        (ngModelChange)="changeIrrigation()" />
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.QUANTITY' | translate }}
                                    </label>
                                    <input type="number" min="0" step="0.01" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="quantity" name="quantity" />
                                </div>
                            </div>
                        </div>
                    </form>
                    <button mat-raised-button id="saveButton" class="float-right m-2" type="button"
                        (click)="addIrrigation()">
                        <mat-icon>done</mat-icon>
                        {{ 'MANAGEMENT.SAVE' | translate }}
                    </button>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="row">
                        <div class="col-6 d-flex align-items-left justify-content-left">
                            <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                                src="../../../../assets/agro_protection.png" alt=""
                                style="background-color: #8deda3;
                                 border: 3px solid rgb(253, 255, 252);
                                 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;" width="40">
                        </div>
                        <div class="col-6 d-flex align-items-center justify-content-center">
                            {{ 'CROP.SPRAY' | translate }}
                        </div>
                    </div>
                </ng-template>
                <div class="container mx-auto px-4">
                    <form style="margin-top: 1%;">
                        <h6 class="text-center text-gray-400 text-sm my-6 font-bold uppercase" id="field">
                            {{ 'CROP.FIELD' | translate }}
                        </h6>
                        <hr class="my-1 border-b-1 border-gray-300" />
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.CODE' | translate }}
                                    </label>
                                    <div
                                        class="card border-1 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <mat-select [formControl]="selectedItems" [(ngModel)]="selectedOptions" #select
                                            name="plotCode" placeholder="{{ 'CARBON.PLOTS' | translate }}"
                                            id="selectPlot">
                                            <mat-checkbox class="example-margin" [checked]="allComplete"
                                                (change)="setAll($event.checked)">
                                                Select All
                                            </mat-checkbox>
                                            <mat-option *ngFor="let code of plotCodes" [value]="code">
                                                <mat-checkbox (change)="toggleCodeSelection($event.checked, code)"
                                                    (click)="$event.stopPropagation()" [checked]="isCodeSelected(code)">
                                                    {{ code[0] }} - {{ code[3] }}
                                                </mat-checkbox>
                                                <hr style="color: gray;">
                                                <span class="row  ml-4 pt-1">
                                                    <div *ngFor="let subtask of code[4]"
                                                        (click)="$event.stopPropagation()">
                                                        <input type="checkbox" id="{{ subtask.id }}" class="check_round"
                                                            name="{{ subtask.id }}" [value]="subtask"
                                                            [checked]="selectedOptions.includes(subtask)"
                                                            (change)="toggleSelection(subtask, $event)">
                                                        <label for="{{ subtask.id }}">
                                                            <span style="font-size: 9px; color: gray;" class="pl-2">{{
                                                                subtask.crop_type }} /
                                                                {{ subtask.variety.split('(')[0]}}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </span>
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.CULTSTAGE' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="cultivationStage" name="cultivationStage">
                                        <option value="" disabled selected></option>
                                        <option *ngFor="let stage of cultivationStages" [value]="stage">{{ stage |

                                            translate }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.METHODSPR' | translate }}
                                    </label>
                                    <div *ngIf="!itemToggled">
                                        <select
                                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            [(ngModel)]="implementationMethod" name="implementationMethod"
                                            [disabled]="itemToggled">
                                            <option value="" disabled selected></option>
                                            <option *ngFor="let method of implementationMethods" [value]="method">
                                                {{ method | translate }}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="itemToggled">
                                        <input type="text" disabled
                                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            [value]="translatedOtherValue" />
                                    </div>
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.WEATHER' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="weatherCondition" [ngModelOptions]="{standalone: true}">
                                        <option value="" disabled selected></option>
                                        <option *ngFor="let condition of weatherConditions" [ngValue]="condition">
                                            {{condition | translate}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'PLOTS.STARTDATE' | translate }}
                                    </label>
                                    <input type="datetime-local"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="sprayingStartDate" name="sprayingDate"
                                        (ngModelChange)="changeSpraying()" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'PLOTS.ENDDATE' | translate }}
                                    </label>
                                    <input type="datetime-local"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="sprayingEndDate" name="sprayingDate"
                                        (ngModelChange)="changeSpraying()" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.DURATION' | translate }}
                                    </label>
                                    <input type="text" readonly
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        (ngModelChange)="changeSpraying()" />
                                    <div class="overlay mt-2">
                                        {{sprayingHours}} hours : {{sprayingMinutes}} minutes
                                    </div>
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div *ngIf="warnSpray" span style="
                                    color: red;
                                    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 10px !important;
                                    padding: 3%;
                                    border-radius: 12px;">
                                    <i class="fa-solid fa-circle-exclamation"></i>
                                    <span style="color: #ef4444; font-weight: 600;">
                                        {{'IRRIGATION.WRONG_TIME' | translate}}
                                    </span>
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.AGRONOMIST' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="sprayingAgronomist" name="sprayingAgronomist" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.OPERATOR' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="sprayingOperator" name="sprayingOperator" />
                                </div>
                            </div>
                        </div>
                    </form>
                    <mat-slide-toggle color="warn" class="float-right mx-2" [(ngModel)]="sprayToggled"
                        [disabled]="itemToggled">
                        <p class="text-gray-600">{{ sprayToggled ? ('CROP.SLIDE_ON' | translate) : ('CROP.SLIDE_OFF' |
                            translate) }}</p>
                    </mat-slide-toggle>
                    <mat-slide-toggle color="warn" class="float-right mx-2" [(ngModel)]="itemToggled"
                        (change)="onItemToggleChange()">
                        <p class="text-gray-600">{{ itemToggled ? ('CROP.ITEMSLIDE_ON' | translate) :
                            ('CROP.ITEMSLIDE_OFF' |
                            translate) }}</p>
                    </mat-slide-toggle>
                </div>
                <div class="container mx-auto px-4 mt-2">
                    <form *ngIf="sprayToggled && !itemToggled" style="margin-top: -1%;">
                        <h6 class="text-center text-gray-400 text-sm my-3 font-bold uppercase" id="spraying">
                            {{ 'CROP.PROTECTION' | translate }}
                        </h6>
                        <hr class="my-1 border-b-1 border-gray-300" />
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.BATCH' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="sprayingBatchNumber" name="sprayingBatchNumber"
                                        (change)="onSprayingBatchChange()">
                                        <option value="" disabled selected></option>
                                        <option *ngFor="let batch of sprayingBatchNumbers" [value]="batch.batchNumber">
                                            {{ batch.batchNumber }} - {{ batch.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.COMMERCIAL' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off" disabled
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="commercialName" name="commercialName" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.SUBSTANCE' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off" disabled
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="substance" name="substance" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.RELATED_ACTION' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="relatedActionSprayUnit" name="solutionUnit">
                                        <option *ngFor="let unit of relatedActionSpray" [value]="unit">
                                            {{ unit | translate }}
                                        </option>
                                    </select>
                                    <span *ngIf="relatedActionSprayEmpty" id="errorMsg">
                                        {{ 'CROP.RELATED_ACTION_CATEGORY_MSG' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="w-full px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.OBJECTIVE' | translate }}
                                    </label>
                                    <textarea autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="justification" name="justification">
                                    </textarea>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.DOSAGE' | translate }} <div *ngIf="this.recommendedDosage != ''"
                                            class="text-red-600">Recommended: {{ recommendedDosage }} </div>
                                    </label>
                                    <div class="flex flex-row">
                                        <div class="w-3/12 mx-1">
                                            <input type="number" autocomplete="off" min="0"
                                                class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                [(ngModel)]="dosage" name="dosage" (ngModelChange)="changeSpraying()" />
                                        </div>
                                        <div class="w-3/12 mx-1">
                                            <select
                                                class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                [(ngModel)]="dosageUnit" name="dosageUnit">
                                                <option value="" disabled selected>{{ 'CROP.UNIT' | translate }}
                                                </option>
                                                <option *ngFor="let unit of dosageUnits" [value]="unit">
                                                    {{ unit }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="w-3/12 mx-1">
                                            <select
                                                class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                [(ngModel)]="dosageAppliedBy" name="dosageAppliedBy"
                                                (ngModelChange)="changeSpraying()">
                                                <option value="" disabled selected>{{ 'CROP.BY' | translate }}</option>
                                                <option *ngFor="let unit of dosageBy" [value]="unit">
                                                    {{ unit | translate }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.PHI' | translate }}
                                    </label>
                                    <input type="number" autocomplete="off" min="0"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="phi" name="phi" />
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.AMOUNT' | translate }}
                                    </label>
                                    <div class="flex flex-row">
                                        <div class="w-3/12 mx-1">
                                            <input type="number" autocomplete="off" min="0"
                                                class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                [(ngModel)]="solutionAmount" name="solutionAmount"
                                                (ngModelChange)="changeSpraying()" />
                                        </div>
                                        <div class="w-3/12 mx-1">
                                            <select
                                                class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                [(ngModel)]="solutionUnit" name="solutionUnit">
                                                <option value="" disabled selected>{{ 'CROP.UNIT' | translate }}
                                                </option>
                                                <option *ngFor="let unit of solutionUnits" [value]="unit">
                                                    {{ unit }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="w-3/12 mx-1">
                                            <select
                                                class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                [(ngModel)]="amountAppliedByHundred" name="amountAppliedByHundred"
                                                (ngModelChange)="changeSpraying()">
                                                <option value="" disabled selected>{{ 'CROP.BY' | translate }}</option>
                                                <option *ngFor="let unit of dosageBySpray" [value]="unit">
                                                    {{ unit | translate}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div *ngIf="chooseAcreSpray">
                                    <div id="borderSum">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.TOTALAMOUNT' | translate }} /{{ 'MANAGEMENT.ACRE' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-10/12 mx-1">
                                                <input type="number" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="totalAmountSpray" />
                                            </div>
                                            <div class="w-2/12 mx-1">
                                                <input type="text" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="dosageUnit" name="dosageUnit" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="chooseTreeSpray">
                                    <div id="borderSum">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.TOTALAMOUNT' | translate }} /{{ 'MANAGEMENT.TREE' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-10/12 mx-1">
                                                <input type="number" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="totalAmountSpray" />
                                            </div>
                                            <div class="w-2/12 mx-1">
                                                <input type="text" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="dosageUnit" name="dosageUnit" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="chooseHundredSpray">
                                    <div id="borderSum">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.TOTALAMOUNT' | translate }} /{{ 'MANAGEMENT.TREE' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-9/12 mx-1">
                                                <input type="number" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="totalAmountSpray" />
                                            </div>
                                            <div class="w-3/12 mx-1">
                                                <input type="text" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="dosageUnit" name="dosageUnit" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form *ngIf="!sprayToggled && !itemToggled" style="margin-top: -1%;">
                        <h6 class="text-center text-gray-400 text-sm my-3 font-bold uppercase" id="spraying">
                            {{ 'CROP.PROTECTION' | translate }}
                        </h6>
                        <hr class="my-1 border-b-1 border-gray-300" />
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.BATCH' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="sprayingBatchNumber" name="sprayingBatchNumber" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.COMMERCIAL' | translate }}
                                    </label>
                                    <mat-form-field class="w-full">
                                        <input type="text" matInput [formControl]="sprayCommercialNameControl"
                                            [matAutocomplete]="auto" />
                                        <mat-autocomplete #auto="matAutocomplete"
                                            (optionSelected)="onSprayingCommercialNameChange()">
                                            <mat-option *ngFor="let name of filteredSprayCommercialNames | async"
                                                [value]="name">
                                                {{ name }}
                                            </mat-option>
                                            <mat-option value="other">{{ 'CROP.OTHER' | translate }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <input *ngIf="sprayCommercialNameControl.value === 'other'"
                                        [(ngModel)]="otherCommercialName" name="otherCommercialName" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.SUBSTANCE' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off" [disabled]="commercialName !== 'other'"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="substance" name="substance" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.RELATED_ACTION' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="relatedActionSprayUnit" name="solutionUnit">
                                        <option *ngFor="let unit of relatedActionSpray" [value]="unit">
                                            {{ unit | translate }}
                                        </option>
                                    </select>
                                    <span *ngIf="relatedActionSprayEmpty"
                                        style="color: red; font-size: 14px; font-weight: 600;">
                                        {{ 'CROP.RELATED_ACTION_CATEGORY_MSG' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="w-full px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.OBJECTIVE' | translate }}
                                    </label>
                                    <textarea autocomplete="off" [disabled]="commercialName !== 'other'"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="justification" name="justification">
                                    </textarea>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.DOSAGE' | translate }} <div *ngIf="this.recommendedDosage != ''"
                                            class="text-red-600">Recommended: {{ recommendedDosage }} </div>
                                    </label>
                                    <div class="flex flex-row">
                                        <div class="w-3/12 mx-1">
                                            <input type="number" autocomplete="off" min="0"
                                                class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                [(ngModel)]="dosage" name="dosage" (ngModelChange)="changeSpraying()" />
                                        </div>
                                        <div class="w-3/12 mx-1">
                                            <select
                                                class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                [(ngModel)]="dosageUnit" name="dosageUnit">
                                                <option value="" disabled selected>{{ 'CROP.UNIT' | translate }}
                                                </option>
                                                <option *ngFor="let unit of dosageUnits" [value]="unit">
                                                    {{ unit }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="w-3/12 mx-1">
                                            <select
                                                class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                [(ngModel)]="dosageAppliedBy" name="dosageAppliedBy"
                                                (ngModelChange)="changeSpraying()">
                                                <option value="" disabled selected>{{ 'CROP.BY' | translate }}</option>
                                                <option *ngFor="let unit of dosageBy" [value]="unit">
                                                    {{ unit | translate}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.PHI' | translate }}
                                    </label>
                                    <input type="number" autocomplete="off" min="0"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="phi" name="phi" />
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.AMOUNT' | translate }}
                                    </label>
                                    <div class="flex flex-row">
                                        <div class="w-3/12 mx-1">
                                            <input type="number" autocomplete="off" min="0"
                                                class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                [(ngModel)]="solutionAmount" name="solutionAmount"
                                                (ngModelChange)="changeSpraying()" />
                                        </div>
                                        <div class="w-3/12 mx-1">
                                            <select
                                                class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                [(ngModel)]="solutionUnit" name="solutionUnit">
                                                <option value="" disabled selected>{{ 'CROP.UNIT' | translate }}
                                                </option>
                                                <option *ngFor="let unit of solutionUnits" [value]="unit">
                                                    {{ unit }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="w-3/12 mx-1">
                                            <select
                                                class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                [(ngModel)]="amountAppliedByHundred" name="amountAppliedByHundred"
                                                (ngModelChange)="changeSpraying()">
                                                <option value="" disabled selected>{{ 'CROP.BY' | translate }}</option>
                                                <option *ngFor="let unit of dosageBySpray" [value]="unit">
                                                    {{ unit | translate}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div *ngIf="chooseAcreSpray">
                                    <div id="borderSum">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.TOTALAMOUNT' | translate }} /{{ 'MANAGEMENT.ACRE' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-10/12 mx-1">
                                                <input type="number" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="totalAmountSpray" />
                                            </div>
                                            <div class="w-2/12 mx-1">
                                                <input type="text" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="dosageUnit" name="dosageUnit" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="chooseTreeSpray">
                                    <div id="borderSum">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.TOTALAMOUNT' | translate }} /{{ 'MANAGEMENT.TREE' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-10/12 mx-1">
                                                <input type="number" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="totalAmountSpray" />
                                            </div>
                                            <div class="w-2/12 mx-1">
                                                <input type="text" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="dosageUnit" name="dosageUnit" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="chooseHundredSpray">
                                    <div id="borderSum">
                                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            {{ 'CROP.TOTALAMOUNT' | translate }} /{{ 'MANAGEMENT.TREE' | translate }}
                                        </label>
                                        <div class="flex flex-row">
                                            <div class="w-9/12 mx-1">
                                                <input type="number" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="totalAmountSpray" />
                                            </div>
                                            <div class="w-3/12 mx-1">
                                                <input type="text" autocomplete="off" disabled
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [value]="dosageUnit" name="dosageUnit" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form *ngIf="!sprayToggled && itemToggled" style="margin-top: -1%;">
                        <h6 class="text-center text-gray-400 text-sm my-3 font-bold uppercase" id="spraying">
                            {{ 'CROP.PROTECTION' | translate }}
                        </h6>
                        <hr class="my-1 border-b-1 border-gray-300" />
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.BATCH' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="sprayingBatchNumber" name="sprayingBatchNumber" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.COMMERCIAL' | translate }}
                                    </label>
                                    <mat-form-field class="w-full">
                                        <input type="text" matInput [formControl]="sprayCommercialNameControl"
                                            [matAutocomplete]="auto" />
                                        <mat-autocomplete #auto="matAutocomplete"
                                            (optionSelected)="onSprayingCommercialNameChange()">
                                            <mat-option *ngFor="let name of filteredSprayCommercialNames | async"
                                                [value]="name">
                                                {{ name }}
                                            </mat-option>
                                            <mat-option value="other">{{ 'CROP.OTHER' | translate }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <input *ngIf="commercialName === 'other'" [(ngModel)]="otherCommercialName"
                                        name="otherCommercialName" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.SUBSTANCE' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off" [disabled]="commercialName !== 'other'"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="substance" name="substance" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.RELATED_ACTION' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="relatedActionSprayUnit" name="solutionUnit">
                                        <option *ngFor="let unit of relatedActionSpray" [value]="unit">
                                            {{ unit | translate}}
                                        </option>
                                    </select>
                                    <span *ngIf="relatedActionSprayEmpty"
                                        style="color: red; font-size: 14px; font-weight: 600;">
                                        {{ 'CROP.RELATED_ACTION_CATEGORY_MSG' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="w-full px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.OBJECTIVE' | translate }}
                                    </label>
                                    <textarea autocomplete="off" [disabled]="commercialName !== 'other'"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="justification" name="justification">
                                    </textarea>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.ITEM_AMOUNT' | translate }}
                                    </label>
                                    <input type="number" autocomplete="off" min="0"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="itemAmount" name="itemAmount" />
                                </div>
                            </div>
                        </div>
                    </form>
                    <button mat-raised-button id="saveButton" class="float-right m-2" type="button"
                        (click)="addSpraying()">
                        <mat-icon>done</mat-icon>
                        {{ 'MANAGEMENT.SAVE' | translate }}
                    </button>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="row">
                        <div class="col-6 d-flex align-items-left justify-content-left">
                            <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                                src="../../../../assets/harvest_fruits.png" alt=""
                                style="background-color: #f6c295;
                                 border: 3px solid rgb(253, 255, 252);
                                 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;" width="40">
                        </div>
                        <div class="col-6 d-flex align-items-center justify-content-center">
                            {{ 'CROP.HARVEST' | translate }}
                        </div>
                    </div>
                </ng-template>
                <div class="container mx-auto px-4">
                    <form style="margin-top: 1%;">
                        <h6 class="text-center text-gray-400 text-sm my-6 font-bold uppercase" id="field">
                            {{ 'CROP.FIELD' | translate }}
                        </h6>
                        <hr class="my-1 border-b-1 border-gray-300" />
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.CODE' | translate }}
                                    </label>
                                    <mat-select class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded 
                                        w-full ease-linear transition-all duration-150">
                                        <mat-option value="residuePlotCode" disabled selected></mat-option>
                                        <mat-option *ngFor="let code of plotCodes" [value]="code">
                                            <div class="row" style="font-size: 12px; color: rgb(104, 103, 103);">
                                                {{ code[0]}} - {{code[3]}}
                                                <div class="row" *ngFor="let subtask of code[4]">
                                                    <mat-radio-button class="pl-2" (change)="getSpraying(subtask)">
                                                        <div style="font-size: 8px; color: rgb(147, 147, 147);">
                                                            {{subtask.crop_type }} /
                                                            {{ subtask.variety.split('(')[0]}}
                                                        </div>
                                                    </mat-radio-button>
                                                </div>
                                            </div>
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.CLEANING' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="cleaning" name="cleaning">
                                        <option value="" disabled selected></option>
                                        <option>OK</option>
                                        <option>NOT OK</option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.CLIENT' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="client" name="client" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.DOCUMENTNUMBER' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="documentNumber" name="documentNumber" />
                                </div>
                            </div>
                            <div class="w-full lg:w-2/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.LAST_SPRAY' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="sprayLast" name="sprayLast" (ngModelChange)="phiSpray($event)" />
                                </div>
                            </div>
                            <div class="w-full lg:w-2/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.COMMERCIAL_NAME' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="sprayName" name="sprayName" />
                                </div>
                            </div>
                            <div class="w-full lg:w-2/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.HARVEST_PHI' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="sprayPhi" name="sprayPhi" (ngModelChange)="phiSpray($event)" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-0">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.HARVEST_PER_PLOT' | translate }}

                                    </label>
                                    <div class="flex flex-row">
                                        <div class=".w-full lg:w-6/12 px-1">
                                            <div class="relative w-full mb-3">
                                                <input type="number" autocomplete="off"
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="harvestPlot" name="harvestPlot"
                                                    (ngModelChange)="changeHarvest($event)" />
                                            </div>
                                        </div>
                                        <div class="w-full lg:w-6/12 px-1">
                                            <div class="relative w-full mb-3">
                                                <select
                                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    [(ngModel)]="fertilisationDosageUnit"
                                                    name="fertilisationDosageUnit">
                                                    <option value="" disabled selected>{{ 'CROP.UNIT' | translate }}
                                                    </option>
                                                    <option *ngFor="let unit of dosageUnitsAcres" [value]="unit">
                                                        {{ unit | translate }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 pb-2 px-4">
                                <div class="mb-3" *ngIf="warnHarvest" style="
                                    color: red;
                                    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 10px !important;
                                    padding: 3%;
                                    border-radius: 12px;">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                    <span style="font-weight: 600;">
                                        {{ 'CROP.HARVEST_ALERT' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'PLOTS.STARTDATE' | translate }}
                                    </label>
                                    <input type="date"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="harvestDate" name="harvestDate"
                                        (ngModelChange)="phiSpray($event)" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Lot Number
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="lotNumber" name="lotNumber" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.SUM' | translate }}
                                    </label>
                                    <input type="number" min="0" step="0.1" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="harvestSum" name="harvestSum"
                                        (ngModelChange)="changeHarvest($event)" />
                                </div>
                            </div>
                        </div>
                    </form>
                    <button mat-raised-button id="saveButton" class="float-right m-2" type="button"
                        (click)="addHarvest()">
                        <mat-icon>done</mat-icon>
                        {{ 'MANAGEMENT.SAVE' | translate }}
                    </button>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="row">
                        <div class="col-6 d-flex align-items-left justify-content-left">
                            <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                                src="../../../../assets/compost.png" alt=""
                                style="background-color: #d8f695;
                                 border: 3px solid rgb(253, 255, 252);
                                 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;" width="40">
                        </div>
                        <div class="col-6 d-flex align-items-center justify-content-center">
                            {{ 'CROP.RESIDUE' | translate }}
                        </div>
                    </div>
                </ng-template>
                <div class="container mx-auto px-4">
                    <form style="margin-top: 1%;">
                        <h6 class="text-center text-gray-400 text-sm my-6 font-bold uppercase" id="field">
                            {{ 'CROP.FIELD' | translate }}
                        </h6>
                        <hr class="my-1 border-b-1 border-gray-300" />
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.CODE' | translate }}
                                    </label>
                                    <mat-select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="residuePlotCode" name="residuePlotCode">
                                        <mat-option value="" disabled selected></mat-option>
                                        <mat-option *ngFor="let code of plotCodes" [value]="code">
                                            {{code[0]}} - {{code[3]}}
                                            <div class="row" *ngFor="let subtask of code[4]">
                                                <mat-radio-button class="pl-2" (change)="getTreatment(subtask)"
                                                    (change)="getHarvests(subtask)">
                                                    <div style="font-size: 8px; color: rgb(147, 147, 147);">
                                                        {{subtask.crop_type }} /
                                                        {{ subtask.variety.split('(')[0]}}
                                                    </div>
                                                </mat-radio-button>
                                            </div>
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'PLOTS.STARTDATE' | translate }}
                                    </label>
                                    <input type="date"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="residueDate" name="residueDate" />
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.TOTALRESIDUE' | translate }}
                                    </label>
                                    <input type="number" min="0" step="0.1" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="residueAmount" name="residueAmount" />
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.RELATED' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="harvestId" name="harvestId"
                                        [ngModelOptions]="{ standalone: true }">
                                        <option value="" disabled selected></option>
                                        <option *ngFor="let harvest of harvests" [ngValue]="harvest.harvestId">
                                            {{ harvest.displayText }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.RELATED_TREATMENT' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="residueId" name="harvestId"
                                        (ngModelChange)="changeResidues($event)"
                                        [ngModelOptions]="{ standalone: true }">
                                        <option value="" disabled selected></option>
                                        <option *ngFor="let residue of residuesTreatments" [ngValue]="residue">
                                            {{ residue.treatment }} - Date: {{residue.start_date}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.IRRMETHOD' | translate }}
                                    </label>
                                    <select
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="residueMethod" [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="onResidues($event)">
                                        <option value="" disabled selected></option>
                                        <option *ngFor="let method of residueMethods; let i = index" [ngValue]="method">
                                            {{method | translate }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                    <button mat-raised-button id="saveButton" class="float-right m-2" type="button"
                        (click)="addResidues()">
                        <mat-icon>done</mat-icon>
                        {{ 'MANAGEMENT.SAVE' | translate }}
                    </button>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>