import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'agnes-dashboard';

  constructor(public translate: TranslateService) {
    if (environment.uruguayFeatures) {
      translate.setDefaultLang('en');
      translate.use('en');
    } else  {
      translate.setDefaultLang('gr');
      translate.use('gr');
    }
  }

}
